import React from 'react';
import { ChevronRight, CheckCircle, Database, Server, Shield, BarChart, Zap, Search } from 'lucide-react';

const DatabaseExpertsPage = () => {
  const expertise = [
    {
      title: "Database Architecture & Design",
      description: "Designing scalable, efficient database schemas and architectures tailored to your specific business requirements."
    },
    {
      title: "Database Performance Optimization",
      description: "Improving query performance, optimizing indexing strategies, and fine-tuning database configurations for maximum efficiency."
    },
    {
      title: "Data Migration & Integration",
      description: "Securely migrating data between different systems and integrating diverse data sources into cohesive solutions."
    },
    {
      title: "High Availability & Disaster Recovery",
      description: "Implementing robust database clusters, replication strategies, and comprehensive backup and recovery solutions."
    },
    {
      title: "Big Data & Analytics Solutions",
      description: "Building data warehousing, data lakes, and analytics platforms to extract actionable insights from large datasets."
    },
    {
      title: "Database Security & Compliance",
      description: "Implementing secure database environments that protect sensitive data and comply with regulatory requirements."
    }
  ];

  const technologies = [
    "PostgreSQL", "MySQL", "Oracle", "SQL Server",
    "MongoDB", "Cassandra", "Redis", "Elasticsearch",
    "DynamoDB", "Firebase", "Neo4j", "CouchDB",
    "Snowflake", "BigQuery", "Redshift", "Clickhouse",
    "Hadoop", "Spark", "Kafka", "Presto",
    "TimescaleDB", "InfluxDB", "GraphQL", "DataOps"
  ];

  const benefits = [
    {
      title: "Data-Driven Decision Making",
      description: "Our database experts enable your organization to leverage data effectively for informed strategic decisions."
    },
    {
      title: "Scalable Data Architecture",
      description: "Build database systems that scale seamlessly with your business growth, handling increasing data volumes without performance degradation."
    },
    {
      title: "Secure Data Management",
      description: "Implement comprehensive data protection strategies to safeguard sensitive information and maintain customer trust."
    },
    {
      title: "Optimized Query Performance",
      description: "Experience significant improvements in application responsiveness with expertly optimized database queries and structures."
    }
  ];

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
                DATABASE EXPERTS
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Expert Database Specialists for Your Data Needs
              </h1>
              <p className="text-xl mb-8 text-purple-100">
                Connect with skilled database developers who design, optimize, and manage high-performance database systems for mission-critical applications.
              </p>
              <div className="flex flex-wrap gap-4">
                <a 
                  href="/get-matched" 
                  className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Hire Database Experts
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
                <a 
                  href="/contact" 
                  className="group bg-purple-800/50 backdrop-blur-sm hover:bg-purple-800/70 border border-purple-500/30 text-white px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Contact Us
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </div>
            </div>
            <div className="hidden md:block">
              <div className="relative">
                <div className="absolute -left-16 -top-16 w-64 h-64 bg-purple-500 opacity-20 rounded-full animate-pulse"></div>
                <div className="absolute -right-20 -bottom-20 w-80 h-80 bg-indigo-500 opacity-20 rounded-full animate-pulse" style={{ animationDelay: '1s' }}></div>
                
                <div className="relative bg-white dark:bg-gray-900 p-8 rounded-xl shadow-2xl transform hover:scale-105 transition-all duration-500">
                  <div className="flex gap-4 mb-6">
                    <div className="w-3 h-3 rounded-full bg-red-400"></div>
                    <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                    <div className="w-3 h-3 rounded-full bg-green-400"></div>
                  </div>
                  
                  <div className="space-y-6">
                    <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700 pb-4">
                      <div className="flex items-center">
                        <div className="w-10 h-10 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center font-bold mr-3">
                          <Database size={20} />
                        </div>
                        <div>
                          <div className="font-bold text-gray-900 dark:text-white">Database Monitor</div>
                          <div className="text-xs text-gray-500 dark:text-gray-400">Performance Metrics</div>
                        </div>
                      </div>
                      <div className="text-xs text-green-600 font-semibold bg-green-100 px-2 py-1 rounded-full">
                        HEALTHY
                      </div>
                    </div>
                    
                    <div className="space-y-3">
                      <div className="flex justify-between items-center">
                        <div className="text-sm font-medium text-gray-700 dark:text-gray-300">Query Performance</div>
                        <div className="text-sm font-bold text-gray-900 dark:text-white">95%</div>
                      </div>
                      <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                        <div className="h-full bg-gradient-to-r from-green-500 to-green-600 rounded-full w-[95%]"></div>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Technologies</span>
                        <span className="font-medium text-gray-900 dark:text-white">PostgreSQL, MongoDB</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Experience</span>
                        <span className="font-medium text-gray-900 dark:text-white">10+ years</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Avg. Response Time</span>
                        <span className="font-medium text-gray-900 dark:text-white">12ms</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Uptime</span>
                        <span className="font-medium text-gray-900 dark:text-white">99.997%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Database Expertise */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              EXPERTISE
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Database Development Expertise
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our database specialists bring comprehensive expertise in designing, optimizing, and managing database systems across various technologies.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {expertise.map((item, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
              >
                <div className="p-4 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  {[<Database />, <Zap />, <Server />, <Shield />, <BarChart />, <Search />][index % 6]}
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Why Choose Our Database Experts */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              BENEFITS
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Why Choose Our Database Experts
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Working with our database specialists brings numerous advantages to your data infrastructure.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            {benefits.map((benefit, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
              >
                <h3 className="text-xl font-bold mb-3 text-gray-900">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Technologies */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              TECH STACK
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Database Technologies We Use
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our database experts are proficient in a wide range of database technologies, from traditional relational databases to modern NoSQL and big data solutions.
            </p>
          </div>

          <div className="flex flex-wrap justify-center gap-4">
            {technologies.map((tech, index) => (
              <div 
                key={index} 
                className="bg-gray-100 text-gray-800 px-4 py-2 rounded-full font-medium hover:bg-purple-100 hover:text-purple-700 transition-colors duration-300"
              >
                {tech}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Hire Database Experts?</h2>
          <p className="text-xl mb-8 text-purple-200 max-w-3xl mx-auto">
            Connect with our team to discuss your database needs and find the perfect database specialist for your project.
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <a 
              href="/get-matched" 
              className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Find Your Database Expert
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            <a 
              href="/contact" 
              className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Contact Us
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DatabaseExpertsPage;