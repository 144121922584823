import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Home, ArrowLeft, ChevronRight } from 'lucide-react';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
          
          {/* Animated particles */}
          {[...Array(15)].map((_, i) => (
            <div 
              key={i} 
              className="absolute rounded-full bg-white/10"
              style={{
                width: `${Math.random() * 6 + 2}px`,
                height: `${Math.random() * 6 + 2}px`,
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animation: `float ${Math.random() * 10 + 15}s linear infinite`,
                animationDelay: `${Math.random() * 5}s`,
                opacity: Math.random() * 0.5 + 0.3
              }}
            />
          ))}
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
                404 Error
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Page Not Found
              </h1>
              <p className="text-xl mb-8 text-purple-100 max-w-lg">
                The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
              </p>
              <div className="flex flex-wrap gap-4">
                <button 
                  onClick={() => navigate('/')} 
                  className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Back To Home
                </button>
              </div>
            </div>
            <div>
              <div className="relative">
                <div className="absolute -left-4 -top-4 w-64 h-64 bg-purple-500/30 rounded-full animate-pulse"></div>
                <div className="relative bg-white/10 backdrop-blur-sm border border-white/20 p-8 rounded-xl shadow-2xl">
                  <div className="flex justify-center items-center mb-6">
                    <div className="text-9xl font-bold text-white opacity-30">404</div>
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-white text-center">Oops! Lost in cyberspace.</h3>
                  <p className="text-purple-100 text-center mb-8">
                    Don't worry, finding the right path is our specialty. Let us help you get back on track.
                  </p>
                  <div className="flex justify-center">
                    <a 
                      href="/get-matched" 
                      className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 rounded-lg font-medium inline-flex items-center shadow-lg transform hover:-translate-y-1 transition-all duration-300"
                    >
                      Find Your Match
                      <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Suggestions Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">
              You Might Be Looking For
            </h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Here are some links that might help you find what you're looking for:
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
              <h3 className="text-xl font-bold mb-3 text-gray-900">Software Development</h3>
              <p className="text-gray-600 mb-4">
                Expert developers skilled in building custom software solutions.
              </p>
              <a 
                href="/software-development" 
                className="flex items-center text-purple-600 font-medium group"
              >
                <span className="mr-1">Learn more</span>
                <ChevronRight size={16} className="group-hover:translate-x-1 transition-transform duration-300" />
              </a>
            </div>

            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
              <h3 className="text-xl font-bold mb-3 text-gray-900">IT Staff Augmentation</h3>
              <p className="text-gray-600 mb-4">
                Extend your team with skilled professionals who integrate seamlessly.
              </p>
              <a 
                href="/it-staff-augmentation" 
                className="flex items-center text-purple-600 font-medium group"
              >
                <span className="mr-1">Learn more</span>
                <ChevronRight size={16} className="group-hover:translate-x-1 transition-transform duration-300" />
              </a>
            </div>

            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
              <h3 className="text-xl font-bold mb-3 text-gray-900">Get Matched Today</h3>
              <p className="text-gray-600 mb-4">
                Find the perfect tech talent match for your business needs.
              </p>
              <a 
                href="/get-matched" 
                className="flex items-center text-purple-600 font-medium group"
              >
                <span className="mr-1">Start now</span>
                <ChevronRight size={16} className="group-hover:translate-x-1 transition-transform duration-300" />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-purple-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">Need Help Finding Something?</h2>
          <p className="text-lg mb-8 text-gray-600 max-w-2xl mx-auto">
            Our team is ready to assist you in finding the right information or connecting you with the perfect tech talent.
          </p>
          <a 
            href="/contact" 
            className="group bg-gradient-to-br from-purple-600 to-indigo-600 text-white px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
          >
            Contact Us
            <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
          </a>
        </div>
      </section>
      
      {/* Custom animation styles */}
      <style jsx>{`
        @keyframes float {
          0%, 100% {
            transform: translateY(0) translateX(0);
          }
          25% {
            transform: translateY(-10px) translateX(10px);
          }
          50% {
            transform: translateY(0) translateX(20px);
          }
          75% {
            transform: translateY(10px) translateX(10px);
          }
        }
      `}</style>
    </div>
  );
};

export default NotFoundPage;