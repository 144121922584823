import React, { useState } from 'react';
import { ChevronRight, Code, Users, CheckCircle, ArrowRight, ChevronDown, Globe, Database, Server, Shield } from 'lucide-react';

const SoftwareDevelopmentPage = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const technologies = [
    { name: "JavaScript", category: "frontend", icon: <div className="bg-yellow-400 text-center px-1">JS</div> },
    { name: "React", category: "frontend", icon: <div className="bg-blue-400 text-center px-1">R</div> },
    { name: "Angular", category: "frontend", icon: <div className="bg-red-500 text-center px-1">A</div> },
    { name: "Vue.js", category: "frontend", icon: <div className="bg-green-500 text-center px-1">V</div> },
    { name: "Next.js", category: "frontend", icon: <div className="bg-gray-800 text-center px-1">N</div> },
    { name: "Node.js", category: "backend", icon: <div className="bg-green-600 text-center px-1">N</div> },
    { name: "Python", category: "backend", icon: <div className="bg-blue-600 text-center px-1">P</div> },
    { name: "Java", category: "backend", icon: <div className="bg-red-600 text-center px-1">J</div> },
    { name: "PHP/Laravel", category: "backend", icon: <div className="bg-purple-600 text-center px-1">L</div> },
    { name: "MongoDB", category: "database", icon: <div className="bg-green-700 text-center px-1">M</div> },
    { name: "PostgreSQL", category: "database", icon: <div className="bg-blue-700 text-center px-1">P</div> },
    { name: "MySQL", category: "database", icon: <div className="bg-orange-500 text-center px-1">M</div> },
    { name: "AWS", category: "devops", icon: <div className="bg-yellow-600 text-center px-1">A</div> },
    { name: "Docker", category: "devops", icon: <div className="bg-blue-500 text-center px-1">D</div> },
    { name: "Kubernetes", category: "devops", icon: <div className="bg-blue-600 text-center px-1">K</div> },
  ];

  const expertise = [
    {
      icon: <Globe className="w-6 h-6" />,
      title: "Web Development",
      description: "We build responsive, high-performance web applications using the latest technologies and frameworks."
    },
    {
      icon: <div className="flex justify-center items-center w-6 h-6">📱</div>,
      title: "Mobile Development",
      description: "Native and cross-platform mobile applications for iOS and Android with exceptional user experiences."
    },
    {
      icon: <Database className="w-6 h-6" />,
      title: "Backend Engineering",
      description: "Robust, scalable backend systems designed for performance, security, and maintainability."
    },
    {
      icon: <Server className="w-6 h-6" />,
      title: "DevOps & Cloud",
      description: "Continuous integration, deployment automation, and cloud infrastructure optimization."
    },
    {
      icon: <Shield className="w-6 h-6" />,
      title: "Security Engineering",
      description: "Implementing best practices in security to protect data and ensure compliance standards."
    },
    {
      icon: <div className="flex justify-center items-center w-6 h-6">🧪</div>,
      title: "Quality Assurance",
      description: "Comprehensive testing strategies to ensure your software meets the highest quality standards."
    }
  ];

  const caseStudies = [
    {
      client: "FinTech Startup",
      project: "Investment Portfolio Platform",
      technologies: ["React", "Node.js", "PostgreSQL", "AWS"],
      description: "Developed a comprehensive investment platform with real-time data analytics, portfolio management, and automated trading capabilities.",
      results: "Helped the client acquire 10,000+ users within 6 months and secure Series A funding."
    },
    {
      client: "E-commerce Giant",
      project: "Inventory Management System",
      technologies: ["Angular", "Java", "MongoDB", "Docker"],
      description: "Built a scalable inventory management system integrating with multiple warehouses and providing real-time stock updates.",
      results: "Reduced inventory discrepancies by 95% and improved order fulfillment time by 40%."
    },
    {
      client: "Healthcare Provider",
      project: "Patient Management Platform",
      technologies: ["Vue.js", "Python", "MySQL", "Kubernetes"],
      description: "Created a HIPAA-compliant patient management system with scheduling, medical records, and billing integration.",
      results: "Streamlined administrative processes, reducing operational costs by 35%."
    }
  ];

  const faqItems = [
    {
      question: "What is your software development process?",
      answer: "We follow an Agile methodology with iterative development cycles. Our process includes requirements gathering, design, development, testing, deployment, and maintenance phases. We provide regular updates and maintain transparency throughout the project lifecycle."
    },
    {
      question: "How do you ensure the quality of your software?",
      answer: "We implement comprehensive testing strategies including unit testing, integration testing, and end-to-end testing. We use automated testing tools, conduct code reviews, and follow industry best practices to ensure the highest quality standards."
    },
    {
      question: "What types of industries do you specialize in?",
      answer: "We have expertise across various industries including FinTech, Healthcare, E-commerce, EdTech, and more. Our diverse team has experience working with businesses ranging from startups to enterprise-level organizations."
    },
    {
      question: "Do you provide ongoing maintenance and support?",
      answer: "Yes, we offer flexible maintenance and support packages tailored to your needs. These include bug fixes, performance optimization, security updates, feature enhancements, and 24/7 technical support options."
    },
    {
      question: "How do you handle intellectual property rights?",
      answer: "We respect your intellectual property. All code, designs, and documentation created during the project belong to you. We sign NDAs to protect your confidential information and ensure all rights are transferred upon project completion."
    }
  ];

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
                Software Development Services
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Expert Software Development For Your Business
              </h1>
              <p className="text-xl mb-8 text-purple-100 max-w-lg">
                We build custom software solutions that drive innovation, improve efficiency, and create exceptional user experiences.
              </p>
              <div className="flex flex-wrap gap-4">
                <a 
                  href="/get-matched" 
                  className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Discuss Your Project
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
                <a 
                  href="#case-studies" 
                  className="group bg-purple-800/50 backdrop-blur-sm hover:bg-purple-800/70 border border-purple-500/30 text-white px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  View Case Studies
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </div>
            </div>
            <div>
              <div className="relative">
                <div className="absolute -left-4 -top-4 w-64 h-64 bg-purple-500/30 rounded-full animate-pulse"></div>
                <div className="relative bg-white/10 backdrop-blur-sm border border-white/20 p-8 rounded-xl shadow-2xl">
                  <div className="p-6 bg-purple-600 text-white rounded-xl inline-block mb-6 shadow-lg">
                    <Code size={48} />
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-white">Custom Software Solutions</h3>
                  <ul className="space-y-3 mb-8">
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Web & Mobile Applications</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Enterprise Software Systems</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">API Development & Integration</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Legacy System Modernization</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">DevOps & Cloud Infrastructure</span>
                    </li>
                  </ul>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Expertise */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR EXPERTISE
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Comprehensive Software Development Services
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              From concept to deployment, we deliver end-to-end software solutions tailored to your business needs.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {expertise.map((item, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-300 hover:shadow-xl hover:-translate-y-1"
              >
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  {item.icon}
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Development Process */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR PROCESS
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Streamlined Software Development Process
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our well-defined process ensures transparency, efficiency, and quality in every project.
            </p>
          </div>

          <div className="relative">
            {/* Connection line */}
            <div className="hidden md:block absolute top-24 left-0 w-full h-1 bg-purple-200 z-0"></div>
            
            <div className="grid md:grid-cols-5 gap-8">
              {[
                { title: "Discovery", description: "Understanding your business requirements and objectives" },
                { title: "Planning", description: "Creating detailed project plans and technical specifications" },
                { title: "Development", description: "Iterative development with regular progress updates" },
                { title: "Testing", description: "Rigorous quality assurance and performance testing" },
                { title: "Deployment", description: "Seamless deployment and ongoing maintenance" }
              ].map((step, index) => (
                <div key={index} className="relative">
                  <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 relative z-10 h-full hover:shadow-xl transition-all duration-300">
                    <div className="w-12 h-12 bg-purple-600 text-white rounded-full flex items-center justify-center font-bold text-xl mb-4 mx-auto">{index + 1}</div>
                    <h3 className="text-xl font-bold mb-3 text-center text-gray-900">{step.title}</h3>
                    <p className="text-gray-600 text-center">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Technologies */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR TECH STACK
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Technologies We Work With
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              We leverage the latest technologies to deliver high-performance, scalable software solutions.
            </p>
          </div>

          <div className="bg-gray-50 rounded-xl p-8 shadow-lg border border-gray-100">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-8">
              <div className="bg-white p-4 rounded-lg shadow-md border border-gray-100">
                <h3 className="font-bold text-lg mb-3 text-purple-700">Frontend</h3>
                <div className="space-y-2">
                  {technologies.filter(tech => tech.category === 'frontend').map((tech, index) => (
                    <div key={index} className="flex items-center py-2 border-b border-gray-100">
                      <div className="w-8 h-8 rounded-full bg-gray-200 text-gray-700 flex items-center justify-center mr-3 font-mono text-sm">
                        {tech.icon}
                      </div>
                      <span>{tech.name}</span>
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="bg-white p-4 rounded-lg shadow-md border border-gray-100">
                <h3 className="font-bold text-lg mb-3 text-purple-700">Backend</h3>
                <div className="space-y-2">
                  {technologies.filter(tech => tech.category === 'backend').map((tech, index) => (
                    <div key={index} className="flex items-center py-2 border-b border-gray-100">
                      <div className="w-8 h-8 rounded-full bg-gray-200 text-gray-700 flex items-center justify-center mr-3 font-mono text-sm">
                        {tech.icon}
                      </div>
                      <span>{tech.name}</span>
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="bg-white p-4 rounded-lg shadow-md border border-gray-100">
                <h3 className="font-bold text-lg mb-3 text-purple-700">Database</h3>
                <div className="space-y-2">
                  {technologies.filter(tech => tech.category === 'database').map((tech, index) => (
                    <div key={index} className="flex items-center py-2 border-b border-gray-100">
                      <div className="w-8 h-8 rounded-full bg-gray-200 text-gray-700 flex items-center justify-center mr-3 font-mono text-sm">
                        {tech.icon}
                      </div>
                      <span>{tech.name}</span>
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="bg-white p-4 rounded-lg shadow-md border border-gray-100">
                <h3 className="font-bold text-lg mb-3 text-purple-700">DevOps</h3>
                <div className="space-y-2">
                  {technologies.filter(tech => tech.category === 'devops').map((tech, index) => (
                    <div key={index} className="flex items-center py-2 border-b border-gray-100">
                      <div className="w-8 h-8 rounded-full bg-gray-200 text-gray-700 flex items-center justify-center mr-3 font-mono text-sm">
                        {tech.icon}
                      </div>
                      <span>{tech.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="text-center">
              <a 
                href="/get-matched" 
                className="inline-flex items-center text-purple-600 font-medium group"
              >
                <span className="mr-1">Find Developers with Your Specific Tech Stack</span>
                <ArrowRight size={16} className="group-hover:translate-x-1 transition-transform duration-300" />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Case Studies */}
      <section id="case-studies" className="py-20 bg-purple-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              SUCCESS STORIES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Case Studies
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Explore some of our successful software development projects and their impact.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {caseStudies.map((study, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden transition-all duration-300 hover:shadow-xl hover:-translate-y-1"
              >
                <div className="h-48 bg-gradient-to-br from-purple-600 to-indigo-700 flex items-center justify-center relative">
                  <div className="text-white text-center z-10 px-6">
                    <div className="text-sm font-semibold mb-2 opacity-75">CLIENT</div>
                    <div className="text-2xl font-bold">{study.client}</div>
                  </div>
                  <div className="absolute inset-0 bg-black/20"></div>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-4 text-gray-900">{study.project}</h3>
                  
                  <div className="mb-4">
                    <div className="text-sm text-gray-500 mb-2">Technologies</div>
                    <div className="flex flex-wrap gap-2">
                      {study.technologies.map((tech, i) => (
                        <span 
                          key={i} 
                          className="bg-purple-100 text-purple-700 text-xs px-3 py-1 rounded-full"
                        >
                          {tech}
                        </span>
                      ))}
                    </div>
                  </div>
                  
                  <p className="text-gray-600 mb-4">{study.description}</p>
                  
                  <div className="bg-green-50 border border-green-100 rounded-lg p-3">
                    <div className="text-sm font-medium text-green-800 mb-1">Results</div>
                    <p className="text-sm text-green-700">{study.results}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-white">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              FAQ
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Frequently Asked Questions
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Find answers to common questions about our software development services.
            </p>
          </div>

          <div className="space-y-4">
            {faqItems.map((item, index) => (
              <div 
                key={index} 
                className="border border-gray-200 rounded-xl overflow-hidden transition-all duration-300"
              >
                <button 
                  className="flex justify-between items-center w-full p-6 text-left bg-white hover:bg-gray-50 transition-colors duration-300"
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="font-bold text-lg text-gray-900">{item.question}</span>
                  <ChevronDown 
                    className={`transform transition-transform duration-300 text-purple-600 ${
                      activeAccordion === index ? 'rotate-180' : ''
                    }`} 
                    size={20} 
                  />
                </button>
                <div 
                  className={`overflow-hidden transition-all duration-300 ${
                    activeAccordion === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                  }`}
                >
                  <div className="p-6 pt-0 text-gray-600 bg-white">
                    {item.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Build Your Software Solution?</h2>
          <p className="text-xl mb-8 text-purple-200 max-w-3xl mx-auto">
            Our team of expert developers is ready to bring your vision to life. Get in touch to discuss your project.
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <a 
              href="/get-matched" 
              className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Start Your Project
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            <a 
              href="/contact" 
              className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Contact Us
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SoftwareDevelopmentPage;