import React, { useState } from 'react';
import { ChevronRight, Mail, Phone, MapPin, CheckCircle, AlertCircle, Linkedin, Clock, Globe, Building } from 'lucide-react';
import axios from 'axios';

// Form ID from the WordPress plugin admin page
const CONTACT_FORM_ID = 10; // Replace with your actual form ID

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [activeLocation, setActiveLocation] = useState('uk');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // In development, you can use this mock instead of the actual API call
      const isDevelopment = process.env.NODE_ENV === 'development';
      const useMockResponse = isDevelopment && false; // Set to true to use mock
      
      if (useMockResponse) {
        // Simulate API call delay
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        setSubmitStatus('success');
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: ''
        });
      } else {
        // Prepare form data for Contact Form 7
        const form = new FormData();
        
        // Add CF7 required fields
        form.append('_wpcf7', CONTACT_FORM_ID);
        form.append('_wpcf7_version', '5.7.7'); // Update to match your CF7 version
        form.append('_wpcf7_locale', 'en_US');
        form.append('_wpcf7_unit_tag', `wpcf7-f${CONTACT_FORM_ID}-p1-o1`);
        form.append('_wpcf7_container_post', '0');
        
        // Map form data to CF7 field names
        form.append('your-name', formData.name);
        form.append('your-email', formData.email);
        form.append('your-phone', formData.phone || '');
        form.append('your-subject', formData.subject);
        form.append('your-message', formData.message);
        
        // CF7 endpoint
        const endpoint = `https://cms.soft-house.eu/wp-json/contact-form-7/v1/contact-forms/${CONTACT_FORM_ID}/feedback`;
        
        // Submit the form
        const response = await axios.post(endpoint, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        
        // Check if submission was successful
        if (response.data.status === 'mail_sent') {
          setSubmitStatus('success');
          setFormData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: ''
          });
        } else {
          console.error('Form submission failed:', response.data.message);
          setSubmitStatus('error');
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
      
      // Clear status after 5 seconds
      setTimeout(() => {
        setSubmitStatus(null);
      }, 5000);
    }
  };

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
          
          {/* Animated particles */}
          {[...Array(15)].map((_, i) => (
            <div 
              key={i} 
              className="absolute rounded-full bg-white/10"
              style={{
                width: `${Math.random() * 6 + 2}px`,
                height: `${Math.random() * 6 + 2}px`,
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animation: `float ${Math.random() * 10 + 15}s linear infinite`,
                animationDelay: `${Math.random() * 5}s`,
                opacity: Math.random() * 0.5 + 0.3
              }}
            />
          ))}
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
            Contact Us
          </div>
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Get In Touch With SOFT HOUSE
          </h1>
          <p className="text-xl mb-8 text-purple-100 max-w-3xl mx-auto">
            Have questions about our services? Need tech talent for your project? We'd love to hear from you.
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <a 
              href="#contact-form" 
              className="group bg-white text-purple-700 hover:bg-purple-50 px-6 py-3 rounded-lg font-medium inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Send a Message
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            <a 
              href="#locations" 
              className="group bg-purple-800/50 backdrop-blur-sm hover:bg-purple-800/70 border border-purple-500/30 text-white px-6 py-3 rounded-lg font-medium inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Visit Our Offices
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>

      {/* Contact Methods Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              REACH OUT
            </div>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">How Can We Help You?</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              Choose the most convenient way to connect with our team
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8 text-center transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
              <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-6">
                <Mail className="h-8 w-8 text-purple-600" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-3">Email Us</h3>
              <p className="text-gray-600 mb-4">
                We'll respond to your inquiry within 24 hours on business days.
              </p>
              <a href="mailto:contact@soft-house.eu" className="text-purple-600 font-medium hover:text-purple-800 transition-colors duration-300">
                contact@soft-house.eu
              </a>
            </div>
            
            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8 text-center transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
              <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-6">
                <Phone className="h-8 w-8 text-purple-600" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-3">Call Us</h3>
              <p className="text-gray-600 mb-4">
                Speak directly with our team during business hours.
              </p>
              <a href="tel:+447456215208" className="text-purple-600 font-medium hover:text-purple-800 transition-colors duration-300">
                +44 7456 215208
              </a>
            </div>
            
            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8 text-center transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
              <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-6">
                <Linkedin className="h-8 w-8 text-purple-600" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-3">Connect</h3>
              <p className="text-gray-600 mb-4">
                Follow us on LinkedIn for updates and insights.
              </p>
              <a 
                href="https://www.linkedin.com/company/softhouseglobal/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-purple-600 font-medium hover:text-purple-800 transition-colors duration-300"
              >
                linkedin.com/company/softhouseglobal
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Locations Section - Redesigned */}
      <section id="locations" className="py-20 bg-gray-50 relative overflow-hidden">
        {/* Background decoration */}
        <div className="absolute -top-40 -right-40 w-96 h-96 bg-purple-100 rounded-full opacity-50 mix-blend-multiply filter blur-3xl"></div>
        <div className="absolute -bottom-40 -left-40 w-96 h-96 bg-indigo-100 rounded-full opacity-50 mix-blend-multiply filter blur-3xl"></div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR LOCATIONS
            </div>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Visit Our Global Offices</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              SOFT HOUSE operates internationally with offices in the UK and Poland
            </p>
            
            {/* Location Tabs */}
            <div className="flex justify-center mt-8">
              <div className="bg-white p-2 rounded-full shadow-md inline-flex">
                <button 
                  onClick={() => setActiveLocation('uk')}
                  className={`px-6 py-3 rounded-full font-medium transition-all duration-300 ${
                    activeLocation === 'uk' 
                      ? 'bg-purple-600 text-white shadow-md' 
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  UK Office
                </button>
                <button 
                  onClick={() => setActiveLocation('poland')}
                  className={`px-6 py-3 rounded-full font-medium transition-all duration-300 ${
                    activeLocation === 'poland' 
                      ? 'bg-purple-600 text-white shadow-md' 
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  Poland Office
                </button>
              </div>
            </div>
          </div>
          
          <div className="mt-8 overflow-hidden">
            {/* UK Office */}
            <div 
              className={`transition-all duration-500 transform ${
                activeLocation === 'uk' ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full absolute'
              }`}
              style={{ height: activeLocation === 'uk' ? 'auto' : '0' }}
            >
              <div className="bg-gradient-to-br from-white to-purple-50 rounded-3xl  overflow-hidden">
                <div className="grid md:grid-cols-2">
                  {/* Image Side */}
                  <div className="h-full">
                    <div className="relative h-full min-h-[300px]">
                      <img 
                        src="/images/uk-office.jpg" 
                        alt="UK Office Building" 
                        className="absolute inset-0 w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-purple-900/20"></div>
                      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-purple-900/80 to-transparent p-6 text-white">
                        <div className="font-bold text-xl">SOFT HOUSE UK</div>
                        <div className="flex items-center mt-2">
                          <Clock size={16} className="mr-2" />
                          <span className="text-sm">9:00 AM - 5:30 PM (GMT)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {/* Content Side */}
                  <div className="p-8 md:p-12">
                    <h3 className="text-2xl font-bold text-gray-900 mb-4 flex items-center">
                      <Building className="mr-3 text-purple-600" />
                      United Kingdom Headquarters
                    </h3>
                    
                    <div className="mb-6 bg-white p-6 rounded-xl shadow-sm">
                      <div className="flex mb-4">
                        <MapPin className="text-purple-600 mt-1 mr-3 flex-shrink-0" size={24} />
                        <div>
                          <p className="font-medium text-gray-900 mb-1">Address</p>
                          <p className="text-gray-600">
                            SOFT HOUSE LTD<br />
                            321-323 High Road<br />
                            Essex, RM6 6AX<br />
                            United Kingdom
                          </p>
                          <p className="text-gray-500 text-sm mt-2">VAT: GB432066718</p>
                        </div>
                      </div>
                      
                      <div className="flex">
                        <Mail className="text-purple-600 mt-1 mr-3 flex-shrink-0" size={24} />
                        <div>
                          <p className="font-medium text-gray-900 mb-1">Email</p>
                          <a href="mailto:hello@soft-house.uk" className="text-purple-600 hover:text-purple-800 transition-colors duration-300">
                            hello@soft-house.uk
                          </a>
                        </div>
                      </div>
                    </div>
                    
                    <div className="bg-purple-900 text-white p-6 rounded-xl shadow-sm">
                      <h4 className="font-bold mb-4 flex items-center">
                        <Globe className="mr-2" size={20} />
                        Getting Here
                      </h4>
                      <ul className="space-y-3">
                        <li className="flex items-start">
                          <div className="bg-white/20 p-1 rounded mr-3 mt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="4" width="18" height="16" rx="2" /><circle cx="12" cy="12" r="3" /><line x1="3" y1="12" x2="9" y2="12" /><line x1="15" y1="12" x2="21" y2="12" /><line x1="12" y1="3" x2="12" y2="9" /><line x1="12" y1="15" x2="12" y2="21" /></svg>
                          </div>
                          <span>10 minutes from Newbury Park Station</span>
                        </li>
                        <li className="flex items-start">
                          <div className="bg-white/20 p-1 rounded mr-3 mt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" /></svg>
                          </div>
                          <span>Bus routes 66, 296, and 396 stop nearby</span>
                        </li>
                        <li className="flex items-start">
                          <div className="bg-white/20 p-1 rounded mr-3 mt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5 17h14v-4H5v4zm11.5-4h-9V9.5h9V13zm-13-1H2v6h1v-6zm18 0h-1v6h1v-6zM9 5.5A3.5 3.5 0 0 1 12.5 2a3.5 3.5 0 0 1 3.5 3.5V13h-7V5.5z" /></svg>
                          </div>
                          <span>Free parking available for visitors</span>
                        </li>
                      </ul>
                      <div className="mt-4 flex justify-end">
                        <a 
                          href="https://maps.google.com/?q=321-323+High+Road+Essex+RM6+6AX+UK" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="inline-flex items-center text-white/90 hover:text-white font-medium text-sm group"
                        >
                          Get Directions
                          <ChevronRight size={16} className="ml-1 group-hover:translate-x-1 transition-transform duration-300" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Poland Office */}
            <div 
              className={`transition-all duration-500 transform ${
                activeLocation === 'poland' ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-full absolute'
              }`}
              style={{ height: activeLocation === 'poland' ? 'auto' : '0' }}
            >
              <div className="bg-gradient-to-br from-white to-indigo-50 rounded-3xl overflow-hidden">
                <div className="grid md:grid-cols-2">
                  {/* Content Side */}
                  <div className="p-8 md:p-12 order-2 md:order-1">
                    <h3 className="text-2xl font-bold text-gray-900 mb-4 flex items-center">
                      <Building className="mr-3 text-indigo-600" />
                      Poland Development Center
                    </h3>
                    
                    <div className="mb-6 bg-white p-6 rounded-xl shadow-sm">
                      <div className="flex mb-4">
                        <MapPin className="text-indigo-600 mt-1 mr-3 flex-shrink-0" size={24} />
                        <div>
                          <p className="font-medium text-gray-900 mb-1">Address</p>
                          <p className="text-gray-600">
                            GAWRYS HOLDING SP. Z O.O.<br />
                            ul. Uniwersytecka 13<br />
                            Katowice, 40-007<br />
                            Poland
                          </p>
                          <p className="text-gray-500 text-sm mt-2">VAT EU: PL5882514286</p>
                        </div>
                      </div>
                      
                      <div className="flex">
                        <Mail className="text-indigo-600 mt-1 mr-3 flex-shrink-0" size={24} />
                        <div>
                          <p className="font-medium text-gray-900 mb-1">Email</p>
                          <a href="mailto:contact@soft-house.eu" className="text-indigo-600 hover:text-indigo-800 transition-colors duration-300">
                            contact@soft-house.eu
                          </a>
                        </div>
                      </div>
                    </div>
                    
                    <div className="bg-indigo-900 text-white p-6 rounded-xl shadow-sm">
                      <h4 className="font-bold mb-4 flex items-center">
                        <Globe className="mr-2" size={20} />
                        Getting Here
                      </h4>
                      <ul className="space-y-3">
                        <li className="flex items-start">
                          <div className="bg-white/20 p-1 rounded mr-3 mt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="4" width="18" height="16" rx="2" /><circle cx="12" cy="12" r="3" /><line x1="3" y1="12" x2="9" y2="12" /><line x1="15" y1="12" x2="21" y2="12" /><line x1="12" y1="3" x2="12" y2="9" /><line x1="12" y1="15" x2="12" y2="21" /></svg>
                          </div>
                          <span>5 minutes from Katowice Main Square</span>
                        </li>
                        <li className="flex items-start">
                          <div className="bg-white/20 p-1 rounded mr-3 mt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" /></svg>
                          </div>
                          <span>Tram lines 7, 13, and 43 stop directly outside</span>
                        </li>
                        <li className="flex items-start">
                          <div className="bg-white/20 p-1 rounded mr-3 mt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" /></svg>
                          </div>
                          <span>20 minutes from Katowice International Airport</span>
                        </li>
                      </ul>
                      <div className="mt-4 flex justify-end">
                        <a 
                          href="https://maps.google.com/?q=Uniwersytecka+13+Katowice+40-007+Poland" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="inline-flex items-center text-white/90 hover:text-white font-medium text-sm group"
                        >
                          Get Directions
                          <ChevronRight size={16} className="ml-1 group-hover:translate-x-1 transition-transform duration-300" />
                        </a>
                      </div>
                    </div>
                  </div>
                  
                  {/* Image Side */}
                  <div className="order-1 md:order-2 h-full">
                    <div className="relative h-full min-h-[300px]">
                      <img 
                        src="/images/pl-office.jpg" 
                        alt="Poland Office Building" 
                        className="absolute inset-0 w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-indigo-900/20"></div>
                      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-indigo-900/80 to-transparent p-6 text-white">
                        <div className="font-bold text-xl">SOFT HOUSE POLAND</div>
                        <div className="flex items-center mt-2">
                          <Clock size={16} className="mr-2" />
                          <span className="text-sm">9:00 AM - 5:30 PM (CET)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section id="contact-form" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            {/* Left Column - Contact Form */}
            <div>
              <div className="bg-white rounded-xl shadow-xl border border-gray-100 p-8">
                <div className="mb-8">
                  <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
                    GET IN TOUCH
                  </div>
                  <h2 className="text-3xl font-bold text-gray-900 mb-4">Send Us a Message</h2>
                  <p className="text-gray-600">
                    Have a question or want to discuss a project? Fill out the form below and we'll get back to you as soon as possible.
                  </p>
                </div>
                
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="grid md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">Full Name *</label>
                      <input 
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500 bg-gray-50"
                        placeholder="Your name"
                      />
                    </div>
                    
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">Email Address *</label>
                      <input 
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500 bg-gray-50"
                        placeholder="your.email@example.com"
                      />
                    </div>
                    
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                      <input 
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500 bg-gray-50"
                        placeholder="+1 (123) 456-7890"
                      />
                    </div>
                    
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">Subject *</label>
                      <input 
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500 bg-gray-50"
                        placeholder="How can we help?"
                      />
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Message *</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      required
                      rows={5}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500 bg-gray-50"
                      placeholder="Please provide details about your inquiry..."
                    ></textarea>
                  </div>
                  
                  <div className="p-4 bg-purple-50 rounded-lg border border-purple-100">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckCircle className="h-5 w-5 text-purple-600" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-gray-700">
                          By submitting this form, you agree to our 
                          <a href="/privacy-policy" className="font-medium text-purple-600 hover:text-purple-500"> Privacy Policy</a>.
                          We'll respond to your inquiry as soon as possible.
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-8 py-3 rounded-lg font-medium inline-flex items-center shadow-md hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300 disabled:opacity-70"
                    >
                      {isSubmitting ? 'Sending...' : 'Send Message'}
                      {!isSubmitting && <ChevronRight size={20} className="ml-2" />}
                    </button>
                  </div>
                  
                  {submitStatus === 'success' && (
                    <div className="mt-4 p-4 bg-green-50 rounded-lg border border-green-100 text-green-800 flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-600 mr-2 flex-shrink-0" />
                      <p className="text-sm">
                        Your message has been sent successfully! We'll get back to you shortly.
                      </p>
                    </div>
                  )}
                  
                  {submitStatus === 'error' && (
                    <div className="mt-4 p-4 bg-red-50 rounded-lg border border-red-100 text-red-800 flex items-start">
                      <AlertCircle className="h-5 w-5 text-red-600 mr-2 flex-shrink-0" />
                      <p className="text-sm">
                        There was an error sending your message. Please try again or contact us directly via email.
                      </p>
                    </div>
                  )}
                </form>
              </div>
            </div>
            
            {/* Right Column - Image and CTA */}
            <div className="space-y-10">
              <div className="relative rounded-2xl overflow-hidden shadow-xl h-80">
                <img 
                  src="/images/team.webp" 
                  alt="SOFT HOUSE Team" 
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-purple-900/80 via-transparent to-transparent flex items-end">
                  <div className="p-8 text-white max-w-md">
                    <h3 className="text-2xl font-bold mb-2">Our Team is Ready to Help</h3>
                    <p className="text-white/90">
                      Get personalized assistance from our experts who understand your industry and technical needs.
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="bg-gradient-to-br from-purple-600 to-indigo-700 rounded-2xl shadow-xl p-8 text-white">
                <h3 className="text-2xl font-bold mb-4">Looking for Tech Talent?</h3>
                <p className="mb-6 text-purple-100">
                  Skip the lengthy hiring process and get matched with pre-vetted developers who fit your tech stack and company culture.
                </p>
                <div className="flex space-x-4 items-center">
                  <div className="flex -space-x-3">
                    {[...Array(4)].map((_, i) => (
                      <div key={i} className="w-10 h-10 rounded-full border-2 border-purple-600 bg-purple-500 text-white flex items-center justify-center text-xs font-bold">
                        {["JS", "PY", "JV", "PH"][i]}
                      </div>
                    ))}
                  </div>
                  <span className="text-sm text-purple-100">
                    <span className="font-bold">500+</span> Expert developers in our talent pool
                  </span>
                </div>
                <div className="mt-6">
                  <a 
                    href="/get-matched" 
                    className="group bg-white text-purple-600 hover:bg-purple-50 px-6 py-3 rounded-lg font-medium inline-flex items-center shadow-md hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300"
                  >
                    Get Matched
                    <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              FAQ
            </div>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Frequently Asked Questions</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              Find quick answers to common questions about working with us
            </p>
          </div>
          
          <div className="grid md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-xl shadow-md border border-gray-100 transition-all duration-300 hover:shadow-lg">
              <h3 className="text-lg font-bold text-gray-900 mb-3">How quickly can I get matched with developers?</h3>
              <p className="text-gray-600">
                Our typical matching process takes 48 hours, after which you'll receive profiles of developers who match your requirements.
              </p>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-md border border-gray-100 transition-all duration-300 hover:shadow-lg">
              <h3 className="text-lg font-bold text-gray-900 mb-3">What areas do you serve?</h3>
              <p className="text-gray-600">
                We work with clients globally, with a strong presence in Europe, North America, and Asia. Our remote team model allows us to collaborate with businesses worldwide.
              </p>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-md border border-gray-100 transition-all duration-300 hover:shadow-lg">
              <h3 className="text-lg font-bold text-gray-900 mb-3">How do you select your developers?</h3>
              <p className="text-gray-600">
                Our developers go through a rigorous vetting process including technical assessments, coding challenges, and interviews to ensure we only work with the top 5% of talent.
              </p>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-md border border-gray-100 transition-all duration-300 hover:shadow-lg">
              <h3 className="text-lg font-bold text-gray-900 mb-3">What happens after I submit a contact form?</h3>
              <p className="text-gray-600">
                You'll receive an immediate email confirmation, and our team will follow up personally within 1 business day to discuss your needs.
              </p>
            </div>
          </div>
          
          <div className="text-center mt-10">
            <a 
              href="#contact-form" 
              className="inline-flex items-center text-purple-600 font-medium hover:text-purple-800 transition-colors duration-300 group"
            >
              Have more questions? Get in touch with our team
              <ChevronRight size={16} className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>
      
      {/* Custom animation styles */}
      <style jsx>{`
        @keyframes float {
          0%, 100% {
            transform: translateY(0) translateX(0);
          }
          25% {
            transform: translateY(-10px) translateX(10px);
          }
          50% {
            transform: translateY(0) translateX(20px);
          }
          75% {
            transform: translateY(10px) translateX(10px);
          }
        }
      `}</style>
    </div>
  );
};

export default ContactPage;