import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import SoftwareDevelopmentPage from './pages/SoftwareDevelopmentPage';
import ITStaffAugmentationPage from './pages/ITStaffAugmentationPage';
import CustomProjectDevelopmentPage from './pages/CustomProjectDevelopmentPage';
import ConsultingPage from './pages/ConsultingPage';
import DevOpsServicesPage from './pages/DevOpsServicesPage';
import GetMatchedPage from './pages/GetMatchedPage';
import BlogPage from './pages/BlogPage';
import BlogPostPage from './pages/BlogPostPage';
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage';
// import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage';
import CareerLanding from './components/careers/CareerLanding';
import JobListings from './components/careers/JobListings';
import JobView from './components/careers/JobView';
import NotFoundPage from './pages/NotFoundPage';
import ContactPage from './pages/ContactPage';
import ScrollToTop from './utils/ScrollToTop';

// Industry Pages
import FinTechPage from './pages/FinTechPage';
import EcommercePage from './pages/EcommercePage';
import SocialMediaPage from './pages/SocialMediaPage';
import CybersecurityPage from './pages/CybersecurityPage';
import EdTechPage from './pages/EdTechPage';
import CloudServicesPage from './pages/CloudServicesPage';
import CommunicationPage from './pages/CommunicationPage';
import HealthTechPage from './pages/HealthTechPage';

// Expert Role Pages
import FrontendExpertsPage from './pages/FrontendExpertsPage';
import BackendExpertsPage from './pages/BackendExpertsPage';
import DatabaseExpertsPage from './pages/DatabaseExpertsPage';
import DevOpsExpertsPage from './pages/DevOpsExpertsPage';

function App() {
  return (
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/software-development" element={<SoftwareDevelopmentPage />} />
          <Route path="/it-staff-augmentation" element={<ITStaffAugmentationPage />} />
          <Route path="/custom-project-development" element={<CustomProjectDevelopmentPage />} />
          <Route path="/consulting" element={<ConsultingPage />} />
          <Route path="/devops-services" element={<DevOpsServicesPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          {/* <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} /> */}
          <Route path="/get-matched" element={<GetMatchedPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<BlogPostPage />} />
          <Route path="/careers" element={<CareerLanding />} />
          <Route path="/careers/positions" element={<JobListings />} />
          <Route path="/careers/positions/:jobSlug" element={<JobView />} />
          
          {/* Industry Pages */}
          <Route path="/fintech" element={<FinTechPage />} />
          <Route path="/ecommerce" element={<EcommercePage />} />
          <Route path="/socialmedia" element={<SocialMediaPage />} />
          <Route path="/cybersecurity" element={<CybersecurityPage />} />
          <Route path="/edtech" element={<EdTechPage />} />
          <Route path="/cloudservices" element={<CloudServicesPage />} />
          <Route path="/communication" element={<CommunicationPage />} />
          <Route path="/healthtech" element={<HealthTechPage />} />
          
          {/* Expert Role Pages */}
          <Route path="/frontend-experts" element={<FrontendExpertsPage />} />
          <Route path="/backend-experts" element={<BackendExpertsPage />} />
          <Route path="/database-experts" element={<DatabaseExpertsPage />} />
          <Route path="/devops-experts" element={<DevOpsExpertsPage />} />

          
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </Router>
  );
}

export default App;