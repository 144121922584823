import React, { useState } from 'react';
import axios from 'axios';

// General Application Form used in the CareerLanding component
const GeneralApplicationForm = () => {
  // Form state
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    position: '',
    experience: '',
    message: '',
    resume: null,
    acceptPrivacyPolicy: false
  });
  
  // Form validation errors
  const [formErrors, setFormErrors] = useState({});
  
  // Form submission state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionResult, setSubmissionResult] = useState(null);

  // Contact Form 7 ID for the General Application Form
  // Replace with the actual CF7 form ID from your WordPress admin
  const GENERAL_APPLICATION_FORM_ID = 11;

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    
    if (type === 'file') {
      setFormData(prev => ({
        ...prev,
        [name]: files[0] || null
      }));
    } else if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        [name]: checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error for this field if it exists
    if (formErrors[name]) {
      setFormErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  // Validate form
  const validateForm = () => {
    const errors = {};
    
    if (!formData.firstName.trim()) {
      errors.firstName = 'First name is required';
    }
    
    if (!formData.lastName.trim()) {
      errors.lastName = 'Last name is required';
    }
    
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email address is invalid';
    }
    
    if (!formData.resume) {
      errors.resume = 'Resume is required';
    } else {
      const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!allowedTypes.includes(formData.resume.type)) {
        errors.resume = 'Resume must be in PDF, DOC, or DOCX format';
      } else if (formData.resume.size > 10 * 1024 * 1024) { // 10MB
        errors.resume = 'Resume must be smaller than 10MB';
      }
    }
    
    if (!formData.acceptPrivacyPolicy) {
      errors.acceptPrivacyPolicy = 'You must agree to the privacy policy';
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Direct implementation of Contact Form 7 submission
  const submitApplication = async (formId, formData, resumeFile) => {
    try {
      // For development, you can use a mock success response
      // const isDevelopment = process.env.NODE_ENV === 'development';
      const isDevelopment = false;
      const useMockResponse = isDevelopment && false; // Set to true for mock
      
      if (useMockResponse) {
        // Simulate API call delay in development
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Return success mock response
        return { 
          success: true, 
          message: 'Your application has been submitted successfully. We will review it and contact you if a suitable position becomes available.' 
        };
      }
      
      // In production, use actual endpoint
      const apiEndpoint = `https://cms.soft-house.eu/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`;
      
      // Create FormData object for submission
      const form = new FormData();
      
      // Add form ID
      form.append('form_id', formId);
      
      // Add CF7 required fields
      form.append('_wpcf7', formId);
      form.append('_wpcf7_version', '5.7.7'); // Update this to match your CF7 version
      form.append('_wpcf7_locale', 'en_US');
      form.append('_wpcf7_unit_tag', `wpcf7-f${formId}-p1-o1`);
      form.append('_wpcf7_container_post', '0');
      
      // Add form fields
      for (const key in formData) {
        form.append(key, formData[key]);
      }
      
      // Add resume file if provided
      if (resumeFile) {
        form.append('resume', resumeFile);
      }
      
      // Submit the form
      const response = await axios.post(apiEndpoint, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      // Process response
      if (response.data.status === 'mail_sent') {
        return { 
          success: true, 
          message: response.data.message || 'Your application has been submitted successfully. We will review it and contact you if a suitable position becomes available.'
        };
      } else {
        return { 
          success: false, 
          message: response.data.message || 'There was an error submitting your application. Please try again later.'
        };
      }
    } catch (error) {
      console.error('Error submitting application form:', error);
      throw new Error('An error occurred when sending the application. Please try again later.');
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Reset submission result
    setSubmissionResult(null);
    
    // Validate form
    if (!validateForm()) {
      // Scroll to first error
      const firstErrorField = Object.keys(formErrors)[0];
      const errorElement = document.querySelector(`[name="${firstErrorField}"]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }
    
    // Submit form
    try {
      setIsSubmitting(true);
      
      // Prepare form data for Contact Form 7
      const cf7FormData = {
        'your-first-name': formData.firstName,
        'your-last-name': formData.lastName,
        'your-email': formData.email,
        'your-phone': formData.phone || '',
        'position': formData.position || '',
        'experience': formData.experience || '',
        'your-message': formData.message || '',
        'accept-privacy': formData.acceptPrivacyPolicy ? '1' : ''
      };
      
      // Direct submission to Contact Form 7
      const response = await submitApplication(
        GENERAL_APPLICATION_FORM_ID,
        cf7FormData,
        formData.resume
      );
      
      // Show success message
      if (response.success) {
        setSubmissionResult({
          status: 'success',
          message: response.message
        });
        
        // Reset form
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          position: '',
          experience: '',
          message: '',
          resume: null,
          acceptPrivacyPolicy: false
        });
        
        // Scroll to result message
        setTimeout(() => {
          const resultElement = document.getElementById('general-submission-result');
          if (resultElement) {
            resultElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }, 100);
      } else {
        setSubmissionResult({
          status: 'error',
          message: response.message
        });
      }
      
    } catch (error) {
      console.error('Error submitting application:', error);
      setSubmissionResult({
        status: 'error',
        message: error.message || 'There was an error submitting your application. Please try again later.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8">
      {/* Submission Result */}
      {submissionResult && (
        <div 
          id="general-submission-result"
          className={`p-4 mb-6 rounded-lg ${
            submissionResult.status === 'success' 
              ? 'bg-green-100 text-green-800' 
              : 'bg-red-100 text-red-800'
          }`}
        >
          {submissionResult.message}
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">First Name *</label>
            <input 
              type="text" 
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              className={`w-full px-4 py-3 rounded-lg border ${
                formErrors.firstName ? 'border-red-500' : 'border-gray-300'
              } focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300`} 
              required 
            />
            {formErrors.firstName && (
              <p className="mt-1 text-sm text-red-600">{formErrors.firstName}</p>
            )}
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">Last Name *</label>
            <input 
              type="text" 
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              className={`w-full px-4 py-3 rounded-lg border ${
                formErrors.lastName ? 'border-red-500' : 'border-gray-300'
              } focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300`} 
              required 
            />
            {formErrors.lastName && (
              <p className="mt-1 text-sm text-red-600">{formErrors.lastName}</p>
            )}
          </div>
        </div>
        
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email *</label>
            <input 
              type="email" 
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className={`w-full px-4 py-3 rounded-lg border ${
                formErrors.email ? 'border-red-500' : 'border-gray-300'
              } focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300`} 
              required 
            />
            {formErrors.email && (
              <p className="mt-1 text-sm text-red-600">{formErrors.email}</p>
            )}
          </div>
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
            <input 
              type="tel" 
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300"
            />
          </div>
        </div>
        
        <div>
          <label htmlFor="position" className="block text-sm font-medium text-gray-700 mb-1">Interested Position / Area</label>
          <select 
            id="position"
            name="position"
            value={formData.position}
            onChange={handleInputChange}
            className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300"
          >
            <option value="">Select a position or area</option>
            <option value="Software Development">Software Development</option>
            <option value="UI/UX Design">UI/UX Design</option>
            <option value="Project Management">Project Management</option>
            <option value="Quality Assurance">Quality Assurance</option>
            <option value="DevOps">DevOps</option>
            <option value="Data Science">Data Science</option>
            <option value="Other">Other</option>
          </select>
        </div>
        
        <div>
          <label htmlFor="experience" className="block text-sm font-medium text-gray-700 mb-1">Years of Experience</label>
          <select 
            id="experience"
            name="experience"
            value={formData.experience}
            onChange={handleInputChange}
            className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300"
          >
            <option value="">Select years of experience</option>
            <option value="Less than 1 year">Less than 1 year</option>
            <option value="1-3 years">1-3 years</option>
            <option value="3-5 years">3-5 years</option>
            <option value="5-7 years">5-7 years</option>
            <option value="7+ years">7+ years</option>
          </select>
        </div>
        
        <div>
          <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Cover Letter / Additional Information</label>
          <textarea 
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            rows="4" 
            className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300"
          ></textarea>
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Resume / CV *</label>
          <div className="flex items-center justify-center w-full">
            <label className={`flex flex-col items-center justify-center w-full h-32 border-2 ${
              formErrors.resume ? 'border-red-300 bg-red-50' : 'border-gray-300 bg-gray-50'
            } border-dashed rounded-lg cursor-pointer hover:bg-gray-100 transition-colors duration-300`}>
              {formData.resume ? (
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg className="w-8 h-8 mb-3 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <p className="mb-2 text-sm text-gray-700">
                    <span className="font-semibold">{formData.resume.name}</span>
                  </p>
                  <p className="text-xs text-gray-500">
                    {(formData.resume.size / (1024 * 1024)).toFixed(2)} MB
                  </p>
                  <p className="mt-2 text-xs text-blue-600 underline">Click to change</p>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg className="w-8 h-8 mb-3 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                  </svg>
                  <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500">PDF, DOC, or DOCX (MAX. 10MB)</p>
                </div>
              )}
              <input 
                id="resume" 
                name="resume"
                type="file" 
                className="hidden" 
                accept=".pdf,.doc,.docx" 
                onChange={handleInputChange}
                required 
              />
            </label>
          </div>
          {formErrors.resume && (
            <p className="mt-1 text-sm text-red-600">{formErrors.resume}</p>
          )}
        </div>
        
        <div className="flex items-start">
          <div className="flex items-center h-5">
            <input
              id="acceptPrivacyPolicy"
              name="acceptPrivacyPolicy"
              type="checkbox"
              checked={formData.acceptPrivacyPolicy}
              onChange={handleInputChange}
              className={`w-4 h-4 border ${
                formErrors.acceptPrivacyPolicy ? 'border-red-300 bg-red-50' : 'border-gray-300 bg-gray-50'
              } rounded focus:ring-3 focus:ring-purple-300`}
              required
            />
          </div>
          <label htmlFor="acceptPrivacyPolicy" className="ml-2 text-sm text-gray-600">
            I agree to the <a href="/privacy-policy" className="text-purple-600 hover:underline">privacy policy</a> and consent to the processing of my data for recruitment purposes.
          </label>
        </div>
        {formErrors.acceptPrivacyPolicy && (
          <p className="mt-1 text-sm text-red-600">{formErrors.acceptPrivacyPolicy}</p>
        )}
        
        <div>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-4 rounded-lg shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300 ${
              isSubmitting ? 'opacity-70 cursor-not-allowed' : ''
            }`}
          >
            {isSubmitting ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Submitting...
              </span>
            ) : (
              'Submit Application'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

// Replace the placeholder form in the CareerLanding component with this one
// Example usage in CareerLanding:
// <div className="max-w-3xl mx-auto animate-section" id="application-form">
//   <div className={`transition-all duration-1000 ${animateClass('application-form')}`}>
//     <GeneralApplicationForm />
//   </div>
// </div>

export default GeneralApplicationForm;