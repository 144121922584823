import React from 'react';
import { ChevronRight, CheckCircle, Server, Database, Shield, Zap, Code, Settings } from 'lucide-react';

const BackendExpertsPage = () => {
  const expertise = [
    {
      title: "API Development",
      description: "Building robust, scalable, and well-documented RESTful and GraphQL APIs for seamless frontend-backend integration."
    },
    {
      title: "Microservices Architecture",
      description: "Designing and implementing microservices-based systems for scalability, maintainability, and resilience."
    },
    {
      title: "Database Design & Optimization",
      description: "Creating efficient database schemas and optimizing queries for high-performance applications."
    },
    {
      title: "Authentication & Authorization",
      description: "Implementing secure authentication systems, role-based access control, and identity management solutions."
    },
    {
      title: "Server-Side Performance Optimization",
      description: "Optimizing backend systems for maximum throughput, minimal latency, and efficient resource utilization."
    },
    {
      title: "Backend Testing & CI/CD",
      description: "Comprehensive testing strategies and continuous integration/deployment pipelines for reliable backend systems."
    }
  ];

  const technologies = [
    "Node.js", "Python", "Java", "C#/.NET", "PHP/Laravel", "Go",
    "Express.js", "NestJS", "Django", "Spring Boot", "Laravel", "ASP.NET Core",
    "PostgreSQL", "MySQL", "MongoDB", "Redis", "Elasticsearch",
    "Docker", "Kubernetes", "AWS", "Azure", "GCP",
    "Kafka", "RabbitMQ", "gRPC", "GraphQL", "REST",
    "Jest", "Mocha", "PyTest", "JUnit", "CI/CD"
  ];

  const benefits = [
    {
      title: "Scalable System Architecture",
      description: "Our backend experts design systems that grow with your business, handling increasing loads without compromising performance."
    },
    {
      title: "Security-First Development",
      description: "Implement robust security measures to protect your data and users from vulnerabilities and attacks."
    },
    {
      title: "Optimized Performance",
      description: "Create high-performance backend systems that process requests efficiently and minimize response times."
    },
    {
      title: "Seamless Integration",
      description: "Build backend systems that integrate smoothly with frontend applications, third-party services, and existing infrastructure."
    }
  ];

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
                BACKEND EXPERTS
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Top Backend Developers for Your Digital Projects
              </h1>
              <p className="text-xl mb-8 text-purple-100">
                Connect with experienced backend developers who build secure, scalable, and high-performance server-side applications.
              </p>
              <div className="flex flex-wrap gap-4">
                <a 
                  href="/get-matched" 
                  className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Hire Backend Developers
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
                <a 
                  href="/contact" 
                  className="group bg-purple-800/50 backdrop-blur-sm hover:bg-purple-800/70 border border-purple-500/30 text-white px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Contact Us
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </div>
            </div>
            <div className="hidden md:block">
              <div className="relative">
                <div className="absolute -left-16 -top-16 w-64 h-64 bg-purple-500 opacity-20 rounded-full animate-pulse"></div>
                <div className="absolute -right-20 -bottom-20 w-80 h-80 bg-indigo-500 opacity-20 rounded-full animate-pulse" style={{ animationDelay: '1s' }}></div>
                
                <div className="relative bg-white dark:bg-gray-900 p-8 rounded-xl shadow-2xl transform hover:scale-105 transition-all duration-500">
                  <div className="flex gap-4 mb-6">
                    <div className="w-3 h-3 rounded-full bg-red-400"></div>
                    <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                    <div className="w-3 h-3 rounded-full bg-green-400"></div>
                  </div>
                  
                  <div className="space-y-6">
                    <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700 pb-4">
                      <div className="flex items-center">
                        <div className="w-10 h-10 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center font-bold mr-3">
                          <Server size={20} />
                        </div>
                        <div>
                          <div className="font-bold text-gray-900 dark:text-white">Server Dashboard</div>
                          <div className="text-xs text-gray-500 dark:text-gray-400">Performance Metrics</div>
                        </div>
                      </div>
                      <div className="text-xs text-green-600 font-semibold bg-green-100 px-2 py-1 rounded-full">
                        OPERATIONAL
                      </div>
                    </div>
                    
                    <div className="space-y-3">
                      <div className="flex justify-between items-center">
                        <div className="text-sm font-medium text-gray-700 dark:text-gray-300">System Load</div>
                        <div className="text-sm font-bold text-gray-900 dark:text-white">28%</div>
                      </div>
                      <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                        <div className="h-full bg-gradient-to-r from-green-500 to-green-600 rounded-full w-[28%]"></div>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Technologies</span>
                        <span className="font-medium text-gray-900 dark:text-white">Node.js, Java</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Experience</span>
                        <span className="font-medium text-gray-900 dark:text-white">9+ years</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">API Uptime</span>
                        <span className="font-medium text-gray-900 dark:text-white">99.99%</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Response Time</span>
                        <span className="font-medium text-gray-900 dark:text-white">42ms avg</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Backend Expertise */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              EXPERTISE
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Backend Development Expertise
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our backend developers bring extensive expertise in building secure, scalable, and high-performance server-side applications.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {expertise.map((item, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
              >
                <div className="p-4 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  {[<Code />, <Server />, <Database />, <Shield />, <Zap />, <Settings />][index % 6]}
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Why Choose Our Backend Experts */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              BENEFITS
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Why Choose Our Backend Experts
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Working with our backend developers brings numerous advantages to your project.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            {benefits.map((benefit, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
              >
                <h3 className="text-xl font-bold mb-3 text-gray-900">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Technologies */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              TECH STACK
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Backend Technologies We Use
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our backend developers are proficient in a wide range of modern technologies, languages, and frameworks.
            </p>
          </div>

          <div className="flex flex-wrap justify-center gap-4">
            {technologies.map((tech, index) => (
              <div 
                key={index} 
                className="bg-gray-100 text-gray-800 px-4 py-2 rounded-full font-medium hover:bg-purple-100 hover:text-purple-700 transition-colors duration-300"
              >
                {tech}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Hire Backend Experts?</h2>
          <p className="text-xl mb-8 text-purple-200 max-w-3xl mx-auto">
            Connect with our team to discuss your backend development needs and find the perfect developer for your project.
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <a 
              href="/get-matched" 
              className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Find Your Backend Expert
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            <a 
              href="/contact" 
              className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Contact Us
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BackendExpertsPage;