import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Award, Users, Heart, Coffee, Star, Calendar, DollarSign, Code, Globe, Zap, ChevronDown } from 'lucide-react';
import GeneralApplicationForm from './GeneralApplicationForm';

// BookOpen component
const BookOpen = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"/><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"/></svg>
);

const CareerLanding = ({ useGeneralApplicationForm = true }) => {
  const [isVisible, setIsVisible] = useState({});
  const [activeAccordion, setActiveAccordion] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(prev => ({ ...prev, [entry.target.id]: true }));
          }
        });
      },
      { threshold: 0.15, rootMargin: "0px 0px -100px 0px" }
    );

    const sections = document.querySelectorAll('.animate-section');
    sections.forEach(section => observer.observe(section));

    return () => sections.forEach(section => observer.unobserve(section));
  }, []);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const animateClass = (id) => 
    isVisible[id] ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10';

  const benefits = [
    {
      icon: <Zap className="w-6 h-6" />,
      title: "Remote-First Culture",
      description: "Work from anywhere in the world with our flexible remote policy. We focus on results, not your location."
    },
    {
      icon: <Calendar className="w-6 h-6" />,
      title: "Flexible Working Hours",
      description: "We understand that everyone has different peak productivity hours. Structure your workday to fit your lifestyle."
    },
    {
      icon: <DollarSign className="w-6 h-6" />,
      title: "Competitive Compensation",
      description: "We offer industry-leading salaries and performance bonuses that reward your hard work and dedication."
    },
    {
      icon: <Code className="w-6 h-6" />,
      title: "Cutting-Edge Projects",
      description: "Work with the latest technologies on challenging projects for clients across various industries."
    },
    {
      icon: <Globe className="w-6 h-6" />,
      title: "International Exposure",
      description: "Join teams with professionals from different countries and cultures, expanding your global network."
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Learning & Development",
      description: "Access to continuous learning resources, conferences, workshops, and certification support."
    },
  ];

  const faqItems = [
    {
      question: "What is the interview process like at SOFT HOUSE?",
      answer: "Our interview process typically consists of 3-4 stages: an initial screening call with our recruitment team, a technical assessment relevant to your role, and final interviews with the team you'll be working with. We aim to make the process informative and transparent, with clear communication throughout."
    },
    {
      question: "Do you offer relocation assistance?",
      answer: "Yes, for certain roles we offer relocation packages that may include moving costs, temporary accommodation, and assistance with work permits or visas depending on your situation and the position."
    },
    {
      question: "How does the remote work policy function?",
      answer: "We have a remote-first approach, meaning most of our team works remotely. We provide the necessary equipment and tools to ensure you can work effectively from anywhere. We maintain communication through regular video calls, collaborative tools, and occasional in-person meetups."
    },
    {
      question: "What opportunities are there for growth and advancement?",
      answer: "We believe in promoting from within and offer clear career progression paths. You'll have regular performance reviews, access to mentorship, and opportunities to lead projects. We also support lateral moves between teams to help you find your perfect fit within the company."
    },
    {
      question: "What technologies do your teams work with?",
      answer: "Our teams work with a wide range of technologies including JavaScript frameworks (React, Angular, Vue.js), backend technologies (Node.js, Java, Python, PHP/Laravel, .NET), databases (PostgreSQL, MongoDB, MySQL), and cloud platforms (AWS, Azure, GCP). The specific technologies depend on the project and client requirements."
    },
    {
      question: "How do you support professional development?",
      answer: "We offer a dedicated learning budget for courses, certifications, and conferences. We also run internal knowledge-sharing sessions, hackathons, and provide access to online learning platforms. Our mentorship program pairs you with experienced professionals to guide your development."
    }
  ];

  const teamMembers = [
    {
      name: "Sarah K.",
      role: "Frontend Developer",
      testimonial: "Joining SOFT HOUSE was the best career move I've made. The work-life balance is excellent, and I've had opportunities to work on challenging projects that have significantly improved my skills.",
      image: "/images/profiles/w_6.jpg"
    },
    {
      name: "Michael T.",
      role: "Java Architect",
      testimonial: "What I appreciate most about working at SOFT HOUSE is the culture of continuous learning. The company invests in our growth, and I've been able to attend conferences and get certifications that have boosted my career.",
      image: "/images/profiles/m_6.jpg"
    },
    {
      name: "Anya P.",
      role: "Project Manager",
      testimonial: "As someone who values flexibility, SOFT HOUSE has been perfect. The remote-first approach means I can structure my day efficiently, and the international team brings diverse perspectives to every project.",
      image: "/images/profiles/w_7.jpg"
    }
  ];

  return (
    <div>
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
          
          {/* Animated particles */}
          {[...Array(20)].map((_, i) => (
            <div 
              key={i} 
              className="absolute rounded-full bg-white/10"
              style={{
                width: `${Math.random() * 6 + 2}px`,
                height: `${Math.random() * 6 + 2}px`,
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animation: `float ${Math.random() * 10 + 15}s linear infinite`,
                animationDelay: `${Math.random() * 5}s`,
                opacity: Math.random() * 0.5 + 0.3
              }}
            />
          ))}
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto">
            <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
              Join Our Team
            </div>
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Build Your Future With Exceptional Talent
            </h1>
            <p className="text-xl mb-8 text-purple-100">
              Join a global team of passionate professionals creating innovative solutions for clients worldwide. Discover your next career opportunity with SOFT HOUSE.
            </p>
            <div className="flex flex-wrap gap-4 justify-center">
              <Link 
                to="/careers/positions"
                className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
              >
                View Open Positions
                <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
              </Link>
              <a 
                href="#benefits" 
                className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
              >
                Explore Benefits
                <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section id="benefits" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16 animate-section" id="benefits-title">
            <div className={`transition-all duration-1000 ${animateClass('benefits-title')}`}>
              <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
                WHY JOIN US
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Benefits of Working With Us</h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                We believe that happy team members create exceptional results. That's why we offer a range of benefits to help you thrive.
              </p>
            </div>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div className="animate-section" id={`benefit-${index}`} key={index}>
                <div className={`bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-1000 delay-${index * 100} transform hover:scale-105 hover:shadow-xl hover:-translate-y-1 ${animateClass(`benefit-${index}`)}`}>
                  <div className="p-3 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                    {benefit.icon}
                  </div>
                  <h3 className="text-xl font-bold mb-3 text-gray-900">{benefit.title}</h3>
                  <p className="text-gray-600">{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Our Values Section */}
      <section className="py-20 bg-purple-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16 animate-section" id="values-title">
            <div className={`transition-all duration-1000 ${animateClass('values-title')}`}>
              <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
                GUIDING PRINCIPLES
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Our Core Values</h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                These principles define our culture and guide how we work together.
              </p>
            </div>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="animate-section" id="value-1">
              <div className={`bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-1000 ${animateClass('value-1')}`}>
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  <Award className="w-6 h-6" />
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">Excellence</h3>
                <p className="text-gray-600">
                  We strive for excellence in everything we do, from our talent selection process to client communication and project delivery.
                </p>
              </div>
            </div>

            <div className="animate-section" id="value-2">
              <div className={`bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-1000 delay-100 ${animateClass('value-2')}`}>
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  <Users className="w-6 h-6" />
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">Collaboration</h3>
                <p className="text-gray-600">
                  We believe in the power of collaboration and teamwork to achieve exceptional results for our clients and partners.
                </p>
              </div>
            </div>

            <div className="animate-section" id="value-3">
              <div className={`bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-1000 delay-200 ${animateClass('value-3')}`}>
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  <Heart className="w-6 h-6" />
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">Integrity</h3>
                <p className="text-gray-600">
                  We operate with the highest ethical standards, maintaining transparency and honesty in all our business dealings.
                </p>
              </div>
            </div>

            <div className="animate-section" id="value-4">
              <div className={`bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-1000 delay-300 ${animateClass('value-4')}`}>
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  <Globe className="w-6 h-6" />
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">Global Perspective</h3>
                <p className="text-gray-600">
                  We embrace diversity of thought, culture, and experience to create innovative solutions for our global clientele.
                </p>
              </div>
            </div>

            <div className="animate-section" id="value-5">
              <div className={`bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-1000 delay-400 ${animateClass('value-5')}`}>
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  <BookOpen className="w-6 h-6" />
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">Continuous Learning</h3>
                <p className="text-gray-600">
                  We foster a culture of continuous learning and skill development to stay at the forefront of technological advancements.
                </p>
              </div>
            </div>

            <div className="animate-section" id="value-6">
              <div className={`bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-1000 delay-500 ${animateClass('value-6')}`}>
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  <Coffee className="w-6 h-6" />
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">Work-Life Balance</h3>
                <p className="text-gray-600">
                  We promote a healthy work-life balance, recognizing that well-rested professionals deliver the best results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Team Testimonials */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16 animate-section" id="testimonials-title">
            <div className={`transition-all duration-1000 ${animateClass('testimonials-title')}`}>
              <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
                TEAM STORIES
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Hear From Our Team</h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                Discover what it's like to work at SOFT HOUSE from our team members.
              </p>
            </div>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {teamMembers.map((member, index) => (
              <div className="animate-section" id={`team-${index}`} key={index}>
                <div className={`bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden transition-all duration-1000 delay-${index * 200} transform hover:shadow-xl hover:-translate-y-1 ${animateClass(`team-${index}`)}`}>
                  <div className="p-6">
                    <div className="flex items-center mb-4">
                      <div className="w-16 h-16 rounded-full overflow-hidden mr-4">
                        <img src={member.image} alt={member.name} className="w-full h-full object-cover" />
                      </div>
                      <div>
                        <h3 className="text-xl font-bold text-gray-900">{member.name}</h3>
                        <p className="text-purple-600">{member.role}</p>
                      </div>
                    </div>
                    <div className="flex mb-4">
                      {[...Array(5)].map((_, i) => (
                        <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
                      ))}
                    </div>
                    <p className="text-gray-600 italic">&ldquo;{member.testimonial}&rdquo;</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Open Positions CTA */}
      <section className="py-16 bg-purple-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 rounded-2xl overflow-hidden shadow-xl">
            <div className="relative px-6 py-16 sm:px-12 lg:px-16">
              {/* Background decoration */}
              <div className="absolute inset-0 overflow-hidden">
                <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
                <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
              </div>
              
              <div className="relative text-center">
                <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">Ready to Join Our Team?</h2>
                <p className="text-xl text-purple-200 max-w-3xl mx-auto mb-8">
                  Explore our current opportunities or submit a general application to be considered for future openings.
                </p>
                <div className="flex flex-wrap gap-4 justify-center">
                  <Link 
                    to="/careers/positions"
                    className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                  >
                    Browse Open Positions
                    <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                  </Link>
                  <a 
                    href="#general-application" 
                    className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                  >
                    General Application
                    <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16 animate-section" id="faq-title">
            <div className={`transition-all duration-1000 ${animateClass('faq-title')}`}>
              <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
                FREQUENTLY ASKED QUESTIONS
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Common Questions</h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                Everything you need to know about working with SOFT HOUSE.
              </p>
            </div>
          </div>

          <div className="max-w-4xl mx-auto animate-section" id="faq-content">
            <div className={`transition-all duration-1000 ${animateClass('faq-content')}`}>
              <div className="space-y-4">
                {faqItems.map((item, index) => (
                  <div 
                    key={index} 
                    className="border border-gray-200 rounded-xl overflow-hidden transition-all duration-300"
                  >
                    <button 
                      className="flex justify-between items-center w-full p-6 text-left bg-white hover:bg-gray-50 transition-colors duration-300"
                      onClick={() => toggleAccordion(index)}
                    >
                      <span className="font-bold text-lg text-gray-900">{item.question}</span>
                      <ChevronDown 
                        className={`transform transition-transform duration-300 text-purple-600 ${
                          activeAccordion === index ? 'rotate-180' : ''
                        }`} 
                        size={20} 
                      />
                    </button>
                    <div 
                      className={`overflow-hidden transition-all duration-300 ${
                        activeAccordion === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                      }`}
                    >
                      <div className="p-6 pt-0 text-gray-600 bg-white">
                        {item.answer}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* General Application Form */}
      <section id="general-application" className="py-20 bg-purple-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16 animate-section" id="application-title">
            <div className={`transition-all duration-1000 ${animateClass('application-title')}`}>
              <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
                GET IN TOUCH
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">General Application</h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                Don't see a position that matches your skills? Submit your details and we'll contact you when something suitable opens up.
              </p>
            </div>
          </div>

          <div className="max-w-3xl mx-auto animate-section" id="application-form">
            <div className={`transition-all duration-1000 ${animateClass('application-form')}`}>
              {useGeneralApplicationForm ? (
                <GeneralApplicationForm />
              ) : (
                <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8">
                  <form className="space-y-6">
                    {/* Default form that will be replaced by the GeneralApplicationForm component */}
                    <div className="grid md:grid-cols-2 gap-6">
                      <div>
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 mb-1">First Name *</label>
                        <input 
                          type="text" 
                          id="first-name" 
                          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300" 
                          required 
                        />
                      </div>
                      <div>
                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 mb-1">Last Name *</label>
                        <input 
                          type="text" 
                          id="last-name" 
                          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300" 
                          required 
                        />
                      </div>
                    </div>
                    
                    <div>
                      <button
                        type="submit"
                        className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-4 rounded-lg shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                      >
                        Submit Application
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CareerLanding;