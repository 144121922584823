import React, { useState } from 'react';
import { ChevronRight, CheckCircle, ArrowRight, ChevronDown, Server, Cloud, Code, GitBranch, Shield, Database, BarChart } from 'lucide-react';

const DevOpsServicesPage = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const devopsServices = [
    {
      icon: <Cloud className="w-6 h-6" />,
      title: "Cloud Infrastructure",
      description: "Design, migration, and management of scalable cloud infrastructure on AWS, Azure, or Google Cloud Platform."
    },
    {
      icon: <GitBranch className="w-6 h-6" />,
      title: "CI/CD Implementation",
      description: "Set up automated pipelines for continuous integration and delivery to accelerate your software development lifecycle."
    },
    {
      icon: <Server className="w-6 h-6" />,
      title: "Infrastructure as Code",
      description: "Implement infrastructure as code using Terraform, Ansible, or CloudFormation for consistent, version-controlled environments."
    },
    {
      icon: <Database className="w-6 h-6" />,
      title: "Database DevOps",
      description: "Apply DevOps principles to database development and operations for improved reliability and performance."
    },
    {
      icon: <Shield className="w-6 h-6" />,
      title: "DevSecOps",
      description: "Integrate security throughout the development lifecycle to identify and address vulnerabilities early."
    },
    {
      icon: <BarChart className="w-6 h-6" />,
      title: "Monitoring & Observability",
      description: "Implement comprehensive monitoring solutions to gain insights into system performance and behavior."
    }
  ];

  const tools = {
    cicd: [
      "Jenkins", "GitLab CI", "GitHub Actions", "CircleCI", "Azure DevOps", "ArgoCD"
    ],
    infrastructure: [
      "Terraform", "Ansible", "Puppet", "Chef", "CloudFormation", "Pulumi"
    ],
    containerization: [
      "Docker", "Kubernetes", "Helm", "Istio", "OpenShift", "Amazon ECS"
    ],
    monitoring: [
      "Prometheus", "Grafana", "ELK Stack", "Datadog", "New Relic", "Dynatrace"
    ]
  };

  const caseStudies = [
    {
      title: "CI/CD Pipeline Modernization",
      client: "Enterprise SaaS Provider",
      industry: "Software",
      challenge: "The client's deployment process was manual, error-prone, and time-consuming, leading to frequent production issues and slow feature delivery.",
      solution: "We implemented a fully automated CI/CD pipeline using GitHub Actions and ArgoCD with comprehensive testing stages and progressive delivery capabilities.",
      results: [
        "Deployment frequency increased from monthly to daily",
        "85% reduction in deployment-related incidents",
        "Reduced lead time from 2 weeks to less than 1 day"
      ],
    },
    {
      title: "Cloud Migration & Infrastructure Automation",
      client: "Financial Services Firm",
      industry: "Finance",
      challenge: "The client needed to migrate from on-premises infrastructure to AWS while ensuring security, compliance, and operational efficiency.",
      solution: "We developed a comprehensive cloud migration strategy and implemented infrastructure as code using Terraform with automated security and compliance checks.",
      results: [
        "40% reduction in infrastructure costs",
        "Environment provisioning time reduced from weeks to minutes",
        "Passed all compliance audits on the first attempt"
      ],
    },
    {
      title: "Kubernetes Platform Implementation",
      client: "E-commerce Platform",
      industry: "Retail",
      challenge: "The client needed a scalable container orchestration solution to handle seasonal traffic spikes and improve resource utilization.",
      solution: "We designed and implemented a Kubernetes platform with auto-scaling capabilities, GitOps workflows, and comprehensive monitoring.",
      results: [
        "99.99% platform availability during peak season",
        "65% improvement in resource utilization",
        "Ability to scale from 100 to 1000 services in minutes"
      ],
    }
  ];

  const benefits = [
    {
      title: "Faster Time to Market",
      description: "Accelerate software delivery through automation, reducing manual steps and enabling more frequent, reliable releases."
    },
    {
      title: "Improved Reliability",
      description: "Enhance system stability with automated testing, consistent environments, and proactive monitoring."
    },
    {
      title: "Increased Efficiency",
      description: "Optimize resource utilization and reduce operational overhead through infrastructure automation and self-service capabilities."
    },
    {
      title: "Enhanced Security",
      description: "Integrate security practices throughout the development process to identify and address vulnerabilities earlier."
    },
    {
      title: "Scalability",
      description: "Build systems that can easily scale to handle growing workloads and user demands without major restructuring."
    },
    {
      title: "Cost Optimization",
      description: "Reduce infrastructure costs through efficient resource utilization, automation, and cloud-native approaches."
    }
  ];

  const faqItems = [
    {
      question: "What makes your DevOps services different?",
      answer: "Our approach to DevOps goes beyond implementing tools—we focus on cultural transformation, process improvement, and technology enablement. We bring expertise from hundreds of successful implementations across diverse industries, allowing us to tailor solutions to your specific needs while following industry best practices. Additionally, our team includes certified experts in all major cloud platforms and DevOps tools."
    },
    {
      question: "How long does it take to implement DevOps practices?",
      answer: "The timeline varies depending on your organization's current maturity level, complexity, and objectives. Initial improvements can be implemented in a few weeks, while comprehensive DevOps transformation typically spans 3-6 months for meaningful results. We focus on incremental improvements that deliver value quickly while building toward long-term goals."
    },
    {
      question: "Can you work with our existing tools and infrastructure?",
      answer: "Absolutely. We assess your current tooling and infrastructure to determine what can be leveraged, improved, or replaced. Our approach is pragmatic—we recommend changes only where they deliver significant value, and we integrate with your existing systems whenever possible. We're tool-agnostic and have expertise across a wide range of DevOps technologies."
    },
    {
      question: "How do you ensure knowledge transfer to our team?",
      answer: "Knowledge transfer is built into our methodology. We work collaboratively with your team throughout the engagement, providing hands-on training, comprehensive documentation, and mentoring. We also offer formal training sessions and workshops tailored to your team's needs. Our goal is to enable your team to independently maintain and evolve the DevOps practices we implement."
    },
    {
      question: "How do you measure DevOps success?",
      answer: "We establish key performance indicators (KPIs) at the start of each engagement, aligned with your business objectives. Common metrics include deployment frequency, lead time for changes, change failure rate, and mean time to recovery. We implement monitoring tools to track these metrics and provide regular reports on progress and areas for improvement."
    }
  ];

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
                DevOps Services
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Accelerate Development with DevOps Excellence
              </h1>
              <p className="text-xl mb-8 text-purple-100 max-w-lg">
                Streamline your software development lifecycle with our expert DevOps services. Automate, optimize, and transform your delivery pipeline for faster, more reliable releases.
              </p>
              <div className="flex flex-wrap gap-4">
                <a 
                  href="/get-matched" 
                  className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Discuss Your Project
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
                <a 
                  href="#case-studies" 
                  className="group bg-purple-800/50 backdrop-blur-sm hover:bg-purple-800/70 border border-purple-500/30 text-white px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  View Case Studies
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </div>
            </div>
            <div>
              <div className="relative">
                <div className="absolute -left-4 -top-4 w-64 h-64 bg-purple-500/30 rounded-full animate-pulse"></div>
                <div className="relative bg-white/10 backdrop-blur-sm border border-white/20 p-8 rounded-xl shadow-2xl">
                  <div className="p-6 bg-purple-600 text-white rounded-xl inline-block mb-6 shadow-lg">
                    <Server size={48} />
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-white">End-to-End DevOps Solutions</h3>
                  <ul className="space-y-3 mb-8">
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">CI/CD Pipeline Automation</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Infrastructure as Code</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Container Orchestration</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Monitoring & Observability</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Security & Compliance</span>
                    </li>
                  </ul>
                  <div className="text-center">
                    <div className="text-sm text-purple-200 mb-2">Cloud Platform Expertise</div>
                    <div className="flex justify-center space-x-4">
                      <div className="px-3 py-1 bg-purple-500/30 backdrop-blur-sm rounded-full text-xs font-medium border border-white/10">AWS</div>
                      <div className="px-3 py-1 bg-purple-500/30 backdrop-blur-sm rounded-full text-xs font-medium border border-white/10">Azure</div>
                      <div className="px-3 py-1 bg-purple-500/30 backdrop-blur-sm rounded-full text-xs font-medium border border-white/10">GCP</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR SERVICES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Comprehensive DevOps Services
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              From initial assessment to full implementation, we provide end-to-end DevOps solutions tailored to your needs.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {devopsServices.map((service, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-300 hover:shadow-xl hover:-translate-y-1"
              >
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  {service.icon}
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Tools & Technologies */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              TOOLCHAIN
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              DevOps Tools & Technologies
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              We have expertise across a wide range of industry-leading DevOps tools and platforms.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8">
              <div className="flex items-center mb-6">
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg mr-4">
                  <GitBranch size={24} />
                </div>
                <h3 className="text-xl font-bold text-gray-900">CI/CD Tools</h3>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {tools.cicd.map((tool, index) => (
                  <div key={index} className="flex items-center p-3 border border-gray-100 rounded-lg">
                    <CheckCircle size={16} className="text-purple-600 mr-2" />
                    <span className="text-gray-700">{tool}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8">
              <div className="flex items-center mb-6">
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg mr-4">
                  <Server size={24} />
                </div>
                <h3 className="text-xl font-bold text-gray-900">Infrastructure Tools</h3>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {tools.infrastructure.map((tool, index) => (
                  <div key={index} className="flex items-center p-3 border border-gray-100 rounded-lg">
                    <CheckCircle size={16} className="text-purple-600 mr-2" />
                    <span className="text-gray-700">{tool}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8">
              <div className="flex items-center mb-6">
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg mr-4">
                  <Code size={24} />
                </div>
                <h3 className="text-xl font-bold text-gray-900">Containerization</h3>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {tools.containerization.map((tool, index) => (
                  <div key={index} className="flex items-center p-3 border border-gray-100 rounded-lg">
                    <CheckCircle size={16} className="text-purple-600 mr-2" />
                    <span className="text-gray-700">{tool}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8">
              <div className="flex items-center mb-6">
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg mr-4">
                  <BarChart size={24} />
                </div>
                <h3 className="text-xl font-bold text-gray-900">Monitoring & Observability</h3>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {tools.monitoring.map((tool, index) => (
                  <div key={index} className="flex items-center p-3 border border-gray-100 rounded-lg">
                    <CheckCircle size={16} className="text-purple-600 mr-2" />
                    <span className="text-gray-700">{tool}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              BENEFITS
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Why Implement DevOps?
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              DevOps practices deliver significant business and technical advantages for organizations of all sizes.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-300 hover:shadow-xl"
              >
                <h3 className="text-xl font-bold mb-3 text-purple-700">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Case Studies */}
      <section id="case-studies" className="py-20 bg-purple-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              SUCCESS STORIES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              DevOps Transformation Case Studies
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              See how we've helped organizations transform their delivery capabilities with DevOps practices.
            </p>
          </div>

          <div className="space-y-12">
            {caseStudies.map((study, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden transition-all duration-300 hover:shadow-xl"
              >
                <div className="grid md:grid-cols-5 gap-0">
                  <div className="md:col-span-2 bg-purple-50 p-8 flex flex-col justify-center">
                    <div className="inline-block bg-purple-600 text-white px-4 py-1 rounded-full text-sm font-semibold mb-4">
                      {study.industry}
                    </div>
                    <h3 className="text-2xl font-bold mb-3 text-gray-900">{study.title}</h3>
                    <p className="text-gray-600 mb-4">Client: {study.client}</p>
                  </div>
                  <div className="md:col-span-3 p-8">                    
                    <div className="mb-4">
                      <h4 className="font-medium text-gray-900 mb-2">Challenge:</h4>
                      <p className="text-gray-600">{study.challenge}</p>
                    </div>
                    
                    <div className="mb-4">
                      <h4 className="font-medium text-gray-900 mb-2">Solution:</h4>
                      <p className="text-gray-600">{study.solution}</p>
                    </div>
                    
                    <div>
                      <h4 className="font-medium text-gray-900 mb-2">Results:</h4>
                      <ul className="space-y-1">
                        {study.results.map((result, i) => (
                          <li key={i} className="flex items-center text-gray-600">
                            <CheckCircle size={16} className="text-green-600 mr-2 flex-shrink-0" />
                            <span>{result}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-white">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              FAQ
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Frequently Asked Questions
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Find answers to common questions about our DevOps services.
            </p>
          </div>

          <div className="space-y-4">
            {faqItems.map((item, index) => (
              <div 
                key={index} 
                className="border border-gray-200 rounded-xl overflow-hidden transition-all duration-300"
              >
                <button 
                  className="flex justify-between items-center w-full p-6 text-left bg-white hover:bg-gray-50 transition-colors duration-300"
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="font-bold text-lg text-gray-900">{item.question}</span>
                  <ChevronDown 
                    className={`transform transition-transform duration-300 text-purple-600 ${
                      activeAccordion === index ? 'rotate-180' : ''
                    }`} 
                    size={20} 
                  />
                </button>
                <div 
                  className={`overflow-hidden transition-all duration-300 ${
                    activeAccordion === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                  }`}
                >
                  <div className="p-6 pt-0 text-gray-600 bg-white">
                    {item.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Accelerate Your Delivery Pipeline?</h2>
          <p className="text-xl mb-8 text-purple-200 max-w-3xl mx-auto">
            Partner with our DevOps experts to transform your software delivery capabilities and achieve faster, more reliable releases.
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <a 
              href="/get-matched" 
              className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Start Your DevOps Journey
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            <a 
              href="/contact" 
              className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Contact Us
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DevOpsServicesPage;