import React from 'react';
import PolicyPage from '../components/PolicyPage';

export const PrivacyPolicyPage = () => {
  const privacyPolicySections = [
    {
      title: "Information We Collect",
      content: (
        <>
          <p>We may collect the following information:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Name and job title</li>
            <li>Contact information including email address</li>
            <li>Demographic information such as postcode, preferences, and interests</li>
            <li>Other information relevant to customer surveys and/or offers</li>
            <li>CV/Resume information for talent matching purposes</li>
            <li>Technical information about your visits to our website</li>
          </ul>
          <p className="mt-4">
            We collect this information to understand your needs and provide you with a better service, 
            particularly for the following reasons:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Internal record keeping</li>
            <li>Improving our products and services</li>
            <li>Periodically sending promotional emails about new services, special offers, or other information which we think you may find interesting</li>
            <li>To contact you for market research purposes</li>
            <li>To match you with suitable job opportunities or talent</li>
          </ul>
        </>
      )
    },
    {
      title: "Contact Forms",
      content: (
        <>
          <p>
            When you use our contact forms to get in touch with us, we collect and process the following information:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Your name and email address</li>
            <li>Your phone number (if provided)</li>
            <li>The contents of your message</li>
            <li>The date and time of your submission</li>
          </ul>
          <p className="mt-4">
            This information is used solely to:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Respond to your inquiries</li>
            <li>Provide you with the information, products, or services you requested</li>
            <li>Maintain a record of our communications</li>
          </ul>
          <p className="mt-4">
            We retain contact form submissions for up to 24 months to ensure we can address any follow-up questions 
            and improve our customer service. You may request the deletion of your contact form data at any time by 
            emailing <a href="mailto:privacy@soft-house.eu" className="text-purple-600 hover:text-purple-800">privacy@soft-house.eu</a>.
          </p>
        </>
      )
    },
    {
      title: "Talent Matching Service",
      content: (
        <>
          <p>
            Our "Get Matched" service enables businesses to connect with technical talent and professionals to find suitable work opportunities. 
            When you use this service, we collect additional information to facilitate appropriate matches:
          </p>
          <h4 className="font-semibold text-lg mt-4 mb-2">For Businesses Seeking Talent:</h4>
          <ul className="list-disc pl-6 space-y-2">
            <li>Company name, size, and industry</li>
            <li>Project requirements and technical specifications</li>
            <li>Preferred skills, experience levels, and qualifications</li>
            <li>Budget ranges and timeframes</li>
            <li>Team culture and work environment details</li>
          </ul>
          <h4 className="font-semibold text-lg mt-4 mb-2">For Professionals Seeking Opportunities:</h4>
          <ul className="list-disc pl-6 space-y-2">
            <li>Professional experience, skills, and qualifications</li>
            <li>CV/Resume and portfolio materials</li>
            <li>Availability and work preferences</li>
            <li>Salary expectations</li>
            <li>Professional references (with their consent)</li>
          </ul>
          <p className="mt-4">
            This information is used to:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Create and optimize matching profiles in our system</li>
            <li>Identify suitable matches between talent and business requirements</li>
            <li>Facilitate introductions between matched parties</li>
            <li>Improve our matching algorithms and processes</li>
            <li>Provide ongoing support throughout the engagement</li>
          </ul>
          <p className="mt-4">
            Your matching profile data is retained for as long as your account remains active, plus an additional 24 months to 
            facilitate potential future matches. You may request the deletion of your matching data at any time, though this 
            will limit our ability to find matches for you.
          </p>
        </>
      )
    },
    {
      title: "Career Applications",
      content: (
        <>
          <p>
            When you apply for a position through our Careers page, we collect and process application data to evaluate your 
            candidacy and manage our recruitment process. This includes:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Full name and contact details</li>
            <li>CV/Resume, cover letters, and portfolios</li>
            <li>Educational background and qualifications</li>
            <li>Employment history and professional experience</li>
            <li>Skills, certifications, and professional achievements</li>
            <li>References and recommendation letters (with their consent)</li>
            <li>Work eligibility documentation</li>
            <li>Any additional information you choose to provide in support of your application</li>
          </ul>
          <p className="mt-4">
            We process this information to:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Assess your suitability for the position you've applied for</li>
            <li>Communicate with you about your application and interview process</li>
            <li>Verify your provided information and conduct background checks (with your consent)</li>
            <li>Create employment records if you are hired</li>
            <li>Comply with legal and regulatory employment requirements</li>
          </ul>
          <p className="mt-4">
            We may also retain your application information to consider you for other current or future positions that match 
            your qualifications, but only with your explicit consent.
          </p>
          <p className="mt-4">
            Application data is retained for:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Successful candidates: The duration of employment plus 7 years</li>
            <li>Unsuccessful candidates: Up to 2 years (with consent) to consider for future positions</li>
            <li>Candidates who withdraw: Up to 6 months for record-keeping purposes</li>
          </ul>
          <p className="mt-4">
            You may request the deletion of your application data at any time by contacting our HR department at 
            <a href="mailto:careers@soft-house.eu" className="text-purple-600 hover:text-purple-800 ml-1">careers@soft-house.eu</a>.
          </p>
        </>
      )
    },
    {
      title: "Legal Basis for Processing",
      content: (
        <>
          <p>
            We process your personal data on the following legal grounds:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li><span className="font-semibold">Consent:</span> Where you have explicitly agreed to our processing of your data for one or more specific purposes.</li>
            <li><span className="font-semibold">Contract Performance:</span> When processing is necessary for the performance of a contract with you or to take steps at your request before entering into a contract.</li>
            <li><span className="font-semibold">Legitimate Interests:</span> Where processing is necessary for our legitimate interests or those of a third party, except where such interests are overridden by your interests or fundamental rights.</li>
            <li><span className="font-semibold">Legal Obligation:</span> When processing is necessary for compliance with a legal obligation to which we are subject.</li>
          </ul>
          <p className="mt-4">
            Specifically:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>For contact forms: We process your data based on your consent and our legitimate interest in responding to your inquiry.</li>
            <li>For talent matching: We process your data based on your consent and for the performance of our service contract with you.</li>
            <li>For job applications: We process your data based on your consent and our legitimate interest in evaluating your suitability for employment.</li>
          </ul>
        </>
      )
    },
    {
      title: "Data Security",
      content: (
        <>
          <p>
            We are committed to ensuring that your information is secure. To prevent unauthorized access or disclosure,
            we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure
            the information we collect online.
          </p>
          <p className="mt-4">
            Our security measures include:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Encryption of sensitive data</li>
            <li>Regular security assessments</li>
            <li>Access controls for internal systems</li>
            <li>Staff training on data protection</li>
            <li>Secure data storage</li>
          </ul>
        </>
      )
    },
    {
      title: "Data Sharing and Third Parties",
      content: (
        <>
          <p>
            We may share your information with the following types of third parties:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li><span className="font-semibold">Service Providers:</span> Third-party companies that perform services on our behalf, such as web hosting, data analysis, payment processing, and customer service.</li>
            <li><span className="font-semibold">Business Partners:</span> With your consent, we may share your information with businesses seeking talent or professionals seeking opportunities as part of our matching service.</li>
            <li><span className="font-semibold">Legal Requirements:</span> When required by law, court order, or governmental regulation.</li>
            <li><span className="font-semibold">Business Transfers:</span> In connection with a merger, acquisition, or sale of assets.</li>
          </ul>
          <p className="mt-4">
            For talent matching specifically:
          </p>
          <p>
            When a match is identified, we will share your professional profile information with potential business partners 
            or talent, but only after obtaining your explicit consent for each specific opportunity. You maintain control over 
            what information is shared and with whom.
          </p>
          <p className="mt-4">
            We do not sell your personal information to third parties.
          </p>
        </>
      )
    },
    {
      title: "How We Use Cookies",
      content: (
        <>
          <p>
            A cookie is a small file that asks permission to be placed on your computer's hard drive. 
            Once you agree, the file is added, and the cookie helps analyze web traffic or lets you know when you visit a particular site.
          </p>
          <p className="mt-4">
            We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic
            and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes.
          </p>
          <p className="mt-4">
            You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify
            your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
          </p>
        </>
      )
    },
    {
      title: "Your Rights",
      content: (
        <>
          <p>
            Under applicable data protection laws, you have the following rights regarding your personal data:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li><span className="font-semibold">Right to Access:</span> You can request a copy of the personal data we hold about you.</li>
            <li><span className="font-semibold">Right to Rectification:</span> You can request that we correct inaccurate or incomplete data.</li>
            <li><span className="font-semibold">Right to Erasure:</span> You can request that we delete your personal data in certain circumstances.</li>
            <li><span className="font-semibold">Right to Restrict Processing:</span> You can request that we limit how we use your data.</li>
            <li><span className="font-semibold">Right to Data Portability:</span> You can request a machine-readable copy of your data to be transferred to another provider.</li>
            <li><span className="font-semibold">Right to Object:</span> You can object to our processing of your data for certain purposes.</li>
            <li><span className="font-semibold">Right to Withdraw Consent:</span> Where processing is based on consent, you can withdraw your consent at any time.</li>
          </ul>
          <p className="mt-4">
            To exercise any of these rights, please contact us at <a href="mailto:privacy@soft-house.eu" className="text-purple-600 hover:text-purple-800">privacy@soft-house.eu</a>. 
            We will respond to your request within 30 days. In certain circumstances, we may need to verify your identity before processing your request.
          </p>
        </>
      )
    },
    {
      title: "International Data Transfers",
      content: (
        <>
          <p>
            SOFT HOUSE operates in multiple countries, and your personal data may be transferred to and processed in countries 
            outside of your residence. These countries may have different data protection laws than your country of residence.
          </p>
          <p className="mt-4">
            When we transfer personal data internationally, we ensure appropriate safeguards are in place to protect your 
            information and comply with applicable data protection laws. These safeguards may include:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Standard contractual clauses approved by the European Commission</li>
            <li>Binding corporate rules for transfers within our corporate group</li>
            <li>Derogations such as consent, contract performance, or important public interest grounds</li>
          </ul>
          <p className="mt-4">
            By using our services, you acknowledge that your personal data may be transferred to and processed in countries 
            outside your country of residence, including the United Kingdom, Poland, and other countries where SOFT HOUSE 
            operates or uses service providers.
          </p>
        </>
      )
    },
    {
      title: "Changes to This Policy",
      content: (
        <>
          <p>
            We may update this privacy policy from time to time to reflect changes in our practices or for other operational, 
            legal, or regulatory reasons. We will notify you of any material changes by:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Posting the updated policy on our website with a new effective date</li>
            <li>Sending an email notification to registered users (for significant changes)</li>
            <li>Displaying a notice on our website</li>
          </ul>
          <p className="mt-4">
            We encourage you to review this policy periodically to stay informed about how we collect, use, and protect your 
            personal information.
          </p>
        </>
      )
    },
    {
      title: "Contact Us",
      content: (
        <>
          <p>
            If you have any questions, concerns, or requests regarding this privacy policy or our data practices, please contact us at:
          </p>
          <p className="mt-4">
            <strong>Data Protection Officer</strong><br />
            SOFT HOUSE LTD<br />
            321-323 High Road<br />
            Essex, RM6 6AX<br />
            United Kingdom<br />
            Email: <a href="mailto:privacy@soft-house.eu" className="text-purple-600 hover:text-purple-800">privacy@soft-house.eu</a>
          </p>
          <p className="mt-4">
            We are committed to working with you to obtain a fair resolution of any complaint or concern about privacy.
          </p>
        </>
      )
    }
  ];
  
  return (
    <PolicyPage
      title="Privacy Policy"
      lastUpdated="March 1, 2025"
      sections={privacyPolicySections}
      contactEmail="privacy@soft-house.eu"
    />
  );
};

export default PrivacyPolicyPage;