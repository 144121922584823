import React, { useState } from 'react';
import { ChevronRight, Users, CheckCircle, ArrowRight, ChevronDown, Clock, Zap, Shield, Award, BarChart } from 'lucide-react';

const ITStaffAugmentationPage = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const benefits = [
    {
      icon: <Clock className="w-6 h-6" />,
      title: "Time & Cost Efficiency",
      description: "Reduce hiring time by up to 60% and eliminate recruitment costs with our pre-vetted talent pool."
    },
    {
      icon: <Zap className="w-6 h-6" />,
      title: "Scalability",
      description: "Quickly scale your team up or down based on project requirements and business needs."
    },
    {
      icon: <Shield className="w-6 h-6" />,
      title: "Risk Mitigation",
      description: "Minimize hiring risks with our thoroughly vetted professionals and flexible engagement models."
    },
    {
      icon: <Award className="w-6 h-6" />,
      title: "Access to Top Talent",
      description: "Tap into our pool of top 5% tech professionals across various specializations and expertise levels."
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Cultural Fit",
      description: "We match professionals not just on technical skills but also on cultural alignment with your organization."
    },
    {
      icon: <BarChart className="w-6 h-6" />,
      title: "Enhanced Productivity",
      description: "Our talent integrates seamlessly with your existing team to boost productivity and project delivery."
    }
  ];

  const roles = [
    {
      category: "Software Development",
      positions: [
        "Frontend Developers (React, Angular, Vue)",
        "Backend Developers (Node.js, Python, Java)",
        "Full Stack Engineers",
        "Mobile Developers (iOS, Android, React Native)",
        "DevOps Engineers"
      ]
    },
    {
      category: "Quality Assurance",
      positions: [
        "QA Engineers",
        "Test Automation Specialists",
        "Performance Testers",
        "Security Testing Experts",
        "QA Managers"
      ]
    },
    {
      category: "Design & UX",
      positions: [
        "UI/UX Designers",
        "Product Designers",
        "Interaction Designers",
        "Visual Designers",
        "UX Researchers"
      ]
    },
    {
      category: "Management",
      positions: [
        "Project Managers",
        "Scrum Masters",
        "Product Owners",
        "Delivery Managers",
        "Technical Team Leads"
      ]
    }
  ];

  const testimonials = [
    {
      quote: "SOFT HOUSE's team integrated seamlessly with our in-house developers. Their technical expertise and cultural fit made it feel like they were part of our company from day one.",
      author: "James Wilson",
      position: "CTO",
      company: "FinTech Solutions Inc.",
      image: "/images/profiles/m_2.jpg"
    },
    {
      quote: "We needed to scale our development team quickly for a major project. SOFT HOUSE provided us with top-tier developers within days, significantly accelerating our development timeline.",
      author: "Emma Rodriguez",
      position: "VP of Engineering",
      company: "E-commerce Platform",
      image: "/images/profiles/w_3.jpg"
    },
    {
      quote: "The flexibility of SOFT HOUSE's staff augmentation model allowed us to efficiently manage our resources during peak development periods without compromising on talent quality.",
      author: "Michael Chang",
      position: "Product Director",
      company: "Healthcare Tech",
      image: "/images/profiles/m_3.jpg"
    }
  ];

  const faqItems = [
    {
      question: "How quickly can you provide talent for our project?",
      answer: "We can typically provide you with carefully matched candidates within 48-72 hours of receiving your requirements. Our extensive pre-vetted talent pool allows us to quickly identify professionals who match your technical and cultural needs."
    },
    {
      question: "What is your vetting process for developers?",
      answer: "Our rigorous vetting process includes technical skills assessment, code reviews, problem-solving challenges, communication evaluation, background checks, and cultural fit assessment. Only the top 5% of candidates make it through our comprehensive screening process."
    },
    {
      question: "What engagement models do you offer?",
      answer: "We offer flexible engagement models including full-time dedicated resources, part-time specialists, project-based teams, and extended team arrangements. We work with you to determine the most suitable model based on your specific project requirements and business needs."
    },
    {
      question: "How do you ensure the quality of developers?",
      answer: "We maintain quality through our rigorous selection process, regular performance evaluations, continuous skill development programs, and client feedback mechanisms. We also provide ongoing support to ensure successful integration and productivity of our talent with your team."
    },
    {
      question: "What if the developer isn't a good fit for our team?",
      answer: "In the rare case that a professional isn't a good fit, we offer a replacement guarantee. We'll quickly provide an alternative candidate at no additional cost during the initial trial period, ensuring minimal disruption to your project timeline."
    }
  ];

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
                IT Staff Augmentation
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Enhance Your Team With Top Tech Talent
              </h1>
              <p className="text-xl mb-8 text-purple-100 max-w-lg">
                Scale your development capabilities with our pre-vetted IT professionals who integrate seamlessly with your existing team.
              </p>
              <div className="flex flex-wrap gap-4">
                <a 
                  href="/get-matched" 
                  className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Find Your Match
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
                <a 
                  href="#how-it-works" 
                  className="group bg-purple-800/50 backdrop-blur-sm hover:bg-purple-800/70 border border-purple-500/30 text-white px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  How It Works
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </div>
            </div>
            <div>
              <div className="relative">
                <div className="absolute -left-4 -top-4 w-64 h-64 bg-purple-500/30 rounded-full animate-pulse"></div>
                <div className="relative bg-white/10 backdrop-blur-sm border border-white/20 p-8 rounded-xl shadow-2xl">
                  <div className="p-6 bg-purple-600 text-white rounded-xl inline-block mb-6 shadow-lg">
                    <Users size={48} />
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-white">Flexible Team Extension</h3>
                  <ul className="space-y-3 mb-8">
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Access to Top 5% Tech Talent</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">48-Hour Matching Process</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Seamless Team Integration</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Flexible Engagement Models</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Cultural & Technical Fit</span>
                    </li>
                  </ul>
                  {/* <div className="text-center">
                    <div className="text-sm text-purple-200 mb-2">Trusted by Industry Leaders</div>
                    <div className="flex justify-center space-x-4">
                      {[1, 2, 3].map((i) => (
                        <div key={i} className="w-8 h-8 bg-white/20 rounded-full"></div>
                      ))}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              BENEFITS
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Why Choose SOFT HOUSE Staff Augmentation
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our staff augmentation model provides numerous advantages over traditional hiring processes.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-300 hover:shadow-xl hover:-translate-y-1"
              >
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  {benefit.icon}
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* How It Works */}
      <section id="how-it-works" className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR PROCESS
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              How Our Staff Augmentation Works
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our streamlined process ensures we find the perfect candidates for your team.
            </p>
          </div>

          <div className="relative">
            {/* Connection line */}
            <div className="hidden md:block absolute top-24 left-0 w-full h-1 bg-purple-200 z-0"></div>
            
            <div className="grid md:grid-cols-4 gap-8">
              {[
                { title: "Requirements Gathering", description: "We understand your technical needs, team culture, and project specifics" },
                { title: "Talent Matching", description: "Our AI matching algorithm identifies ideal candidates from our pre-vetted pool" },
                { title: "Interview & Selection", description: "You interview and select the professionals who best fit your requirements" },
                { title: "Onboarding & Support", description: "We facilitate seamless integration and provide ongoing support" }
              ].map((step, index) => (
                <div key={index} className="relative">
                  <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 relative z-10 h-full hover:shadow-xl transition-all duration-300">
                    <div className="w-12 h-12 bg-purple-600 text-white rounded-full flex items-center justify-center font-bold text-xl mb-4 mx-auto">{index + 1}</div>
                    <h3 className="text-xl font-bold mb-3 text-center text-gray-900">{step.title}</h3>
                    <p className="text-gray-600 text-center">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-16 text-center">
            <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-lg border border-gray-100 p-8">
              <h3 className="text-2xl font-bold mb-4 text-gray-900">Quick Turnaround Time</h3>
              <p className="text-gray-600 mb-6">
                Our efficient process allows us to provide you with matched candidates within 48 hours of receiving your requirements.
              </p>
              <div className="grid grid-cols-3 gap-4">
                <div className="text-center p-4 bg-purple-50 rounded-lg">
                  <div className="text-3xl font-bold text-purple-600 mb-2">48h</div>
                  <div className="text-sm text-gray-600">Initial Matching</div>
                </div>
                <div className="text-center p-4 bg-purple-50 rounded-lg">
                  <div className="text-3xl font-bold text-purple-600 mb-2">72h</div>
                  <div className="text-sm text-gray-600">Interview Setup</div>
                </div>
                <div className="text-center p-4 bg-purple-50 rounded-lg">
                  <div className="text-3xl font-bold text-purple-600 mb-2">1 week</div>
                  <div className="text-sm text-gray-600">Team Integration</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Available Roles */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              TALENT POOL
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Available Technical Roles
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              We offer a wide range of technical professionals to match your specific project needs.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            {roles.map((role, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-300 hover:shadow-xl"
              >
                <h3 className="text-xl font-bold mb-4 text-purple-700">{role.category}</h3>
                <ul className="space-y-2">
                  {role.positions.map((position, i) => (
                    <li key={i} className="flex items-center text-gray-700">
                      <CheckCircle size={16} className="text-purple-600 mr-2 flex-shrink-0" />
                      <span>{position}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="mt-12 text-center">
            <a 
              href="/get-matched" 
              className="inline-flex items-center text-purple-600 font-medium group"
            >
              <span className="mr-1">Don't see what you need? Contact us for specialized roles</span>
              <ArrowRight size={16} className="group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>

      {/* Engagement Models */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              FLEXIBILITY
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Flexible Engagement Models
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              We offer various engagement models to suit your specific business needs.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8 transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
              <div className="h-12 w-12 bg-purple-100 text-purple-600 rounded-lg flex items-center justify-center mb-6">
                <Users size={24} />
              </div>
              <h3 className="text-xl font-bold mb-3 text-gray-900">Dedicated Team</h3>
              <p className="text-gray-600 mb-4">
                Full-time professionals who work exclusively on your projects as an extension of your team.
              </p>
              <ul className="space-y-2 mb-6">
                <li className="flex items-center text-gray-700">
                  <CheckCircle size={16} className="text-purple-600 mr-2" />
                  <span>Long-term projects</span>
                </li>
                <li className="flex items-center text-gray-700">
                  <CheckCircle size={16} className="text-purple-600 mr-2" />
                  <span>Complete team integration</span>
                </li>
                <li className="flex items-center text-gray-700">
                  <CheckCircle size={16} className="text-purple-600 mr-2" />
                  <span>Predictable monthly cost</span>
                </li>
              </ul>
            </div>

            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8 transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
              <div className="h-12 w-12 bg-purple-100 text-purple-600 rounded-lg flex items-center justify-center mb-6">
                <Clock size={24} />
              </div>
              <h3 className="text-xl font-bold mb-3 text-gray-900">Part-Time Specialists</h3>
              <p className="text-gray-600 mb-4">
                Highly specialized professionals available on a part-time basis for specific project needs.
              </p>
              <ul className="space-y-2 mb-6">
                <li className="flex items-center text-gray-700">
                  <CheckCircle size={16} className="text-purple-600 mr-2" />
                  <span>Cost-effective solution</span>
                </li>
                <li className="flex items-center text-gray-700">
                  <CheckCircle size={16} className="text-purple-600 mr-2" />
                  <span>Specialized skill sets</span>
                </li>
                <li className="flex items-center text-gray-700">
                  <CheckCircle size={16} className="text-purple-600 mr-2" />
                  <span>Flexible scheduling</span>
                </li>
              </ul>
            </div>

            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8 transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
              <div className="h-12 w-12 bg-purple-100 text-purple-600 rounded-lg flex items-center justify-center mb-6">
                <Zap size={24} />
              </div>
              <h3 className="text-xl font-bold mb-3 text-gray-900">Project-Based Team</h3>
              <p className="text-gray-600 mb-4">
                A complete team assembled specifically for your project from start to finish.
              </p>
              <ul className="space-y-2 mb-6">
                <li className="flex items-center text-gray-700">
                  <CheckCircle size={16} className="text-purple-600 mr-2" />
                  <span>Defined project scope</span>
                </li>
                <li className="flex items-center text-gray-700">
                  <CheckCircle size={16} className="text-purple-600 mr-2" />
                  <span>End-to-end delivery</span>
                </li>
                <li className="flex items-center text-gray-700">
                  <CheckCircle size={16} className="text-purple-600 mr-2" />
                  <span>Fixed or time & materials</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="py-20 bg-purple-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              CLIENT STORIES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              What Our Clients Say
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Hear from businesses that have successfully augmented their teams with our talent.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-8 transition-all duration-300 hover:shadow-xl hover:-translate-y-1"
              >
                {/* <div className="mb-6 text-purple-600">
                  <svg width="45" height="36" className="fill-current">
                    <path d="M13.415.43c-2.523 0-4.75 1.173-6.682 3.52C4.8 6.298 3.756 9.38 3.756 12.89c0 6.438 2.08 11.937 6.242 16.504C13.585 33.425 18.15 35.6 23.656 36v-6.387c-2.82-.616-5.11-1.952-6.852-4.015-1.74-2.064-2.611-4.454-2.611-7.176 0-1.477.338-2.86 1.015-4.145.677-1.284 1.625-2.34 2.85-3.164-1.931 0-3.5-.584-4.707-1.753C12.142 8.19 11.538 6.6 11.538 4.57c0-1.263.41-2.414 1.228-3.453C13.585.08 14.453 0 15.372 0h10.283v36h7.089V.43H13.415z"/>
                  </svg>
                </div> */}
                <p className="text-gray-700 mb-6 italic">{testimonial.quote}</p>
                <div className="flex items-center">
                  <div className="w-12 h-12 rounded-full overflow-hidden mr-4">
                    <img 
                      src={testimonial.image} 
                      alt={testimonial.author}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="font-bold text-gray-900">{testimonial.author}</div>
                    <div className="text-gray-600">{testimonial.position}</div>
                    <div className="text-sm text-purple-600">{testimonial.company}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-white">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              FAQ
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Frequently Asked Questions
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Find answers to common questions about our IT staff augmentation services.
            </p>
          </div>

          <div className="space-y-4">
            {faqItems.map((item, index) => (
              <div 
                key={index} 
                className="border border-gray-200 rounded-xl overflow-hidden transition-all duration-300"
              >
                <button 
                  className="flex justify-between items-center w-full p-6 text-left bg-white hover:bg-gray-50 transition-colors duration-300"
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="font-bold text-lg text-gray-900">{item.question}</span>
                  <ChevronDown 
                    className={`transform transition-transform duration-300 text-purple-600 ${
                      activeAccordion === index ? 'rotate-180' : ''
                    }`} 
                    size={20} 
                  />
                </button>
                <div 
                  className={`overflow-hidden transition-all duration-300 ${
                    activeAccordion === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                  }`}
                >
                  <div className="p-6 pt-0 text-gray-600 bg-white">
                    {item.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Augment Your Team?</h2>
          <p className="text-xl mb-8 text-purple-200 max-w-3xl mx-auto">
            Scale your development capabilities with our top-tier tech professionals. Get matched with the perfect talent today.
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <a 
              href="/get-matched" 
              className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Get Matched
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            <a 
              href="/contact" 
              className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Contact Us
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ITStaffAugmentationPage;