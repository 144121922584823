import React from 'react';
import { ChevronRight, CheckCircle, Server, Database, Cloud, ShieldCheck, BarChart, Settings, Globe, Zap } from 'lucide-react';

const CloudServicesPage = () => {
  const services = [
    {
      title: "Cloud Migration & Deployment",
      description: "Strategic planning and execution of migrations from on-premises infrastructure to cloud platforms with minimal disruption."
    },
    {
      title: "Cloud Infrastructure Design",
      description: "Custom cloud architecture design optimized for performance, security, scalability, and cost-efficiency."
    },
    {
      title: "Multi-Cloud Management",
      description: "Unified management solutions for hybrid and multi-cloud environments, ensuring consistent operations across platforms."
    },
    {
      title: "Cloud Security & Compliance",
      description: "Comprehensive security implementations and compliance solutions for regulated industries in cloud environments."
    },
    {
      title: "Serverless Application Development",
      description: "Development of scalable, cost-effective applications using serverless architectures and microservices."
    },
    {
      title: "Cloud Cost Optimization",
      description: "Strategic analysis and implementation of cost-saving measures without compromising performance or security."
    }
  ];

  const caseStudies = [
    {
      title: "Enterprise Cloud Migration",
      description: "Migrated a large enterprise's legacy infrastructure to a multi-cloud solution, modernizing applications and implementing CI/CD pipelines for faster deployments.",
      results: ["60% reduction in infrastructure costs", "99.99% uptime with improved disaster recovery", "Deployment frequency increased from monthly to daily"]
    },
    {
      title: "Healthcare Data Platform",
      description: "Designed and implemented a HIPAA-compliant cloud data platform for a healthcare provider, enabling secure data storage, analysis, and sharing while maintaining strict compliance.",
      results: ["Achieved full HIPAA compliance certification", "Reduced data processing time by 85%", "Enabled real-time analytics for 50M+ patient records"]
    },
    {
      title: "Retail Multi-Cloud Strategy",
      description: "Developed a multi-cloud strategy for a retail chain, distributing workloads across providers for optimal performance and cost, with unified management and monitoring.",
      results: ["42% overall cost reduction", "Improved application performance by 70%", "Seamless scaling during peak shopping periods with zero downtime"]
    }
  ];

  const technologies = [
    "AWS", "Azure", "Google Cloud Platform", "Kubernetes", 
    "Docker", "Terraform", "Ansible", "CloudFormation",
    "Lambda", "Azure Functions", "Cloud Run",
    "S3/Blob Storage", "DynamoDB", "Cosmos DB",
    "CI/CD Pipelines", "Monitoring & Observability",
    "IAM", "Zero Trust Security"
  ];

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
                CLOUD SERVICES
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Powerful Cloud Solutions for Modern Businesses
              </h1>
              <p className="text-xl mb-8 text-purple-100">
                Transform your infrastructure with scalable, secure, and cost-effective cloud services designed for your unique business needs.
              </p>
              <div className="flex flex-wrap gap-4">
                <a 
                  href="/get-matched" 
                  className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Get Cloud Experts
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
                <a 
                  href="/contact" 
                  className="group bg-purple-800/50 backdrop-blur-sm hover:bg-purple-800/70 border border-purple-500/30 text-white px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Contact Us
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </div>
            </div>
            <div className="hidden md:block">
              <div className="relative">
                <div className="absolute -left-16 -top-16 w-64 h-64 bg-purple-500 opacity-20 rounded-full animate-pulse"></div>
                <div className="absolute -right-20 -bottom-20 w-80 h-80 bg-indigo-500 opacity-20 rounded-full animate-pulse" style={{ animationDelay: '1s' }}></div>
                
                <div className="relative bg-white dark:bg-gray-900 p-8 rounded-xl shadow-2xl transform hover:scale-105 transition-all duration-500">
                  <div className="flex gap-4 mb-6">
                    <div className="w-3 h-3 rounded-full bg-red-400"></div>
                    <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                    <div className="w-3 h-3 rounded-full bg-green-400"></div>
                  </div>
                  
                  <div className="space-y-6">
                    <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700 pb-4">
                      <div className="flex items-center">
                        <div className="w-10 h-10 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center font-bold mr-3">
                          <Cloud size={20} />
                        </div>
                        <div>
                          <div className="font-bold text-gray-900 dark:text-white">Cloud Dashboard</div>
                          <div className="text-xs text-gray-500 dark:text-gray-400">Infrastructure Monitor</div>
                        </div>
                      </div>
                      <div className="text-xs text-green-600 font-semibold bg-green-100 px-2 py-1 rounded-full">
                        HEALTHY
                      </div>
                    </div>
                    
                    <div className="space-y-3">
                      <div className="flex justify-between items-center">
                        <div className="text-sm font-medium text-gray-700 dark:text-gray-300">System Resources</div>
                        <div className="text-sm font-bold text-gray-900 dark:text-white">68%</div>
                      </div>
                      <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                        <div className="h-full bg-gradient-to-r from-green-500 to-green-600 rounded-full w-[68%]"></div>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Active Services</span>
                        <span className="font-medium text-gray-900 dark:text-white">24/24</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Uptime</span>
                        <span className="font-medium text-gray-900 dark:text-white">99.99%</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Cost Optimization</span>
                        <span className="font-medium text-green-600">-32% MTD</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Security Status</span>
                        <span className="font-medium text-gray-900 dark:text-white flex items-center">
                          <CheckCircle size={14} className="text-green-500 mr-1" />
                          Secure
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR SERVICES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Comprehensive Cloud Solutions
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              From migration planning to ongoing optimization, we provide end-to-end cloud services to transform your infrastructure.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
              >
                <div className="p-4 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  {[<Cloud />, <Server />, <Globe />, <ShieldCheck />, <Zap />, <BarChart />][index % 6]}
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Case Studies */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              SUCCESS STORIES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Cloud Services Case Studies
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Discover how we've helped organizations transform their infrastructure with powerful cloud solutions.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {caseStudies.map((study, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
              >
                <div className="h-12 bg-gradient-to-r from-purple-600 to-indigo-600"></div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-3 text-gray-900">{study.title}</h3>
                  <p className="text-gray-600 mb-4">{study.description}</p>
                  <div className="border-t border-gray-100 pt-4">
                    <h4 className="font-bold text-gray-900 mb-2">Results:</h4>
                    <ul className="space-y-1">
                      {study.results.map((result, i) => (
                        <li key={i} className="flex items-start">
                          <CheckCircle size={16} className="text-green-500 mt-1 mr-2 flex-shrink-0" />
                          <span className="text-gray-600">{result}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Technologies */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              TECH STACK
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Cloud Technologies We Use
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our cloud solutions leverage the most reliable and innovative cloud technologies for optimal performance.
            </p>
          </div>

          <div className="flex flex-wrap justify-center gap-4">
            {technologies.map((tech, index) => (
              <div 
                key={index} 
                className="bg-gray-100 text-gray-800 px-4 py-2 rounded-full font-medium hover:bg-purple-100 hover:text-purple-700 transition-colors duration-300"
              >
                {tech}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Transform Your Cloud Infrastructure?</h2>
          <p className="text-xl mb-8 text-purple-200 max-w-3xl mx-auto">
            Connect with our cloud experts to discuss your infrastructure needs and discover how we can help you optimize your cloud strategy.
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <a 
              href="/get-matched" 
              className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Get Cloud Experts
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            <a 
              href="/contact" 
              className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Contact Us
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CloudServicesPage;