import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X, ChevronDown, ChevronRight } from 'lucide-react';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [mobileServicesDropdownOpen, setMobileServicesDropdownOpen] = useState(false);
  const servicesRef = useRef(null);
  const location = useLocation();
  
  // Handle scroll position for navbar styles
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  // Handle route changes and check for hash navigation
  useEffect(() => {
    setIsMenuOpen(false);
    setMobileServicesDropdownOpen(false);
    
    // Only close dropdown on major route changes, not just hash changes
    if (location.pathname !== '/') {
      setServicesDropdownOpen(false);
    }
    
    // Handle hash navigation when landing on homepage
    if (location.pathname === '/' && location.hash) {
      const sectionId = location.hash.slice(1); // Remove the # character
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 300); // Allow time for page to render before scrolling
    }
  }, [location]);

  // Handle click outside of services dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (servicesRef.current && !servicesRef.current.contains(event.target)) {
        setServicesDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Handle scroll to section for hash links
  const scrollToSection = (sectionId) => {
    // Try multiple times to find the element, as it might not be immediately available
    let attempts = 0;
    const maxAttempts = 5;
    
    const tryScrolling = () => {
      const element = document.getElementById(sectionId);
      if (element) {
        const navbarHeight = 80; // Approximate navbar height
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      } else if (attempts < maxAttempts) {
        // Element not found yet, try again after a short delay
        attempts++;
        setTimeout(tryScrolling, 200);
      } else {
        console.log(`Could not find element with id: ${sectionId}`);
      }
    };
    
    // Start the first attempt after a short delay to ensure page is loaded
    setTimeout(tryScrolling, 100);
  };

  // Services submenu items from footer
  const serviceItems = [
    { name: 'Software Development', path: '/software-development' },
    { name: 'IT Staff Augmentation', path: '/it-staff-augmentation' },
    { name: 'Custom Projects', path: '/custom-project-development' },
    { name: 'Consulting', path: '/consulting' },
    { name: 'DevOps Services', path: '/devops-services' },
  ];

  const navLinks = [
    { name: 'Home', path: '/' },
    { 
      name: 'Services', 
      path: '/#services',
      hasDropdown: true,
      dropdownItems: serviceItems
    },
    { name: 'How It Works', path: '/#how-it-works' },
    { name: 'About Us', path: '/about-us' },
    { name: 'Blog', path: '/blog', external: true },
    { name: 'Careers', path: '/careers', external: true },
  ];

  const isOnJobListingsPage = location.pathname === '/careers/positions';
  const isOnJobViewPage = /^\/careers\/positions\/[^/]+$/.test(location.pathname);

  const shouldNavbarBeSolid = isOnJobListingsPage || isOnJobViewPage;

  let navbarClasses = `fixed w-full z-50 transition-all duration-300 ${
    shouldNavbarBeSolid
      ? 'bg-white/95 backdrop-blur-sm shadow-md py-3'
      : scrollPosition > 50
      ? 'bg-white/95 backdrop-blur-sm shadow-md py-3'
      : 'bg-transparent py-5'
  }`;

  navbarClasses = isMenuOpen ? 'fixed top-0 left-0 right-0 z-50 bg-white backdrop-blur-sm shadow-md py-3' : navbarClasses;

  const renderDesktopNavLink = (link) => {
    const linkClasses = `${
      shouldNavbarBeSolid || scrollPosition > 50 
        ? 'text-gray-700 hover:text-purple-600' 
        : 'text-white/90 hover:text-white'
    } font-medium transition duration-300 flex items-center`;

    if (link.external) {
      return (
        <a 
          key={link.name}
          href={link.path} 
          className={linkClasses}
          target="_blank"
          rel="noopener noreferrer"
        >
          {link.name}
        </a>
      );
    }

    if (link.hasDropdown) {
      // Dropdown hover timeout management
      const handleMouseEnter = () => {
        setServicesDropdownOpen(true);
        // Clear any pending close timeout
        if (window.dropdownTimeout) {
          clearTimeout(window.dropdownTimeout);
          window.dropdownTimeout = null;
        }
      };
      
      const handleMouseLeave = () => {
        // Set a timeout before closing dropdown to prevent accidental closing
        window.dropdownTimeout = setTimeout(() => {
          setServicesDropdownOpen(false);
        }, 300); // 300ms delay before closing
      };
      
      return (
        <div 
          className="relative" 
          key={link.name} 
          ref={servicesRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <button
            className={`${linkClasses} focus:outline-none`}
            onClick={() => setServicesDropdownOpen(!servicesDropdownOpen)}
            aria-expanded={servicesDropdownOpen}
          >
            {link.name} <ChevronDown size={16} className={`ml-1 transition-transform duration-200 ${servicesDropdownOpen ? 'rotate-180' : ''}`} />
          </button>
          
          {servicesDropdownOpen && (
            <div 
              className="absolute left-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden z-50"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="py-1">
                {link.dropdownItems.map((item) => (
                  <Link
                    key={item.name}
                    to={item.path}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-purple-50 hover:text-purple-700 flex items-center"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    }

    // Handle hash links
    if (link.path.includes('#') && link.path.startsWith('/')) {
      const isHomePage = location.pathname === '/';
      const sectionId = link.path.split('#')[1];
      
      return (
        <Link 
          key={link.name}
          to={link.path}
          className={linkClasses}
          onClick={(e) => {
            e.preventDefault();
            if (!isHomePage) {
              // Navigate to homepage first, then scroll
              // Using history.pushState to ensure React Router handles the navigation
              window.history.pushState({}, '', link.path);
              // Force a reload to ensure proper handling of the hash
              window.location.reload();
            } else {
              // Already on homepage, just scroll
              scrollToSection(sectionId);
            }
          }}
        >
          {link.name}
        </Link>
      );
    }

    return (
      <Link 
        key={link.name}
        to={link.path} 
        className={linkClasses}
      >
        {link.name}
      </Link>
    );
  };

  const renderMobileNavLink = (link) => {
    const linkClasses = "text-gray-700 hover:text-purple-600 font-medium py-2 transition duration-300";

    if (link.external) {
      return (
        <a 
          key={link.name}
          href={link.path} 
          className={linkClasses}
          target="_blank"
          rel="noopener noreferrer"
        >
          {link.name}
        </a>
      );
    }

    if (link.hasDropdown) {
      return (
        <div key={link.name}>
          <button
            className={`${linkClasses} flex items-center justify-between w-full focus:outline-none`}
            onClick={() => setMobileServicesDropdownOpen(!mobileServicesDropdownOpen)}
            aria-expanded={mobileServicesDropdownOpen}
          >
            {link.name}
            <ChevronDown size={16} className={`transition-transform duration-200 ${mobileServicesDropdownOpen ? 'rotate-180' : ''}`} />
          </button>
          
          {mobileServicesDropdownOpen && (
            <div className="ml-4 mt-2 space-y-2">
              {link.dropdownItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className="block py-2 text-gray-600 hover:text-purple-600 flex items-center"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <ChevronRight size={14} className="mr-2" />
                  {item.name}
                </Link>
              ))}
            </div>
          )}
        </div>
      );
    }

    // Handle hash links
    if (link.path.includes('#') && link.path.startsWith('/')) {
      const isHomePage = location.pathname === '/';
      const sectionId = link.path.split('#')[1];
      
      return (
        <Link 
          key={link.name}
          to={link.path}
          className={linkClasses}
          onClick={(e) => {
            e.preventDefault();
            setIsMenuOpen(false);
            if (!isHomePage) {
              // Navigate to homepage first, then scroll
              window.location.href = link.path;
            } else {
              // Already on homepage, just scroll
              scrollToSection(sectionId);
            }
          }}
        >
          {link.name}
        </Link>
      );
    }

    return (
      <Link 
        key={link.name}
        to={link.path} 
        className={linkClasses}
        onClick={() => setIsMenuOpen(false)}
      >
        {link.name}
      </Link>
    );
  };

  return (
    <nav className={navbarClasses}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Link 
              to="/" 
              className={`text-2xl font-bold ${
                isMenuOpen || shouldNavbarBeSolid || scrollPosition > 50 
                  ? 'text-purple-600' 
                  : 'text-white'
              } transition-colors duration-300`}
            >
              <div className="flex items-center space-x-2">
                <div className="w-8 h-8 bg-purple-600 rounded-md flex items-center justify-center text-white font-bold text-xl shadow-lg">S</div>
                <span>SOFT HOUSE</span>
              </div>
            </Link>
          </div>
          <div className="hidden md:flex items-center space-x-8">
            {navLinks.map(link => renderDesktopNavLink(link))}
            <a 
              href="/get-matched" 
              className={`${
                shouldNavbarBeSolid || scrollPosition > 50 
                  ? 'bg-purple-600 hover:bg-purple-700 text-white' 
                  : 'bg-white hover:bg-white/90 text-purple-700'
              } px-4 py-2 rounded-md font-medium transition duration-300 shadow-md hover:shadow-lg`}
            >
              Get Matched
            </a>
          </div>
          <div className="flex items-center md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={`${
                isMenuOpen || shouldNavbarBeSolid || scrollPosition > 50 
                  ? 'text-gray-700 hover:text-purple-600' 
                  : 'text-white hover:text-white/80'
              } focus:outline-none transition-colors duration-300`}
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation menu"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>
      
      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-white p-6 shadow-lg rounded-b-xl">
          <div className="flex flex-col space-y-2">
            {navLinks.map(link => renderMobileNavLink(link))}
            <div className="pt-2">
              <a 
                href="/get-matched" 
                className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-3 rounded-md font-medium text-center transition duration-300 shadow-md block"
              >
                Get Matched
              </a>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;