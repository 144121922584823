import React, { useState } from 'react';
import { X, CheckCircle, ChevronRight, Lock } from 'lucide-react';
import { Link } from 'react-router-dom';
import axios from 'axios';

// Form ID from the WordPress plugin admin page
const DEVELOPER_MATCHES_FORM_ID = 13; // Replace with your actual form ID

const ViewMatchesModal = ({ isOpen, onClose }) => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  
  const matches = [
    {
      id: 1,
      name: "Jake M.",
      role: "Senior React Developer",
      matchPercentage: 96,
      skills: ["React", "Next.js", "TypeScript", "Node.js"],
      experience: "8+ years",
      availability: "Immediate",
      image: "/images/profiles/m_4.jpg"
    },
    {
      id: 2,
      name: "Sarah K.",
      role: "Full Stack Developer",
      matchPercentage: 92,
      skills: ["React", "Python", "Django", "PostgreSQL"],
      experience: "6+ years",
      availability: "2 weeks",
      image: "/images/profiles/w_4.jpg"
    },
    {
      id: 3,
      name: "Michael R.",
      role: "Frontend Specialist",
      matchPercentage: 88,
      skills: ["React", "Vue.js", "CSS/SCSS", "UI/UX"],
      experience: "5+ years",
      availability: "Immediate",
      image: "/images/profiles/m_5.jpg"
    }
  ];

  if (!isOpen) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // In development, you can use this mock instead of the actual API call
     // const isDevelopment = process.env.NODE_ENV === 'development';
      const isDevelopment = false;
      const useMockResponse = isDevelopment && false; // Set to true to use mock

      if (useMockResponse) {
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 1500));
        setSubmitSuccess(true);
      } else {
        // Prepare form data for Contact Form 7
        const form = new FormData();
        
        // Add CF7 required fields
        form.append('_wpcf7', DEVELOPER_MATCHES_FORM_ID);
        form.append('_wpcf7_version', '5.7.7'); // Update to match your CF7 version
        form.append('_wpcf7_locale', 'en_US');
        form.append('_wpcf7_unit_tag', `wpcf7-f${DEVELOPER_MATCHES_FORM_ID}-p1-o1`);
        form.append('_wpcf7_container_post', '0');
        
        // Add user data
        form.append('your-name', formData.name);
        form.append('your-email', formData.email);
        form.append('your-company', formData.company || '');
        
        // For silent consent (user submits without explicit checkbox)
        form.append('accept-privacy', '1');
        
        // CF7 endpoint
        const endpoint = `https://cms.soft-house.eu/wp-json/contact-form-7/v1/contact-forms/${DEVELOPER_MATCHES_FORM_ID}/feedback`;
        
        // Submit the form
        const response = await axios.post(endpoint, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        
        // Check if submission was successful
        if (response.data.status === 'mail_sent') {
          setSubmitSuccess(true);
        } else {
          console.error('Form submission failed:', response.data.message);
          alert(response.data.message || 'There was an error submitting the form. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting the form. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose}></div>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl w-full">
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              className="bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={onClose}
            >
              <span className="sr-only">Close</span>
              <X size={20} />
            </button>
          </div>
          
          <div className="bg-gradient-to-r from-purple-600 to-indigo-700 px-6 py-4">
            <h3 className="text-xl font-bold text-white">Your Top Matches</h3>
            <p className="text-purple-100">These developers match your project requirements</p>
          </div>

          {!submitSuccess ? (
            <div className="p-6">
              {!showForm ? (
                <div>
                  <div className="grid md:grid-cols-3 gap-4 mb-6">
                    {matches.map((match) => (
                      <div key={match.id} className="border border-gray-200 rounded-lg overflow-hidden relative">
                        <div className="absolute inset-0 bg-white/60 backdrop-blur-md flex flex-col items-center justify-center z-10">
                          <Lock size={32} className="text-purple-600 mb-2" />
                          <p className="text-center text-gray-800 font-medium px-4">
                            Complete the form to unlock developer profiles
                          </p>
                        </div>
                        
                        <div className="p-4">
                          <div className="flex items-center mb-4">
                            <div className="w-16 h-16 rounded-full bg-gray-200 overflow-hidden mr-4">
                              <img src={match.image} alt={match.name} className="w-full h-full object-cover" />
                            </div>
                            <div>
                              <h4 className="font-bold text-gray-900">{match.name}</h4>
                              <p className="text-gray-600">{match.role}</p>
                              <div className="mt-1 inline-flex items-center bg-green-100 text-green-800 text-xs px-2 py-0.5 rounded-full">
                                <CheckCircle size={12} className="mr-1" />
                                {match.matchPercentage}% Match
                              </div>
                            </div>
                          </div>
                          
                          <div className="space-y-3">
                            <div>
                              <p className="text-xs text-gray-500">Key Skills</p>
                              <div className="flex flex-wrap gap-1 mt-1">
                                {match.skills.map((skill, index) => (
                                  <span 
                                    key={index} 
                                    className="text-xs bg-gray-100 text-gray-700 px-2 py-0.5 rounded"
                                  >
                                    {skill}
                                  </span>
                                ))}
                              </div>
                            </div>
                            
                            <div className="flex justify-between text-sm">
                              <div>
                                <p className="text-xs text-gray-500">Experience</p>
                                <p className="font-medium">{match.experience}</p>
                              </div>
                              <div>
                                <p className="text-xs text-gray-500">Availability</p>
                                <p className="font-medium">{match.availability}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  
                  <div className="text-center">
                    <button
                      onClick={() => setShowForm(true)}
                      className="bg-purple-600 text-white px-6 py-3 rounded-lg font-medium inline-flex items-center shadow-md hover:bg-purple-700 transition-colors duration-300"
                    >
                      See Full Developer Profiles
                      <ChevronRight size={20} className="ml-2" />
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="text-lg mb-6">
                    To view complete developer profiles and contact information, please provide your details:
                  </p>
                  
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="grid md:grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">Full Name *</label>
                        <input 
                          type="text"
                          name="name"
                          required
                          value={formData.name}
                          onChange={handleInputChange}
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                        />
                      </div>
                      
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">Email Address *</label>
                        <input 
                          type="email"
                          name="email"
                          required
                          value={formData.email}
                          onChange={handleInputChange}
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                        />
                      </div>
                      
                      <div className="space-y-2 md:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Company</label>
                        <input 
                          type="text"
                          name="company"
                          value={formData.company}
                          onChange={handleInputChange}
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                        />
                      </div>
                    </div>
                    
                    <div className="mt-4 p-4 bg-purple-50 rounded-lg border border-purple-100">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          <CheckCircle className="h-5 w-5 text-purple-600" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-gray-700">
                            By submitting this form, you agree to our 
                            <a href="/privacy-policy" className="font-medium text-purple-600 hover:text-purple-500"> Privacy Policy </a>
                            and consent to SOFT HOUSE contacting you about these developers.
                          </p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="bg-purple-600 text-white px-6 py-3 rounded-lg font-medium inline-flex items-center shadow-md hover:bg-purple-700 transition-colors duration-300 disabled:opacity-70"
                      >
                        {isSubmitting ? 'Processing...' : 'Unlock Developer Profiles'}
                        {!isSubmitting && <ChevronRight size={20} className="ml-2" />}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          ) : (
            <div className="p-8 text-center">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-green-100 mb-4">
                <CheckCircle size={32} className="text-green-600" />
              </div>
              <h3 className="text-2xl font-bold text-gray-900 mb-2">Success!</h3>
              <p className="text-lg text-gray-600 mb-6">
                Developer profiles have been sent to your email: <span className="font-semibold">{formData.email}</span>
              </p>
              <p className="text-gray-600 mb-8">
                Our team will reach out shortly to discuss these matches and how they can help with your project.
              </p>
              <div className="space-x-4">
                <button
                  onClick={onClose}
                  className="bg-gray-200 text-gray-800 px-6 py-3 rounded-lg font-medium inline-flex items-center hover:bg-gray-300 transition-colors duration-300"
                >
                  Close
                </button>
                <Link
                  to="/get-matched"
                  className="bg-purple-600 text-white px-6 py-3 rounded-lg font-medium inline-flex items-center shadow-md hover:bg-purple-700 transition-colors duration-300"
                >
                  Complete Full Matching
                  <ChevronRight size={20} className="ml-2" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewMatchesModal;