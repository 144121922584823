import React, { useState } from 'react';
import { ChevronRight, Zap, CheckCircle, ArrowRight, ChevronDown, Lightbulb, Code, Settings, Layout, BarChart } from 'lucide-react';

const CustomProjectDevelopmentPage = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [activeTab, setActiveTab] = useState('process');

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const services = [
    {
      icon: <Lightbulb className="w-6 h-6" />,
      title: "Discovery & Strategy",
      description: "We analyze your business needs, define project objectives, and create a comprehensive roadmap for success."
    },
    {
      icon: <Layout className="w-6 h-6" />,
      title: "UX/UI Design",
      description: "Our designers create intuitive, engaging user experiences that align with your brand and business goals."
    },
    {
      icon: <Code className="w-6 h-6" />,
      title: "Custom Development",
      description: "We build tailored solutions using the most suitable technologies for your specific project requirements."
    },
    {
      icon: <Settings className="w-6 h-6" />,
      title: "Quality Assurance",
      description: "Rigorous testing ensures your product meets the highest quality standards before deployment."
    },
    {
      icon: <Zap className="w-6 h-6" />,
      title: "Deployment & Launch",
      description: "We handle the technical aspects of launching your product and ensure a smooth transition to production."
    },
    {
      icon: <BarChart className="w-6 h-6" />,
      title: "Maintenance & Support",
      description: "Ongoing technical support, performance monitoring, and regular updates keep your solution running optimally."
    }
  ];

  const processSteps = [
    {
      title: "Discovery",
      description: "We start by understanding your business goals, target audience, and project requirements through in-depth consultations.",
      tasks: [
        "Stakeholder interviews",
        "Requirement analysis",
        "Market research",
        "Competitive analysis",
        "Technical feasibility assessment"
      ]
    },
    {
      title: "Planning",
      description: "Our team creates a detailed project roadmap with clear milestones, deliverables, and timelines.",
      tasks: [
        "Project scope definition",
        "Technical architecture planning",
        "Resource allocation",
        "Risk assessment",
        "Project timeline creation"
      ]
    },
    {
      title: "Design",
      description: "We design intuitive user experiences and engaging interfaces that align with your brand and meet user needs.",
      tasks: [
        "Wireframing",
        "UI/UX design",
        "Prototype development",
        "User testing",
        "Design iteration"
      ]
    },
    {
      title: "Development",
      description: "Our developers build your solution using agile methodologies, ensuring quality code and regular progress updates.",
      tasks: [
        "Frontend development",
        "Backend development",
        "API integration",
        "Database architecture",
        "Regular code reviews"
      ]
    },
    {
      title: "Testing",
      description: "Comprehensive testing ensures your product is stable, secure, and performs optimally across all scenarios.",
      tasks: [
        "Functional testing",
        "Performance testing",
        "Security testing",
        "Compatibility testing",
        "User acceptance testing"
      ]
    },
    {
      title: "Deployment",
      description: "We handle the technical aspects of launching your product and ensure a smooth transition to production.",
      tasks: [
        "Environment setup",
        "Deployment automation",
        "Data migration",
        "Performance monitoring",
        "Launch support"
      ]
    },
    {
      title: "Support",
      description: "Post-launch, we provide ongoing maintenance, support, and enhancements to keep your product running optimally.",
      tasks: [
        "Technical support",
        "Bug fixes",
        "Performance optimization",
        "Feature enhancements",
        "Security updates"
      ]
    }
  ];

  const projectTypes = [
    {
      title: "Web Applications",
      description: "Custom web applications designed to solve specific business challenges and streamline operations.",
      examples: [
        "Customer portals",
        "Business management systems",
        "E-commerce platforms",
        "Booking systems",
        "CRM systems"
      ]
    },
    {
      title: "Mobile Applications",
      description: "Native and cross-platform mobile applications that deliver exceptional user experiences on any device.",
      examples: [
        "iOS applications",
        "Android applications",
        "Cross-platform solutions",
        "Progressive web apps",
        "Mobile commerce apps"
      ]
    },
    {
      title: "Enterprise Solutions",
      description: "Scalable enterprise software that integrates with your existing systems and optimizes business processes.",
      examples: [
        "ERP systems",
        "Supply chain management",
        "Business intelligence tools",
        "Workflow automation",
        "Data management platforms"
      ]
    },
    {
      title: "Digital Transformation",
      description: "End-to-end digital transformation initiatives that modernize legacy systems and embrace digital innovation.",
      examples: [
        "Legacy system modernization",
        "Cloud migration",
        "Process digitization",
        "API development",
        "Microservices architecture"
      ]
    }
  ];

  const caseStudies = [
    {
      title: "Social Media Platform",
      client: "Digital Media Startup",
      industry: "Social Networking",
      challenge: "Building a scalable social media platform with real-time features and content moderation capabilities.",
      solution: "Developed a microservices-based architecture using Node.js, React, and MongoDB, with real-time functionality powered by WebSockets.",
      results: [
        "100,000+ active users within 3 months of launch",
        "99.9% uptime even during peak usage",
        "50% lower infrastructure costs than projected"
      ],
      image: "#"
    },
    {
      title: "Healthcare Management System",
      client: "Regional Healthcare Provider",
      industry: "Healthcare",
      challenge: "Creating a comprehensive patient management system that ensures HIPAA compliance and integrates with existing tools.",
      solution: "Built a secure web application using Java Spring Boot, React, and PostgreSQL with dedicated security layers and API integrations.",
      results: [
        "40% reduction in administrative workload",
        "Zero security incidents since implementation",
        "95% positive feedback from medical staff"
      ],
      image: "#"
    },
    {
      title: "E-commerce Marketplace",
      client: "Retail Conglomerate",
      industry: "E-commerce",
      challenge: "Developing a multi-vendor marketplace with complex inventory management and payment processing systems.",
      solution: "Created a scalable platform using Python, Django, React, and microservices architecture with third-party API integrations.",
      results: [
        "230% increase in online sales within 6 months",
        "Successful integration of 50+ vendors",
        "Cart abandonment rate reduced by 35%"
      ],
      image: "#"
    }
  ];

  const faqItems = [
    {
      question: "How long does a typical custom project take to complete?",
      answer: "Project timelines vary significantly based on complexity, scope, and requirements. Simple projects might take 2-3 months, while complex enterprise solutions can take 6-12 months or more. During our initial discovery phase, we'll provide you with a detailed timeline specific to your project needs."
    },
    {
      question: "What is your development methodology?",
      answer: "We follow an Agile development methodology with iterative development cycles (sprints) typically lasting 2 weeks. This approach allows for regular progress reviews, flexibility to adapt to changing requirements, and continuous delivery of working software. We maintain transparency through regular meetings and project management tools that give you visibility into progress."
    },
    {
      question: "How do you handle project changes and new requirements?",
      answer: "We understand that requirements can evolve during project development. Our Agile approach is designed to accommodate changes. When new requirements arise, we assess their impact on the project timeline, resources, and budget. We then provide you with options and recommendations before implementing any changes, ensuring complete transparency throughout the process."
    },
    {
      question: "What happens after my project is launched?",
      answer: "After launch, we provide a warranty period during which we address any issues at no additional cost. Beyond this, we offer flexible support and maintenance packages to ensure your solution continues to run optimally. These can include technical support, bug fixes, security updates, performance optimization, and feature enhancements based on your ongoing needs."
    },
    {
      question: "How do you ensure the quality of your deliverables?",
      answer: "Quality assurance is integrated throughout our development process, not just at the end. We implement code reviews, automated testing, continuous integration, regular QA testing cycles, security audits, and performance testing. Our comprehensive approach to quality ensures that the final product meets the highest standards in functionality, security, performance, and user experience."
    }
  ];

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
                Custom Project Development
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Turning Your Vision Into Digital Reality
              </h1>
              <p className="text-xl mb-8 text-purple-100 max-w-lg">
                From concept to launch, we build custom digital products tailored to your business needs and market requirements.
              </p>
              <div className="flex flex-wrap gap-4">
                <a 
                  href="/get-matched" 
                  className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Discuss Your Project
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
                <a 
                  href="#case-studies" 
                  className="group bg-purple-800/50 backdrop-blur-sm hover:bg-purple-800/70 border border-purple-500/30 text-white px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  View Case Studies
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </div>
            </div>
            <div>
              <div className="relative">
                <div className="absolute -left-4 -top-4 w-64 h-64 bg-purple-500/30 rounded-full animate-pulse"></div>
                <div className="relative bg-white/10 backdrop-blur-sm border border-white/20 p-8 rounded-xl shadow-2xl">
                  <div className="p-6 bg-purple-600 text-white rounded-xl inline-block mb-6 shadow-lg">
                    <Zap size={48} />
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-white">End-to-End Project Development</h3>
                  <ul className="space-y-3 mb-8">
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Ideation & Strategy</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Product Design & UX</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Full-Stack Development</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Quality Assurance</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Deployment & Maintenance</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR SERVICES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              End-to-End Development Services
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              We provide comprehensive services covering every stage of the project lifecycle.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-300 hover:shadow-xl hover:-translate-y-1"
              >
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  {service.icon}
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Process & Project Types Tabs */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              APPROACH
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Our Approach to Custom Development
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              We follow a structured methodology to ensure successful project delivery.
            </p>
          </div>

          <div className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden">
            <div className="flex flex-wrap border-b border-gray-200">
              <button
                onClick={() => setActiveTab('process')}
                className={`px-6 py-4 text-sm font-medium transition-colors duration-300 ${
                  activeTab === 'process'
                    ? 'border-b-2 border-purple-600 text-purple-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Development Process
              </button>
              <button
                onClick={() => setActiveTab('projects')}
                className={`px-6 py-4 text-sm font-medium transition-colors duration-300 ${
                  activeTab === 'projects'
                    ? 'border-b-2 border-purple-600 text-purple-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Project Types
              </button>
            </div>
            
            <div className="p-8">
              {activeTab === 'process' && (
                <div>
                  <h3 className="text-2xl font-bold mb-8 text-gray-900 text-center">Our Development Process</h3>
                  <div className="relative">
                    {/* Connection line */}
                    <div className="hidden md:block absolute top-1/3 left-0 w-full h-1 bg-purple-200 z-0"></div>
                    
                    <div className="grid md:grid-cols-4 gap-8 mb-12">
                      {processSteps.slice(0, 4).map((step, index) => (
                        <div key={index} className="relative">
                          <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 relative z-10 h-full hover:shadow-xl transition-all duration-300">
                            <div className="w-12 h-12 bg-purple-600 text-white rounded-full flex items-center justify-center font-bold text-xl mb-4 mx-auto">{index + 1}</div>
                            <h4 className="text-xl font-bold mb-3 text-center text-gray-900">{step.title}</h4>
                            <p className="text-gray-600 text-center mb-4">{step.description}</p>
                            <div className="bg-purple-50 p-3 rounded-lg">
                              <div className="text-sm font-medium text-purple-700 mb-2">Key Activities:</div>
                              <ul className="text-sm space-y-1">
                                {step.tasks.map((task, i) => (
                                  <li key={i} className="flex items-start">
                                    <div className="w-1 h-1 bg-purple-600 rounded-full mt-2 mr-2"></div>
                                    <span className="text-gray-700">{task}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    
                    {/* Connection line for second row */}
                    <div className="hidden md:block absolute top-2/3 left-0 w-full h-1 bg-purple-200 z-0 mt-12"></div>
                    
                    <div className="grid md:grid-cols-3 gap-8">
                      {processSteps.slice(4).map((step, index) => (
                        <div key={index} className="relative">
                          <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 relative z-10 h-full hover:shadow-xl transition-all duration-300">
                            <div className="w-12 h-12 bg-purple-600 text-white rounded-full flex items-center justify-center font-bold text-xl mb-4 mx-auto">{index + 5}</div>
                            <h4 className="text-xl font-bold mb-3 text-center text-gray-900">{step.title}</h4>
                            <p className="text-gray-600 text-center mb-4">{step.description}</p>
                            <div className="bg-purple-50 p-3 rounded-lg">
                              <div className="text-sm font-medium text-purple-700 mb-2">Key Activities:</div>
                              <ul className="text-sm space-y-1">
                                {step.tasks.map((task, i) => (
                                  <li key={i} className="flex items-start">
                                    <div className="w-1 h-1 bg-purple-600 rounded-full mt-2 mr-2"></div>
                                    <span className="text-gray-700">{task}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              
              {activeTab === 'projects' && (
                <div>
                  <h3 className="text-2xl font-bold mb-8 text-gray-900 text-center">Types of Projects We Develop</h3>
                  <div className="grid md:grid-cols-2 gap-8">
                    {projectTypes.map((type, index) => (
                      <div 
                        key={index} 
                        className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 h-full transition-all duration-300 hover:shadow-xl"
                      >
                        <h4 className="text-xl font-bold mb-3 text-gray-900">{type.title}</h4>
                        <p className="text-gray-600 mb-4">{type.description}</p>
                        <div className="bg-purple-50 p-4 rounded-lg">
                          <div className="text-sm font-medium text-purple-700 mb-2">Examples:</div>
                          <ul className="space-y-2">
                            {type.examples.map((example, i) => (
                              <li key={i} className="flex items-center text-gray-700">
                                <CheckCircle size={16} className="text-purple-600 mr-2 flex-shrink-0" />
                                <span>{example}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Case Studies */}
      <section id="case-studies" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              SUCCESS STORIES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Featured Case Studies
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Explore some of our successful custom development projects.
            </p>
          </div>

          <div className="space-y-16">
            {caseStudies.map((study, index) => (
              <div 
                key={index} 
                className={`bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden transition-all duration-300 hover:shadow-xl`}
              >
                <div className="grid md:grid-cols-5 gap-0">
                  {/* <div className="md:col-span-2 bg-purple-100 flex items-center justify-center p-4">
                    <img 
                      src={study.image} 
                      alt={study.title}
                      className="rounded-lg shadow-md max-h-64 object-cover"
                    />
                  </div> */}
                  <div className="md:col-span-3 p-8">
                    <div className="flex flex-wrap items-center gap-3 mb-4">
                      <span className="bg-purple-100 text-purple-700 text-xs px-3 py-1 rounded-full">
                        {study.industry}
                      </span>
                      <span className="text-gray-500">•</span>
                      <span className="text-gray-600 text-sm">{study.client}</span>
                    </div>
                    
                    <h3 className="text-2xl font-bold mb-4 text-gray-900">{study.title}</h3>
                    
                    <div className="mb-4">
                      <h4 className="font-medium text-gray-900 mb-2">Challenge:</h4>
                      <p className="text-gray-600">{study.challenge}</p>
                    </div>
                    
                    <div className="mb-4">
                      <h4 className="font-medium text-gray-900 mb-2">Solution:</h4>
                      <p className="text-gray-600">{study.solution}</p>
                    </div>
                    
                    <div>
                      <h4 className="font-medium text-gray-900 mb-2">Results:</h4>
                      <ul className="space-y-1">
                        {study.results.map((result, i) => (
                          <li key={i} className="flex items-center text-gray-600">
                            <CheckCircle size={16} className="text-green-600 mr-2 flex-shrink-0" />
                            <span>{result}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-purple-50">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              FAQ
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Frequently Asked Questions
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Find answers to common questions about our custom project development services.
            </p>
          </div>

          <div className="space-y-4">
            {faqItems.map((item, index) => (
              <div 
                key={index} 
                className="border border-gray-200 rounded-xl overflow-hidden transition-all duration-300"
              >
                <button 
                  className="flex justify-between items-center w-full p-6 text-left bg-white hover:bg-gray-50 transition-colors duration-300"
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="font-bold text-lg text-gray-900">{item.question}</span>
                  <ChevronDown 
                    className={`transform transition-transform duration-300 text-purple-600 ${
                      activeAccordion === index ? 'rotate-180' : ''
                    }`} 
                    size={20} 
                  />
                </button>
                <div 
                  className={`overflow-hidden transition-all duration-300 ${
                    activeAccordion === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                  }`}
                >
                  <div className="p-6 pt-0 text-gray-600 bg-white">
                    {item.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Start Your Custom Project?</h2>
          <p className="text-xl mb-8 text-purple-200 max-w-3xl mx-auto">
            Let's turn your vision into reality. Our team of experts is ready to help you build a custom solution tailored to your business needs.
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <a 
              href="/get-matched" 
              className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Start Your Project
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            <a 
              href="/contact" 
              className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Contact Us
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CustomProjectDevelopmentPage;