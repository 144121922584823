import React from 'react';
import { ChevronRight, CheckCircle, Users, MessageCircle, TrendingUp, Share2, Bell, BarChart, Heart, Search } from 'lucide-react';

const SocialMediaPage = () => {
  const services = [
    {
      title: "Social Platform Development",
      description: "Custom social media platforms and communities tailored to your specific audience and business objectives."
    },
    {
      title: "Real-time Messaging Systems",
      description: "Scalable messaging infrastructure for instant communication with support for text, images, videos, and more."
    },
    {
      title: "Content Delivery Networks",
      description: "High-performance CDN solutions to deliver media content quickly and reliably to users worldwide."
    },
    {
      title: "Engagement & Moderation Tools",
      description: "AI-powered tools for content moderation, user engagement analytics, and community management."
    },
    {
      title: "Social Analytics Platforms",
      description: "Comprehensive analytics solutions to track user behavior, content performance, and platform growth."
    },
    {
      title: "API Development & Integration",
      description: "Custom APIs for seamless integration with third-party services and platforms in the social ecosystem."
    }
  ];

  const caseStudies = [
    {
      title: "Industry-Specific Social Network",
      description: "Developed a niche social network for professionals in the creative industry, featuring portfolio showcases, collaboration tools, and specialized networking features.",
      results: ["250,000+ active users within first year", "Average session time of 18 minutes", "72% monthly active user retention"]
    },
    {
      title: "Video-Based Community Platform",
      description: "Built a scalable video-sharing community platform with advanced recommendation algorithms, interactive features, and monetization capabilities for content creators.",
      results: ["5M+ daily video views", "3x growth in content creator signups", "99.9% platform uptime with global CDN infrastructure"]
    },
    {
      title: "Enterprise Social Collaboration Tool",
      description: "Created an internal social collaboration tool for a Fortune 500 company, enabling knowledge sharing, project collaboration, and company-wide communication.",
      results: ["85% employee adoption rate", "40% reduction in internal email volume", "Measurable increase in cross-department collaboration"]
    }
  ];

  const technologies = [
    "React", "Node.js", "Socket.io", "WebRTC", "Redux",
    "MongoDB", "PostgreSQL", "Redis", "Neo4j",
    "AWS", "Kubernetes", "Docker", "CI/CD",
    "Elasticsearch", "TensorFlow", "Python",
    "Microservices", "GraphQL", "Kafka"
  ];

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
                SOCIAL MEDIA SOLUTIONS
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Innovative Social Media Platforms for Global Connection
              </h1>
              <p className="text-xl mb-8 text-purple-100">
                Building scalable, engaging, and feature-rich social platforms that connect communities and drive meaningful interactions.
              </p>
              <div className="flex flex-wrap gap-4">
                <a 
                  href="/get-matched" 
                  className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Get Social Media Experts
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
                <a 
                  href="/contact" 
                  className="group bg-purple-800/50 backdrop-blur-sm hover:bg-purple-800/70 border border-purple-500/30 text-white px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Contact Us
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </div>
            </div>
            <div className="hidden md:block">
              <div className="relative">
                <div className="absolute -left-16 -top-16 w-64 h-64 bg-purple-500 opacity-20 rounded-full animate-pulse"></div>
                <div className="absolute -right-20 -bottom-20 w-80 h-80 bg-indigo-500 opacity-20 rounded-full animate-pulse" style={{ animationDelay: '1s' }}></div>
                
                <div className="relative bg-white dark:bg-gray-900 p-8 rounded-xl shadow-2xl transform hover:scale-105 transition-all duration-500">
                  <div className="flex gap-4 mb-6">
                    <div className="w-3 h-3 rounded-full bg-red-400"></div>
                    <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                    <div className="w-3 h-3 rounded-full bg-green-400"></div>
                  </div>
                  
                  <div className="space-y-6">
                    <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700 pb-4">
                      <div className="flex items-center">
                        <div className="w-10 h-10 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center font-bold mr-3">
                          <MessageCircle size={20} />
                        </div>
                        <div>
                          <div className="font-bold text-gray-900 dark:text-white">Social Analytics</div>
                          <div className="text-xs text-gray-500 dark:text-gray-400">Engagement Dashboard</div>
                        </div>
                      </div>
                      <div className="text-xs text-green-600 font-semibold bg-green-100 px-2 py-1 rounded-full">
                        LIVE
                      </div>
                    </div>
                    
                    <div className="space-y-3">
                      <div className="flex justify-between items-center">
                        <div className="text-sm font-medium text-gray-700 dark:text-gray-300">User Growth</div>
                        <div className="text-sm font-bold text-gray-900 dark:text-white">
                          <span className="text-green-600 flex items-center">
                            <TrendingUp size={14} className="mr-1" />
                            24%
                          </span>
                        </div>
                      </div>
                      <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                        <div className="h-full bg-gradient-to-r from-purple-600 to-indigo-600 rounded-full w-[85%]"></div>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Daily Active Users</span>
                        <span className="font-medium text-gray-900 dark:text-white">1.2M</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Avg. Session Time</span>
                        <span className="font-medium text-gray-900 dark:text-white">16m 24s</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Posts Created</span>
                        <span className="font-medium text-gray-900 dark:text-white">458K/day</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Engagement Rate</span>
                        <span className="font-medium text-gray-900 dark:text-white">8.7%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR SERVICES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Comprehensive Social Media Solutions
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              From platform development to analytics, we provide end-to-end services to build and grow your social media ecosystem.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
              >
                <div className="p-4 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  {[<Users />, <MessageCircle />, <Share2 />, <Bell />, <BarChart />, <Search />][index % 6]}
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Case Studies */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              SUCCESS STORIES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Social Media Case Studies
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Discover how we've helped businesses and communities build engaging social platforms.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {caseStudies.map((study, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
              >
                <div className="h-12 bg-gradient-to-r from-purple-600 to-indigo-600"></div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-3 text-gray-900">{study.title}</h3>
                  <p className="text-gray-600 mb-4">{study.description}</p>
                  <div className="border-t border-gray-100 pt-4">
                    <h4 className="font-bold text-gray-900 mb-2">Results:</h4>
                    <ul className="space-y-1">
                      {study.results.map((result, i) => (
                        <li key={i} className="flex items-start">
                          <CheckCircle size={16} className="text-green-500 mt-1 mr-2 flex-shrink-0" />
                          <span className="text-gray-600">{result}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Technologies */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              TECH STACK
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Technologies We Use
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our social media platforms are built with cutting-edge technologies optimized for scalability and performance.
            </p>
          </div>

          <div className="flex flex-wrap justify-center gap-4">
            {technologies.map((tech, index) => (
              <div 
                key={index} 
                className="bg-gray-100 text-gray-800 px-4 py-2 rounded-full font-medium hover:bg-purple-100 hover:text-purple-700 transition-colors duration-300"
              >
                {tech}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Build Your Social Media Platform?</h2>
          <p className="text-xl mb-8 text-purple-200 max-w-3xl mx-auto">
            Connect with our social media experts to discuss your platform requirements and discover how we can help you build an engaging community.
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <a 
              href="/get-matched" 
              className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Get Social Media Experts
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            <a 
              href="/contact" 
              className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Contact Us
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SocialMediaPage;