import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronRight, MapPin, Briefcase, Clock, Search, Filter } from 'lucide-react';
import { fetchJobs, fetchDepartments, fetchLocations } from '../../api';

// ChevronLeft component
const ChevronLeft = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}><path d="m15 18-6-6 6-6"/></svg>
);

const JobListings = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [filters, setFilters] = useState({
    department: '',
    location: '',
    search: ''
  });
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    totalJobs: 0
  });

  // Fetch data when component mounts or filters change
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Get departments and locations (only once)
        if (departments.length === 0) {
          const departmentsData = await fetchDepartments();
          setDepartments(departmentsData);
        }
        
        if (locations.length === 0) {
          const locationsData = await fetchLocations();
          setLocations(locationsData);
        }
        
        // Fetch jobs with filters
        const jobsData = await fetchJobs({
          page: pagination.page,
          perPage: 10,
          department: filters.department,
          location: filters.location,
          search: filters.search
        });
        
        setJobs(jobsData.jobs);
        setPagination({
          page: jobsData.current_page,
          totalPages: jobsData.pages,
          totalJobs: jobsData.total
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [filters, pagination.page, departments.length, locations.length]);

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
    // Reset to page 1 when filters change
    setPagination(prev => ({ ...prev, page: 1 }));
  };

  // Handle search form submission
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // The search is already applied through the handleFilterChange function
  };

  // Change page
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= pagination.totalPages) {
      setPagination(prev => ({ ...prev, page: newPage }));
      // Scroll to top of results
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Scroll to general application form
  const scrollToGeneralApplication = () => {
    navigate('/careers');
    
    // Allow time for the landing page to load, then scroll to the general application form
    setTimeout(() => {
      const element = document.getElementById('general-application');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  };

  return (
    <div className="pt-32 pb-24 bg-gray-50 min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-10">
          <div className="flex items-center mb-6">
            <Link 
              to="/careers"
              className="inline-flex items-center text-purple-600 hover:text-purple-800 font-medium mr-4"
            >
              <ChevronLeft className="mr-1 w-5 h-5" />
              Back
            </Link>
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900">Open Positions</h1>
          </div>
          <p className="text-xl text-gray-600 max-w-3xl">
            Join our team of talented professionals and work on exciting projects with cutting-edge technologies.
          </p>
        </div>

        {/* Filters Section */}
        <div className="bg-white p-6 rounded-xl shadow-md mb-8">
          <form onSubmit={handleSearchSubmit} className="flex flex-col md:flex-row gap-4">
            <div className="relative flex-grow">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <Search className="w-5 h-5 text-gray-400" />
              </div>
              <input
                type="text"
                name="search"
                placeholder="Search positions, skills, keywords..."
                className="w-full pl-10 px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300"
                value={filters.search}
                onChange={handleFilterChange}
              />
            </div>
            
            <div className="flex flex-col md:flex-row gap-4">
              <select
                name="department"
                className="px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300"
                value={filters.department}
                onChange={handleFilterChange}
              >
                <option value="">All Departments</option>
                {departments.map(dept => (
                  <option key={dept.id} value={dept.id}>{dept.name}</option>
                ))}
              </select>
              
              <select
                name="location"
                className="px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300"
                value={filters.location}
                onChange={handleFilterChange}
              >
                <option value="">All Locations</option>
                {locations.map(loc => (
                  <option key={loc.id} value={loc.id}>{loc.name}</option>
                ))}
              </select>
              
              <button
                type="submit"
                className="px-6 py-3 bg-purple-600 hover:bg-purple-700 text-white font-medium rounded-lg shadow transition-colors duration-300"
              >
                Filter
              </button>
            </div>
          </form>
        </div>

        {/* Results Stats */}
        {!isLoading && (
          <div className="mb-6 text-gray-600">
            Showing {jobs.length} of {pagination.totalJobs} positions
          </div>
        )}

        {/* Results Section */}
        {isLoading ? (
          <div className="flex justify-center items-center py-10">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-600"></div>
          </div>
        ) : jobs.length > 0 ? (
          <>
            <div className="grid md:grid-cols-2 gap-6">
              {jobs.map(job => (
                <div 
                  key={job.id} 
                  className="bg-white rounded-xl shadow-md border border-gray-100 overflow-hidden transition-all duration-300 hover:shadow-lg hover:-translate-y-1"
                >
                  <div className="p-6">
                    <div className="flex justify-between items-start mb-4">
                      <span className="bg-purple-100 text-purple-700 px-3 py-1 rounded-full text-sm font-medium">
                        {job.department?.name || "General"}
                      </span>
                      <span className="text-gray-500 text-sm">{job.reference_code}</span>
                    </div>
                    
                    <h2 className="text-xl font-bold mb-3 text-gray-900">{job.title}</h2>
                    
                    <div className="flex flex-wrap gap-4 mb-4">
                      <div className="flex items-center text-gray-600">
                        <MapPin className="w-4 h-4 mr-1" />
                        <span>{job.location?.name || "Various Locations"}</span>
                      </div>
                      <div className="flex items-center text-gray-600">
                        <Briefcase className="w-4 h-4 mr-1" />
                        <span>{job.job_type?.name || "Full-time"}</span>
                      </div>
                      {job.experience && (
                        <div className="flex items-center text-gray-600">
                          <Clock className="w-4 h-4 mr-1" />
                          <span>{job.experience}</span>
                        </div>
                      )}
                    </div>
                    
                    <p className="text-gray-600 mb-4">{job.excerpt}</p>
                    
                    {job.required_skills && (
                      <div className="flex flex-wrap gap-2 mb-5">
                        {job.required_skills.split(',').slice(0, 3).map((skill, i) => (
                          <span key={i} className="bg-gray-100 text-gray-800 px-3 py-1 rounded-full text-sm">
                            {skill.trim()}
                          </span>
                        ))}
                        {job.required_skills.split(',').length > 3 && (
                          <span className="bg-gray-100 text-gray-800 px-3 py-1 rounded-full text-sm">
                            +{job.required_skills.split(',').length - 3} more
                          </span>
                        )}
                      </div>
                    )}
                    
                    <Link
                      to={`/careers/positions/${job.slug}`}
                      className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg inline-flex items-center font-medium transition-colors duration-300 shadow hover:shadow-md"
                    >
                      View Details & Apply
                      <ChevronRight className="ml-1 w-4 h-4" />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            
            {/* Pagination */}
            {pagination.totalPages > 1 && (
              <div className="flex justify-center mt-10">
                <nav className="flex items-center space-x-2" aria-label="Pagination">
                  <button
                    onClick={() => handlePageChange(pagination.page - 1)}
                    disabled={pagination.page === 1}
                    className={`px-3 py-2 rounded-md ${
                      pagination.page === 1
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
                    }`}
                  >
                    Previous
                  </button>
                  
                  {/* Show max 5 page numbers */}
                  {[...Array(pagination.totalPages)].map((_, i) => {
                    const pageNum = i + 1;
                    
                    // Show first, last, current and pages around current
                    if (
                      pageNum === 1 ||
                      pageNum === pagination.totalPages ||
                      (pageNum >= pagination.page - 1 && pageNum <= pagination.page + 1)
                    ) {
                      return (
                        <button
                          key={pageNum}
                          onClick={() => handlePageChange(pageNum)}
                          className={`px-4 py-2 rounded-md ${
                            pagination.page === pageNum
                              ? 'bg-purple-600 text-white'
                              : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
                          }`}
                        >
                          {pageNum}
                        </button>
                      );
                    }
                    
                    // Show ellipsis
                    if (
                      (pageNum === 2 && pagination.page > 3) ||
                      (pageNum === pagination.totalPages - 1 && pagination.page < pagination.totalPages - 2)
                    ) {
                      return <span key={pageNum} className="px-4 py-2">...</span>;
                    }
                    
                    return null;
                  })}
                  
                  <button
                    onClick={() => handlePageChange(pagination.page + 1)}
                    disabled={pagination.page === pagination.totalPages}
                    className={`px-3 py-2 rounded-md ${
                      pagination.page === pagination.totalPages
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
                    }`}
                  >
                    Next
                  </button>
                </nav>
              </div>
            )}
          </>
        ) : (
          <div className="bg-white p-10 rounded-xl shadow-md text-center">
            <div className="mb-4 text-gray-400">
              <Filter className="w-16 h-16 mx-auto" />
            </div>
            <h3 className="text-xl font-bold text-gray-900 mb-2">No positions found</h3>
            <p className="text-gray-600 mb-4">No positions match your current filters. Try adjusting your search criteria.</p>
            <button
              onClick={() => setFilters({ department: '', location: '', search: '' })}
              className="text-purple-600 hover:text-purple-800 font-medium"
            >
              Clear all filters
            </button>
          </div>
        )}

        {/* General Application CTA */}
        <div className="mt-16 bg-purple-50 rounded-xl p-10 text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-3">Don't see the right position?</h2>
          <p className="text-gray-600 mb-6 max-w-2xl mx-auto">
            We're always looking for talented individuals to join our team. Submit a general application and we'll contact you when a suitable position opens up.
          </p>
          <button
            onClick={scrollToGeneralApplication}
            className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 rounded-lg inline-flex items-center font-bold transition-colors duration-300 shadow-md hover:shadow-lg"
          >
            Submit General Application
            <ChevronRight className="ml-2 w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobListings;