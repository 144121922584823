// src/pages/BlogPostPage.js
import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { 
  Clock, 
  Calendar, 
  User, 
  Tag, 
  ChevronLeft, 
  ChevronRight, 
  Share2, 
  Linkedin, 
  Twitter,
  Facebook,
  Copy,
  ArrowLeft,
  Loader
} from 'lucide-react';

const BlogPostPage = () => {
  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  
  // WordPress API base URL
  const apiUrl = 'https://cms.soft-house.eu/wp-json/wp/v2';

  useEffect(() => {
    // If post was passed through navigation state, use it
    if (location.state && location.state.post) {
      setPost(location.state.post);
      setLoading(false);
      fetchRelatedPosts(location.state.post.categories[0]);
    } else {
      // Otherwise fetch it from the API
      fetchPost();
    }
    
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, [slug]);

  const fetchPost = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/posts?slug=${slug}&_embed`);
      if (!response.ok) throw new Error('Failed to fetch post');
      const data = await response.json();
      
      if (data.length === 0) {
        throw new Error('Post not found');
      }
      
      setPost(data[0]);
      fetchRelatedPosts(data[0].categories[0]);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching post:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchRelatedPosts = async (categoryId) => {
    if (!categoryId) return;
    
    try {
      const response = await fetch(`${apiUrl}/posts?categories=${categoryId}&per_page=3&exclude=${post ? post.id : ''}&_embed`);
      if (!response.ok) throw new Error('Failed to fetch related posts');
      const data = await response.json();
      setRelatedPosts(data);
    } catch (err) {
      console.error('Error fetching related posts:', err);
    }
  };

  // Estimate reading time based on content length
  const getReadingTime = (content) => {
    if (!content) return '3 min read';
    const text = content.replace(/<\/?[^>]+(>|$)/g, '');
    const wordCount = text.split(/\s+/).length;
    const readingTime = Math.ceil(wordCount / 200); // Assuming 200 words per minute
    return `${readingTime} min read`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getCategoryName = (categoryId) => {
    if (!post || !post._embedded || !post._embedded['wp:term']) {
      return 'Uncategorized';
    }
    
    const categories = post._embedded['wp:term'].find(terms => 
      terms.length > 0 && terms[0].taxonomy === 'category'
    );
    
    if (!categories || categories.length === 0) {
      return 'Uncategorized';
    }
    
    const category = categories.find(cat => cat.id === categoryId);
    return category ? category.name : 'Uncategorized';
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-50">
        <Loader className="w-12 h-12 text-purple-600 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-50 px-4 py-20">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">Error: {error}</h1>
        <p className="text-gray-600 mb-8 text-center">
          We couldn't find the article you're looking for. It may have been moved or deleted.
        </p>
        <Link
          to="/blog"
          className="px-6 py-3 bg-purple-600 text-white rounded-lg font-medium hover:bg-purple-700 transition-colors duration-300 inline-flex items-center"
        >
          <ArrowLeft size={16} className="mr-2" />
          Back to Blog
        </Link>
      </div>
    );
  }

  if (!post) {
    return null;
  }

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-20 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="inline-block bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-white/20">
            {post.categories && post.categories.length > 0 && (
              getCategoryName(post.categories[0])
            )}
          </div>
          
          <h1 
            className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 leading-tight"
            dangerouslySetInnerHTML={{ __html: post.title.rendered }}
          />
          
          <div className="flex flex-wrap items-center text-purple-200 gap-4 mb-8">
            <div className="flex items-center">
              <Clock size={16} className="mr-2" />
              <span>{getReadingTime(post.content.rendered)}</span>
            </div>
            <div className="flex items-center">
              <Calendar size={16} className="mr-2" />
              <span>{formatDate(post.date)}</span>
            </div>
            {post._embedded && post._embedded.author && (
              <div className="flex items-center">
                <User size={16} className="mr-2" />
                <span>{post._embedded.author[0].name}</span>
              </div>
            )}
          </div>
          
          <div className="flex space-x-4">
            <Link
              to="/blog"
              className="bg-white/10 backdrop-blur-sm border border-white/20 px-4 py-2 rounded-lg text-white hover:bg-white/20 transition-colors duration-300 inline-flex items-center"
            >
              <ArrowLeft size={16} className="mr-2" />
              Back to Blog
            </Link>
            
            <div className="relative">
              <button
                onClick={copyToClipboard}
                className="bg-white/10 backdrop-blur-sm border border-white/20 px-4 py-2 rounded-lg text-white hover:bg-white/20 transition-colors duration-300 inline-flex items-center"
              >
                <Share2 size={16} className="mr-2" />
                Share
              </button>
              {copySuccess && (
                <div className="absolute top-full left-0 mt-2 bg-gray-800 text-white text-sm py-1 px-3 rounded shadow-lg z-10">
                  Link copied!
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Featured Image */}
      {post._embedded && 
       post._embedded['wp:featuredmedia'] && 
       post._embedded['wp:featuredmedia'][0].source_url && (
        <div className="relative -mt-10 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white p-4 rounded-xl shadow-xl overflow-hidden">
            <img
              src={post._embedded['wp:featuredmedia'][0].source_url}
              alt={post.title.rendered}
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      )}

      {/* Content Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-6 md:p-10">
              {/* Post Content */}
              <article className="prose prose-lg max-w-none">
                <div 
                  className="post-content"
                  dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                />
              </article>
              
              {/* Social Share */}
              <div className="mt-12 pt-8 border-t border-gray-200">
                <h4 className="text-lg font-bold mb-4">Share this article</h4>
                <div className="flex space-x-3">
                  <button 
                    onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`)}
                    className="bg-gray-100 hover:bg-gray-200 p-3 rounded-full transition-colors duration-300"
                    aria-label="Share on LinkedIn"
                  >
                    <Linkedin size={20} className="text-gray-700" />
                  </button>
                  <button 
                    onClick={() => window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(post.title.rendered)}`)}
                    className="bg-gray-100 hover:bg-gray-200 p-3 rounded-full transition-colors duration-300"
                    aria-label="Share on Twitter"
                  >
                    <Twitter size={20} className="text-gray-700" />
                  </button>
                  <button 
                    onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`)}
                    className="bg-gray-100 hover:bg-gray-200 p-3 rounded-full transition-colors duration-300"
                    aria-label="Share on Facebook"
                  >
                    <Facebook size={20} className="text-gray-700" />
                  </button>
                  <button 
                    onClick={copyToClipboard}
                    className="bg-gray-100 hover:bg-gray-200 p-3 rounded-full transition-colors duration-300"
                    aria-label="Copy link"
                  >
                    <Copy size={20} className="text-gray-700" />
                  </button>
                </div>
              </div>
              
              {/* Author Box */}
              {post._embedded && post._embedded.author && (
                <div className="mt-12 bg-purple-50 rounded-xl p-6">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 mr-6">
                      {post._embedded.author[0].avatar_urls ? (
                        <img 
                          src={post._embedded.author[0].avatar_urls['96']} 
                          alt={post._embedded.author[0].name}
                          className="w-16 h-16 rounded-full"
                        />
                      ) : (
                        <div className="w-16 h-16 bg-purple-600 text-white rounded-full flex items-center justify-center text-xl font-bold">
                          {post._embedded.author[0].name.charAt(0)}
                        </div>
                      )}
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-gray-900 mb-2">
                        {post._embedded.author[0].name}
                      </h3>
                      {post._embedded.author[0].description && (
                        <p className="text-gray-700">
                          {post._embedded.author[0].description}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Related Posts */}
      {relatedPosts.length > 0 && (
        <section className="py-16 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold mb-12 text-center">Related Articles</h2>
            
            <div className="grid md:grid-cols-3 gap-8">
              {relatedPosts.map(relatedPost => (
                <div 
                  key={relatedPost.id} 
                  className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
                >
                  {relatedPost._embedded && relatedPost._embedded['wp:featuredmedia'] ? (
                    <div className="h-48 bg-gradient-to-br from-purple-600 to-indigo-700 overflow-hidden">
                      <img
                        src={relatedPost._embedded['wp:featuredmedia'][0].source_url}
                        alt={relatedPost.title.rendered}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ) : (
                    <div className="h-48 bg-gradient-to-br from-purple-600 to-indigo-700 flex items-center justify-center">
                      <div className="text-white text-5xl font-bold opacity-30">SOFT HOUSE</div>
                    </div>
                  )}
                  
                  <div className="p-6">
                    <div className="flex items-center text-sm text-gray-500 mb-3">
                      <Clock size={14} className="mr-1" />
                      <span>{getReadingTime(relatedPost.content.rendered)}</span>
                      <span className="mx-2">•</span>
                      <span>{formatDate(relatedPost.date)}</span>
                    </div>
                    
                    <h3 
                      className="text-xl font-bold mb-4 text-gray-900 line-clamp-2"
                      dangerouslySetInnerHTML={{ __html: relatedPost.title.rendered }}
                    />
                    
                    <Link
                      to={`/blog/${relatedPost.slug}`}
                      state={{ post: relatedPost }}
                      className="text-purple-600 font-medium inline-flex items-center group"
                    >
                      Read Article
                      <ChevronRight size={16} className="ml-1 group-hover:translate-x-1 transition-transform duration-300" />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white relative overflow-hidden">
        {/* Background decoration */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Need Expert Tech Talent?</h2>
          <p className="text-xl mb-8 text-purple-200 max-w-3xl mx-auto">
            Connect with top-tier tech professionals who match your project requirements, company culture, and technical needs.
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <a 
              href="/get-matched" 
              className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Get Matched Now
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            <Link
              to="/blog" 
              className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Back to Blog
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </Link>
          </div>
        </div>
      </section>

      {/* Custom CSS for WordPress content */}
      <style jsx>{`
        .post-content {
          font-size: 1.125rem;
          line-height: 1.75;
          color: #374151;
        }
        
        .post-content h2 {
          font-size: 1.875rem;
          font-weight: 700;
          margin-top: 2.5rem;
          margin-bottom: 1.25rem;
          color: #111827;
        }
        
        .post-content h3 {
          font-size: 1.5rem;
          font-weight: 700;
          margin-top: 2rem;
          margin-bottom: 1rem;
          color: #111827;
        }
        
        .post-content p {
          margin-bottom: 1.5rem;
        }
        
        .post-content a {
          color: #7c3aed;
          text-decoration: underline;
        }
        
        .post-content a:hover {
          color: #6d28d9;
        }
        
        .post-content ul, .post-content ol {
          margin-top: 1rem;
          margin-bottom: 1rem;
          padding-left: 1.5rem;
        }
        
        .post-content li {
          margin-bottom: 0.5rem;
        }
        
        .post-content blockquote {
          border-left: 4px solid #7c3aed;
          padding-left: 1rem;
          margin-left: 0;
          margin-right: 0;
          font-style: italic;
          color: #4b5563;
        }
        
        .post-content pre {
          background-color: #f3f4f6;
          padding: 1rem;
          border-radius: 0.5rem;
          overflow-x: auto;
          margin-bottom: 1.5rem;
        }
        
        .post-content code {
          background-color: #f3f4f6;
          padding: 0.125rem 0.25rem;
          border-radius: 0.25rem;
          font-size: 0.875em;
          color: #111827;
        }
        
        .post-content img {
          max-width: 100%;
          height: auto;
          border-radius: 0.5rem;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }
        
        .post-content table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }
        
        .post-content table th {
          background-color: #f3f4f6;
          font-weight: 600;
          text-align: left;
          padding: 0.75rem;
          border: 1px solid #e5e7eb;
        }
        
        .post-content table td {
          padding: 0.75rem;
          border: 1px solid #e5e7eb;
        }
        
        .post-content table tr:nth-child(even) {
          background-color: #f9fafb;
        }
      `}</style>
    </div>
  );
};

export default BlogPostPage;