import React from 'react';
import { ChevronRight, CheckCircle, Laptop, BookOpen, Video, Users, BarChart, Award, Clock, Bookmark } from 'lucide-react';

const EdTechPage = () => {
  const services = [
    {
      title: "Learning Management Systems",
      description: "Custom LMS solutions with interactive content delivery, student progress tracking, and assessment tools."
    },
    {
      title: "Virtual Classroom Platforms",
      description: "Real-time virtual classroom environments with video conferencing, interactive whiteboards, and collaboration tools."
    },
    {
      title: "Educational Content Development",
      description: "Interactive educational content creation including simulations, gamified learning modules, and multimedia resources."
    },
    {
      title: "Student Information Systems",
      description: "Comprehensive solutions for managing student data, enrollment, attendance, grading, and academic progress."
    },
    {
      title: "Educational Analytics Platforms",
      description: "Data analytics tools to track learning outcomes, identify intervention opportunities, and optimize educational strategies."
    },
    {
      title: "Mobile Learning Applications",
      description: "Engaging mobile apps for anytime, anywhere learning with personalized content and progress synchronization."
    }
  ];

  const caseStudies = [
    {
      title: "Higher Education LMS Platform",
      description: "Developed a comprehensive learning management system for a network of universities, featuring personalized learning paths, multimedia content delivery, and sophisticated assessment tools.",
      results: ["Deployed across 12 universities with 200,000+ users", "40% increase in student engagement", "25% improvement in course completion rates"]
    },
    {
      title: "K-12 Virtual Classroom Solution",
      description: "Created an immersive virtual classroom environment for K-12 schools, enabling seamless remote learning with interactive lessons, real-time collaboration, and robust progress tracking.",
      results: ["98% teacher adoption rate", "Reduced administrative workload by 65%", "Maintained 93% attendance during remote learning periods"]
    },
    {
      title: "Corporate Training Platform",
      description: "Built a scalable corporate training solution for a multinational company, featuring personalized learning tracks, skill assessments, and comprehensive analytics for workforce development.",
      results: ["85% employee completion rate for required training", "ROI of 320% on training investment", "Reduced onboarding time by 45%"]
    }
  ];

  const technologies = [
    "React", "Node.js", "Python", "Django", "Ruby on Rails",
    "WebRTC", "Socket.io", "MongoDB", "PostgreSQL",
    "AWS", "Docker", "Kubernetes", "CI/CD",
    "Machine Learning", "Data Analytics", "GraphQL",
    "Adaptive Learning Algorithms", "WebGL"
  ];

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
                EDTECH SOLUTIONS
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Innovative Educational Technology for the Digital Age
              </h1>
              <p className="text-xl mb-8 text-purple-100">
                Transforming learning experiences with powerful digital platforms that engage students and optimize educational outcomes.
              </p>
              <div className="flex flex-wrap gap-4">
                <a 
                  href="/get-matched" 
                  className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Get EdTech Experts
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
                <a 
                  href="/contact" 
                  className="group bg-purple-800/50 backdrop-blur-sm hover:bg-purple-800/70 border border-purple-500/30 text-white px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Contact Us
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </div>
            </div>
            <div className="hidden md:block">
              <div className="relative">
                <div className="absolute -left-16 -top-16 w-64 h-64 bg-purple-500 opacity-20 rounded-full animate-pulse"></div>
                <div className="absolute -right-20 -bottom-20 w-80 h-80 bg-indigo-500 opacity-20 rounded-full animate-pulse" style={{ animationDelay: '1s' }}></div>
                
                <div className="relative bg-white dark:bg-gray-900 p-8 rounded-xl shadow-2xl transform hover:scale-105 transition-all duration-500">
                  <div className="flex gap-4 mb-6">
                    <div className="w-3 h-3 rounded-full bg-red-400"></div>
                    <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                    <div className="w-3 h-3 rounded-full bg-green-400"></div>
                  </div>
                  
                  <div className="space-y-6">
                    <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700 pb-4">
                      <div className="flex items-center">
                        <div className="w-10 h-10 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center font-bold mr-3">
                          <BookOpen size={20} />
                        </div>
                        <div>
                          <div className="font-bold text-gray-900 dark:text-white">Learning Dashboard</div>
                          <div className="text-xs text-gray-500 dark:text-gray-400">Student Progress</div>
                        </div>
                      </div>
                      <div className="text-xs text-green-600 font-semibold bg-green-100 px-2 py-1 rounded-full">
                        ACTIVE
                      </div>
                    </div>
                    
                    <div className="space-y-3">
                      <div className="flex justify-between items-center">
                        <div className="text-sm font-medium text-gray-700 dark:text-gray-300">Course Completion</div>
                        <div className="text-sm font-bold text-gray-900 dark:text-white">78%</div>
                      </div>
                      <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                        <div className="h-full bg-gradient-to-r from-purple-600 to-indigo-600 rounded-full w-[78%]"></div>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Lessons Completed</span>
                        <span className="font-medium text-gray-900 dark:text-white">32/45</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Assessment Score</span>
                        <span className="font-medium text-gray-900 dark:text-white">92%</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Time Spent</span>
                        <span className="font-medium text-gray-900 dark:text-white">24.5 hours</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Next Milestone</span>
                        <span className="font-medium text-purple-600 dark:text-purple-400">Final Project</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR SERVICES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Comprehensive EdTech Solutions
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              From learning management systems to educational analytics, we provide end-to-end technology solutions for educational institutions and corporate training.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
              >
                <div className="p-4 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  {[<BookOpen />, <Video />, <Laptop />, <Users />, <BarChart />, <Award />][index % 6]}
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Case Studies */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              SUCCESS STORIES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              EdTech Case Studies
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Discover how we've helped educational institutions and organizations transform their learning environments.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {caseStudies.map((study, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
              >
                <div className="h-12 bg-gradient-to-r from-purple-600 to-indigo-600"></div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-3 text-gray-900">{study.title}</h3>
                  <p className="text-gray-600 mb-4">{study.description}</p>
                  <div className="border-t border-gray-100 pt-4">
                    <h4 className="font-bold text-gray-900 mb-2">Results:</h4>
                    <ul className="space-y-1">
                      {study.results.map((result, i) => (
                        <li key={i} className="flex items-start">
                          <CheckCircle size={16} className="text-green-500 mt-1 mr-2 flex-shrink-0" />
                          <span className="text-gray-600">{result}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Technologies */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              TECH STACK
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Technologies We Use
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our EdTech solutions are built with cutting-edge technologies optimized for interactive learning and performance.
            </p>
          </div>

          <div className="flex flex-wrap justify-center gap-4">
            {technologies.map((tech, index) => (
              <div 
                key={index} 
                className="bg-gray-100 text-gray-800 px-4 py-2 rounded-full font-medium hover:bg-purple-100 hover:text-purple-700 transition-colors duration-300"
              >
                {tech}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Transform Your Educational Technology?</h2>
          <p className="text-xl mb-8 text-purple-200 max-w-3xl mx-auto">
            Connect with our EdTech experts to discuss your educational technology needs and discover how we can help enhance learning outcomes.
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <a 
              href="/get-matched" 
              className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Get EdTech Experts
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            <a 
              href="/contact" 
              className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Contact Us
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EdTechPage;