// src/pages/BlogPage.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Clock, 
  ChevronRight, 
  Search, 
  Tag, 
  Calendar,
  User,
  ArrowRight,
  Loader
} from 'lucide-react';

const BlogPage = () => {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [visiblePosts, setVisiblePosts] = useState(6);

  // WordPress API base URL
  const apiUrl = 'https://cms.soft-house.eu/wp-json/wp/v2';

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${apiUrl}/categories?per_page=20`);
        if (!response.ok) throw new Error('Failed to fetch categories');
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        console.error('Error fetching categories:', err);
        setError(err.message);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        let url = `${apiUrl}/posts?_embed&per_page=100`;
        
        if (activeCategory) {
          url += `&categories=${activeCategory}`;
        }
        
        if (searchQuery) {
          url += `&search=${encodeURIComponent(searchQuery)}`;
        }
        
        const response = await fetch(url);
        if (!response.ok) throw new Error('Failed to fetch posts');
        const data = await response.json();
        setPosts(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching posts:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchPosts();
  }, [activeCategory, searchQuery]);

  // Estimate reading time based on content length
  const getReadingTime = (content) => {
    if (!content) return '3 min read';
    const text = content.replace(/<\/?[^>]+(>|$)/g, '');
    const wordCount = text.split(/\s+/).length;
    const readingTime = Math.ceil(wordCount / 200); // Assuming 200 words per minute
    return `${readingTime} min read`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const loadMorePosts = () => {
    setVisiblePosts(prev => prev + 6);
  };

  const getCategoryName = (categoryId) => {
    const category = categories.find(cat => cat.id === categoryId);
    return category ? category.name : 'Uncategorized';
  };

  const getFirstCategoryId = (post) => {
    return post.categories && post.categories.length > 0 
      ? post.categories[0] 
      : null;
  };

  const filteredPosts = posts
    .filter(post => {
      // If no active filters, show all posts
      if (!activeCategory && !searchQuery) return true;
      
      // Filter by category if active
      if (activeCategory && !post.categories.includes(activeCategory)) return false;
      
      // Search is handled by the API already
      return true;
    });

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-20 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
          
          {/* Animated particles */}
          {[...Array(15)].map((_, i) => (
            <div 
              key={i} 
              className="absolute rounded-full bg-white/10"
              style={{
                width: `${Math.random() * 6 + 2}px`,
                height: `${Math.random() * 6 + 2}px`,
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animation: `float ${Math.random() * 10 + 15}s linear infinite`,
                animationDelay: `${Math.random() * 5}s`,
                opacity: Math.random() * 0.5 + 0.3
              }}
            />
          ))}
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto">
            <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
              BLOG
            </div>
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Insights from Our Tech Experts
            </h1>
            <p className="text-xl mb-8 text-purple-100">
              Discover the latest trends, technologies, and best practices in IT staffing and software development
            </p>
            
            {/* Search Bar */}
            <div className="mt-8 max-w-2xl mx-auto">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search articles..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full bg-white/10 backdrop-blur-sm border border-white/20 rounded-lg py-4 px-6 pl-12 text-white placeholder-white/60 focus:outline-none focus:ring-2 focus:ring-white/30"
                />
                <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white/60" size={20} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Category Filters */}
      <section className="py-8 bg-white border-b border-gray-200 sticky top-0 z-10 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between overflow-x-auto pb-2">
            <div className="flex space-x-4">
              <button
                onClick={() => setActiveCategory(null)}
                className={`px-4 py-2 rounded-full text-sm font-medium whitespace-nowrap transition-colors duration-300 ${
                  activeCategory === null
                    ? 'bg-purple-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                All Articles
              </button>
              
              {categories.map(category => (
                <button
                  key={category.id}
                  onClick={() => setActiveCategory(category.id)}
                  className={`px-4 py-2 rounded-full text-sm font-medium whitespace-nowrap transition-colors duration-300 ${
                    activeCategory === category.id
                      ? 'bg-purple-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Blog Posts Grid */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {loading ? (
            <div className="flex justify-center items-center py-20">
              <Loader className="w-12 h-12 text-purple-600 animate-spin" />
            </div>
          ) : error ? (
            <div className="text-center py-20">
              <p className="text-red-500 text-xl">Error: {error}</p>
              <p className="mt-4 text-gray-600">
                We're having trouble connecting to our blog. Please try again later.
              </p>
            </div>
          ) : filteredPosts.length === 0 ? (
            <div className="text-center py-20">
              <h3 className="text-2xl font-bold text-gray-800 mb-4">No posts found</h3>
              <p className="text-gray-600 mb-8">
                {searchQuery 
                  ? `No articles match your search for "${searchQuery}"`
                  : 'No articles found in this category'}
              </p>
              <button
                onClick={() => {
                  setActiveCategory(null);
                  setSearchQuery('');
                }}
                className="px-6 py-3 bg-purple-600 text-white rounded-lg font-medium hover:bg-purple-700 transition-colors duration-300"
              >
                View All Articles
              </button>
            </div>
          ) : (
            <>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {filteredPosts.slice(0, visiblePosts).map(post => {
                  const categoryId = getFirstCategoryId(post);
                  return (
                    <div key={post.id} className="bg-white rounded-xl shadow-xl border border-gray-100 overflow-hidden transition-all duration-300 hover:shadow-2xl hover:-translate-y-1">
                      {post._embedded && post._embedded['wp:featuredmedia'] ? (
                        <div className="h-48 bg-gradient-to-br from-purple-600 to-indigo-700 overflow-hidden">
                          <img
                            src={post._embedded['wp:featuredmedia'][0].source_url}
                            alt={post.title.rendered}
                            className="w-full h-full object-cover"
                          />
                        </div>
                      ) : (
                        <div className="h-48 bg-gradient-to-br from-purple-600 to-indigo-700 flex items-center justify-center relative overflow-hidden">
                          <div className="absolute inset-0 bg-black/10"></div>
                          <div className="relative z-10 text-white text-5xl font-bold opacity-30">SOFT HOUSE</div>
                          {categoryId && (
                            <div className="absolute top-4 left-4 bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full text-xs font-semibold text-white border border-white/30">
                              {getCategoryName(categoryId)}
                            </div>
                          )}
                        </div>
                      )}
                      
                      <div className="p-6">
                        <div className="flex items-center text-sm text-gray-500 mb-3">
                          <Clock size={14} className="mr-1" />
                          <span>{getReadingTime(post.content.rendered)}</span>
                          <span className="mx-2">•</span>
                          <span>{formatDate(post.date)}</span>
                        </div>
                        
                        <h3 className="text-xl font-bold mb-3 text-gray-900 line-clamp-2">
                          {post.title.rendered}
                        </h3>
                        
                        <div 
                          className="text-gray-600 mb-6 line-clamp-3"
                          dangerouslySetInnerHTML={{ 
                            __html: post.excerpt.rendered 
                          }}
                        />
                        
                        <div className="flex justify-between items-center">
                          {post._embedded && post._embedded.author ? (
                            <div className="flex items-center">
                              <div className="w-8 h-8 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center font-bold text-sm">
                                {post._embedded.author[0].name.charAt(0)}
                              </div>
                              <span className="ml-2 text-sm text-gray-700 font-medium">
                                {post._embedded.author[0].name}
                              </span>
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <div className="w-8 h-8 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center font-bold text-sm">
                                S
                              </div>
                              <span className="ml-2 text-sm text-gray-700 font-medium">
                                SOFT HOUSE Team
                              </span>
                            </div>
                          )}
                          
                          <Link 
                            to={`/blog/${post.slug}`}
                            state={{ post }}
                            className="text-purple-600 font-medium flex items-center group hover:text-purple-800 transition-colors duration-300"
                          >
                            Read More
                            <ArrowRight size={16} className="ml-1 group-hover:translate-x-1 transition-transform duration-300" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              
              {visiblePosts < filteredPosts.length && (
                <div className="text-center mt-12">
                  <button
                    onClick={loadMorePosts}
                    className="px-8 py-3 bg-purple-600 text-white rounded-lg font-medium hover:bg-purple-700 transition-colors duration-300 inline-flex items-center"
                  >
                    Load More Articles
                    <ChevronRight className="ml-2" />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white relative overflow-hidden">
        {/* Background decoration */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Find Your Perfect Tech Match?</h2>
          <p className="text-xl mb-8 text-purple-200 max-w-3xl mx-auto">
            Put our expertise to work for your business. Connect with top tech talent that fits your needs perfectly.
          </p>
          <a 
            href="/get-matched" 
            className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
          >
            Get Matched Now
            <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
          </a>
        </div>
      </section>
    </div>
  );
};

export default BlogPage;