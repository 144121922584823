import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, CheckCircle, Code, Users, Zap, Briefcase, ChevronDown, ArrowRight, Database, Globe, Server, ShieldCheck, Award, Star, Coffee, Clock, Laptop, MessageCircle, Loader } from 'lucide-react';
import ViewMatchesModal from '../components/ViewMatchesModal';

const HomePage = () => {
  const [isVisible, setIsVisible] = useState({});
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [activeTab, setActiveTab] = useState('frontend');
  const [scrollPosition, setScrollPosition] = useState(0);
  const heroRef = useRef(null);
  const [isMatchModalOpen, setIsMatchModalOpen] = useState(false);
  const [modalKey, setModalKey] = useState(Date.now());
  const openMatchModal = () => {
    setModalKey(Date.now());
    setIsMatchModalOpen(true);
};
  
  // Blog posts state
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // WordPress API base URL
  const apiUrl = 'https://cms.soft-house.eu/wp-json/wp/v2';

  // Fetch blog posts from WordPress API
  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch(`${apiUrl}/posts?_embed&per_page=3`);
        if (!response.ok) throw new Error('Failed to fetch blog posts');
        const data = await response.json();
        setBlogPosts(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching blog posts:', err);
        setError(err.message);
        setLoading(false);
        
        // Fallback to hardcoded posts if API fails
        setBlogPosts([
          {
            title: { rendered: "Architecting the Perfect CRM: Java vs. Laravel Showdown" },
            excerpt: { rendered: "A comprehensive comparison between Java and Laravel for building a Customer Relationship Management (CRM) system. Uncover the strengths and weaknesses of both technologies." },
            author_name: "Igor G.",
            slug: "crm-laravel-java",
            categories: [1],
            date: "2025-02-28T10:00:00",
            _embedded: { author: [{ name: "Igor G." }] }
          },
          {
            title: { rendered: "Unlocking the Digital Samurai: Hiring Programmers from Japan" },
            excerpt: { rendered: "In the contemporary world where the software industry reigns supreme, it's vital to have a team of proficient programmers powering your business." },
            author_name: "Igor G.",
            slug: "japan-programmers",
            categories: [2],
            date: "2025-02-15T10:00:00",
            _embedded: { author: [{ name: "Igor G." }] }
          },
          {
            title: { rendered: "Constructing a Social Media Colossus: The Chronicles of Our Client's Dream Project" },
            excerpt: { rendered: "Discover the riveting journey of building a social media platform for our client. Get an insider's perspective on the choices we made." },
            author_name: "SOFT HOUSE Team",
            slug: "social-media-platform",
            categories: [3],
            date: "2025-01-22T10:00:00",
            _embedded: { author: [{ name: "SOFT HOUSE Team" }] }
          }
        ]);
      }
    };

    fetchBlogPosts();
  }, []);

  // Handle scroll position for parallax and navbar effects
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Intersection Observer for scroll animations
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(prev => ({ ...prev, [entry.target.id]: true }));
          }
        });
      },
      { threshold: 0.15, rootMargin: "0px 0px -100px 0px" }
    );

    const sections = document.querySelectorAll('.animate-section');
    sections.forEach(section => observer.observe(section));

    return () => sections.forEach(section => observer.unobserve(section));
  }, []);

  // Testimonial rotation
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial(prev => (prev + 1) % testimonials.length);
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  // Typewriter effect for hero
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const phrase = "Match with Vetted Experts";
  
  useEffect(() => {
    if (currentIndex < phrase.length) {
      const timeout = setTimeout(() => {
        setDisplayText(prev => prev + phrase[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, 100);
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, phrase]);

  // Project counter animation
  const [projectCount, setProjectCount] = useState(0);
  const [animateCount, setAnimateCount] = useState(false);
  const countRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !animateCount) {
          setAnimateCount(true);
        }
      },
      { threshold: 0.5 }
    );
    
    if (countRef.current) {
      observer.observe(countRef.current);
    }
    
    return () => {
      if (countRef.current) {
        observer.unobserve(countRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (animateCount && projectCount < 500) {
      const timer = setTimeout(() => {
        setProjectCount(prev => 
          prev + Math.ceil((500 - prev) / 10)
        );
      }, 30);
      return () => clearTimeout(timer);
    }
  }, [projectCount, animateCount]);

  const testimonials = [
    {
      text: "SOFT HOUSE found us developers that perfectly matched our tech stack and company culture. The onboarding was seamless, and our productivity increased by 35% within just two months of collaboration.",
      author: "Sarah Chen",
      role: "CTO at TechVision",
      industry: "Financial Services",
      image: "/images/profiles/w_1.jpg"
    },
    {
      text: "We've been working with SOFT HOUSE for over 2 years now. Their talent matching is unparalleled in the industry. What impressed us most was how quickly the developers integrated with our existing team.",
      author: "Michael Rodriguez",
      role: "Engineering Lead",
      industry: "E-commerce Platform",
      image: "/images/profiles/m_1.jpg"
    },
    {
      text: "The quality of developers we've hired through SOFT HOUSE has consistently exceeded our expectations. They understand our technical needs and company values, matching us with professionals who fit both.",
      author: "Jennifer Park",
      role: "Head of Development",
      industry: "Healthcare Tech",
      image: "/images/profiles/w_2.jpg"
    }
  ];

  const techStacks = {
    frontend: [
      { name: "React", level: 90 },
      { name: "Angular", level: 85 },
      { name: "Vue.js", level: 80 },
      { name: "Next.js", level: 85 },
      { name: "Tailwind CSS", level: 90 }
    ],
    backend: [
      { name: "Node.js", level: 85 },
      { name: "Java", level: 90 },
      { name: "Python", level: 85 },
      { name: "PHP/Laravel", level: 80 },
      { name: ".NET", level: 85 }
    ],
    database: [
      { name: "PostgreSQL", level: 90 },
      { name: "MongoDB", level: 85 },
      { name: "MySQL", level: 90 },
      { name: "Redis", level: 80 },
      { name: "Firebase", level: 85 }
    ],
    devops: [
      { name: "AWS", level: 85 },
      { name: "Docker", level: 90 },
      { name: "Kubernetes", level: 80 },
      { name: "CI/CD", level: 85 },
      { name: "Terraform", level: 80 }
    ]
  };

  const industries = [
    { icon: <Database size={24} />, name: "FinTech", link: "/fintech" },
    { icon: <Globe size={24} />, name: "E-commerce", link: "/ecommerce" },
    { icon: <Users size={24} />, name: "Social Media", link: "/socialmedia" },
    { icon: <ShieldCheck size={24} />, name: "Cybersecurity", link: "/cybersecurity" },
    { icon: <Laptop size={24} />, name: "EdTech", link: "/edtech" },
    { icon: <Server size={24} />, name: "Cloud Services", link: "/cloudservices" },
    { icon: <MessageCircle size={24} />, name: "Communication", link: "/communication" },
    { icon: <Award size={24} />, name: "HealthTech", link: "/healthtech" }
  ];

  const logos = [
    { name: 'T-mobile', image: '/images/logos/t-mobile.svg' },
    { name: 'Sii', image: '/images/logos/sii.svg' },
    { name: 'Nurofron', image: '/images/logos/nurofron.svg' },
    { name: 'Company 4', image: '/logos/company4.svg' },
    { name: 'Company 5', image: '/logos/company5.svg' },
    { name: 'Company 6', image: '/logos/company6.svg' },
  ];
  // Helper function for blog posts
  const getReadingTime = (content) => {
    if (!content) return '3 min read';
    const text = content.replace(/<\/?[^>]+(>|$)/g, '');
    const wordCount = text.split(/\s+/).length;
    const readingTime = Math.ceil(wordCount / 200); // Assuming 200 words per minute
    return `${readingTime} min read`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const getCategoryName = (post) => {
    if (!post || !post._embedded || !post._embedded['wp:term']) {
      return 'General';
    }
    
    const categories = post._embedded['wp:term'].find(terms => 
      terms.length > 0 && terms[0].taxonomy === 'category'
    );
    
    if (!categories || categories.length === 0) {
      return 'General';
    }
    
    return categories[0].name;
  };

  const animateClass = (id) => 
    isVisible[id] ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10';

  return (
    <div className="font-sans text-gray-900 overflow-x-hidden">
      {/* Fixed background gradient */}
      <div className="fixed inset-0 bg-gradient-to-br from-indigo-900/10 via-transparent to-purple-900/5 pointer-events-none z-0"></div>

      {/* Hero Section with Parallax */}
      <section 
        ref={heroRef}
        className="relative pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white overflow-hidden min-h-screen flex items-center"
      >
        {/* Animated particles */}
        <div className="absolute inset-0 overflow-hidden">
          {[...Array(20)].map((_, i) => (
            <div 
              key={i} 
              className="absolute rounded-full bg-white/10"
              style={{
                width: `${Math.random() * 6 + 2}px`,
                height: `${Math.random() * 6 + 2}px`,
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animation: `float ${Math.random() * 10 + 15}s linear infinite`,
                animationDelay: `${Math.random() * 5}s`,
                opacity: Math.random() * 0.5 + 0.3
              }}
            />
          ))}
        </div>

        {/* Hero content */}
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 z-10">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="animate-section" id="hero-text">
              <div className={`transition-all duration-1000 ${animateClass('hero-text')}`}>
                <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
                  Premium Tech Talent Matchmaking
                </div>
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6">
                  Hire Top Tech Talent{" "}
                  <span className="bg-gradient-to-r from-purple-300 to-indigo-300 bg-clip-text text-transparent">
                    {displayText}
                    <span className="animate-blink">|</span>
                  </span>
                </h1>
                <p className="text-xl mb-8 text-purple-100 max-w-lg">
                  Our AI-powered matchmaking ensures perfect compatibility with your company culture, language requirements, tech stack, and soft skills.
                </p>
                <div className="flex flex-wrap gap-4">
                  <a 
                    href="/get-matched" 
                    className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                  >
                    Get Your Match
                    <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                  </a>
                  <a 
                    href="/#how-it-works" 
                    className="group bg-purple-800/50 backdrop-blur-sm hover:bg-purple-800/70 border border-purple-500/30 text-white px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                  >
                    How It Works
                    <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                  </a>
                </div>
                
                <div className="mt-12 flex items-center space-x-4">
                  <div className="flex -space-x-3">
                    {[...Array(4)].map((_, i) => (
                      <div key={i} className="w-10 h-10 rounded-full border-2 border-purple-900 bg-purple-600 text-white flex items-center justify-center text-xs font-bold">
                        {["JS", "PY", "JV", "PH"][i]}
                      </div>
                    ))}
                  </div>
                  <span className="text-sm text-purple-200">
                    <span className="font-bold">500+</span> Expert developers in our talent pool
                  </span>
                </div>
              </div>
            </div>
            <div className={`animate-section transition-all duration-1000 ${animateClass('hero-image')}`} id="hero-image">
              <div className="relative">
                {/* Decorative circles with parallax effect */}
                <div 
                  className="absolute -left-16 -top-16 w-64 h-64 bg-purple-500 opacity-20 rounded-full animate-pulse"
                  style={{ 
                    transform: `translate(${scrollPosition * 0.05}px, ${scrollPosition * 0.08}px)`,
                    transition: 'transform 0.1s ease-out'
                  }}
                ></div>
                <div 
                  className="absolute -right-20 -bottom-20 w-80 h-80 bg-indigo-500 opacity-20 rounded-full animate-pulse" 
                  style={{ 
                    animationDelay: '1s',
                    transform: `translate(${-scrollPosition * 0.07}px, ${-scrollPosition * 0.05}px)`,
                    transition: 'transform 0.1s ease-out'
                  }}
                ></div>
                
                {/* Main card */}
                <div className="relative bg-white dark:bg-gray-900 p-8 rounded-xl shadow-2xl transform hover:scale-105 transition-all duration-500">
                  <div className="flex gap-4 mb-6">
                    <div className="w-3 h-3 rounded-full bg-red-400"></div>
                    <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                    <div className="w-3 h-3 rounded-full bg-green-400"></div>
                  </div>
                  
                  <div className="space-y-6">
                    <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700 pb-4">
                      <div className="flex items-center">
                        <div className="w-10 h-10 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center font-bold mr-3">
                          SH
                        </div>
                        <div>
                          <div className="font-bold text-gray-900 dark:text-white">SOFT HOUSE</div>
                          <div className="text-xs text-gray-500 dark:text-gray-400">Talent Matching</div>
                        </div>
                      </div>
                      <div className="text-xs text-purple-600 font-semibold bg-purple-100 px-2 py-1 rounded-full">
                        ACTIVE
                      </div>
                    </div>
                    
                    <div className="space-y-3">
                      <div className="flex justify-between items-center">
                        <div className="text-sm font-medium text-gray-700 dark:text-gray-300">Match Progress</div>
                        <div className="text-sm font-bold text-gray-900 dark:text-white">86%</div>
                      </div>
                      <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                        <div className="h-full bg-gradient-to-r from-purple-600 to-indigo-600 rounded-full w-5/6"></div>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Matching</span>
                        <span className="font-medium text-gray-900 dark:text-white">Tech Stack</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Expertise Level</span>
                        <span className="font-medium text-gray-900 dark:text-white">Senior</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Availability</span>
                        <span className="font-medium text-gray-900 dark:text-white">Immediate</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500 dark:text-gray-400">Timezone</span>
                        <span className="font-medium text-gray-900 dark:text-white">GMT+1</span>
                      </div>
                    </div>
                    
                    <div className="pt-2">
                        <button onClick={openMatchModal} className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-3 rounded-lg font-medium hover:shadow-lg transition-all duration-300">
                            View 3 Matches
                        </button>
                    </div>
                  </div>
                  
                  {/* Notification panel */}
                  <div className="absolute -right-6 top-20 transform rotate-3 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-xl border border-gray-200 dark:border-gray-700 w-64">
                    <div className="flex items-start space-x-3">
                      <div className="w-8 h-8 rounded-full bg-green-500 text-white flex items-center justify-center">
                        <CheckCircle size={16} />
                      </div>
                      <div>
                        <div className="font-medium text-gray-900 dark:text-white">React Developer Found!</div>
                        <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">Jake M. matches 96% of your requirements</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Clients logos 
          <div className={`mt-20 pt-10 border-t border-white/10`}>
      <p className="text-center text-sm font-medium text-purple-200 mb-6">TRUSTED BY INNOVATIVE COMPANIES WORLDWIDE</p>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 items-center justify-items-center opacity-70">
        {logos.map((logo, index) => (
          <div key={index} className="h-12 flex items-center justify-center">
            {/* If you have actual logo images 
            {logo.image ? (
              <img 
                src={logo.image} 
                alt={`${logo.name} logo`} 
                className="max-h-full max-w-full object-contain grayscale" 
              />
            ) : (
              // Placeholder for development
              <div className="h-6 bg-white/90 rounded w-24"></div>
            )}
          </div>
        ))}
      </div>
    </div>*/}
        </div>
      </section>

      {/* Stats Section with Counter Animation */}
      <section className="py-16 bg-white relative overflow-hidden">
        {/* Background decoration */}
        <div className="absolute -left-64 top-0 w-96 h-96 bg-purple-100 rounded-full mix-blend-multiply filter blur-3xl opacity-70"></div>
        <div className="absolute -right-64 -bottom-32 w-96 h-96 bg-indigo-100 rounded-full mix-blend-multiply filter blur-3xl opacity-70"></div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-center">
            <div className="animate-section" id="stat-1" ref={countRef}>
              <div className={`bg-white rounded-xl shadow-sm border border-gray-100 p-6 h-full transition-all duration-1000 transform hover:scale-105 hover:shadow-md ${animateClass('stat-1')}`}>
                <div className="text-4xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent mb-2">{projectCount}+</div>
                <div className="text-gray-600 font-medium">Expert Developers</div>
              </div>
            </div>
            <div className="animate-section" id="stat-2">
              <div className={`bg-white rounded-xl shadow-sm border border-gray-100 p-6 h-full transition-all duration-1000 delay-100 transform hover:scale-105 hover:shadow-md ${animateClass('stat-2')}`}>
                <div className="text-4xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent mb-2">98%</div>
                <div className="text-gray-600 font-medium">Client Satisfaction</div>
              </div>
            </div>
            <div className="animate-section" id="stat-3">
              <div className={`bg-white rounded-xl shadow-sm border border-gray-100 p-6 h-full transition-all duration-1000 delay-200 transform hover:scale-105 hover:shadow-md ${animateClass('stat-3')}`}>
                <div className="text-4xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent mb-2">48h</div>
                <div className="text-gray-600 font-medium">Average Match Time</div>
              </div>
            </div>
            <div className="animate-section" id="stat-4">
              <div className={`bg-white rounded-xl shadow-sm border border-gray-100 p-6 h-full transition-all duration-1000 delay-300 transform hover:scale-105 hover:shadow-md ${animateClass('stat-4')}`}>
                <div className="text-4xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent mb-2">15+</div>
                <div className="text-gray-600 font-medium">Technologies</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Industries Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12 animate-section" id="industries-title">
            <div className={`transition-all duration-1000 ${animateClass('industries-title')}`}>
              <h2 className="text-3xl font-bold text-gray-900 mb-4">Industries We Serve</h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                We provide top tech talent across a wide range of industries and specializations
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            {industries.map((industry, index) => (
              <div className="animate-section" id={`industry-${index}`} key={index}>
                <Link to={industry.link} className="block">
                  <div className={`bg-white rounded-xl shadow-sm border border-gray-100 p-6 transition-all duration-1000 delay-${index * 75} transform hover:scale-105 hover:shadow-md ${animateClass(`industry-${index}`)}`}>
                    <div className="flex items-center">
                      <div className="p-3 bg-purple-100 text-purple-600 rounded-lg mr-4">
                        {industry.icon}
                      </div>
                      <h3 className="text-lg font-bold text-gray-900">{industry.name}</h3>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-20 relative overflow-hidden">
        {/* Background decoration */}
        <div className="absolute top-0 left-0 w-full h-64 bg-gradient-to-b from-gray-50 to-transparent pointer-events-none"></div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16 animate-section" id="services-title">
            <div className={`transition-all duration-1000 ${animateClass('services-title')}`}>
              <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">OUR SERVICES</div>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">How We Can Help Your Business</h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                We specialize in connecting businesses with top-tier tech talent across multiple disciplines.
              </p>
            </div>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            <div className="animate-section" id="service-1">
              <div className={`bg-white rounded-xl shadow-xl border border-gray-100 p-8 h-full transition-all duration-1000 hover:shadow-2xl hover:-translate-y-2 ${animateClass('service-1')}`}>
                <div className="p-4 bg-purple-600 text-white rounded-2xl inline-block mb-6 shadow-lg">
                  <Code size={28} />
                </div>
                <h3 className="text-2xl font-bold mb-4">Software Development</h3>
                <p className="text-gray-600 mb-6">
                  Access expert developers skilled in React, Java, Python, Laravel, Node.js, and more to build your next digital product with precision and excellence.
                </p>
                <ul className="space-y-2 mb-6">
                  <li className="flex items-center text-gray-700">
                    <CheckCircle size={16} className="text-purple-600 mr-2" />
                    <span>Full-stack development</span>
                  </li>
                  <li className="flex items-center text-gray-700">
                    <CheckCircle size={16} className="text-purple-600 mr-2" />
                    <span>Mobile app development</span>
                  </li>
                  <li className="flex items-center text-gray-700">
                    <CheckCircle size={16} className="text-purple-600 mr-2" />
                    <span>API integration</span>
                  </li>
                </ul>
                <a 
                  href="/software-development" 
                  className="flex items-center text-purple-600 font-medium group"
                >
                  <span className="mr-1">Learn more</span>
                  <ChevronRight size={16} className="group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </div>
            </div>

            <div className="animate-section" id="service-2">
              <div className={`bg-white rounded-xl shadow-xl border border-gray-100 p-8 h-full transition-all duration-1000 delay-200 hover:shadow-2xl hover:-translate-y-2 ${animateClass('service-2')}`}>
                <div className="p-4 bg-purple-600 text-white rounded-2xl inline-block mb-6 shadow-lg">
                  <Users size={28} />
                </div>
                <h3 className="text-2xl font-bold mb-4">IT Staff Augmentation</h3>
                <p className="text-gray-600 mb-6">
                  Extend your team with skilled professionals that integrate seamlessly with your company culture and workflow to accelerate your project delivery.
                </p>
                <ul className="space-y-2 mb-6">
                  <li className="flex items-center text-gray-700">
                    <CheckCircle size={16} className="text-purple-600 mr-2" />
                    <span>Flexible engagement models</span>
                  </li>
                  <li className="flex items-center text-gray-700">
                    <CheckCircle size={16} className="text-purple-600 mr-2" />
                    <span>Seamless team integration</span>
                  </li>
                  <li className="flex items-center text-gray-700">
                    <CheckCircle size={16} className="text-purple-600 mr-2" />
                    <span>Scalable resources</span>
                  </li>
                </ul>
                <a 
                  href="/it-staff-augmentation" 
                  className="flex items-center text-purple-600 font-medium group"
                >
                  <span className="mr-1">Learn more</span>
                  <ChevronRight size={16} className="group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </div>
            </div>

            <div className="animate-section" id="service-3">
              <div className={`bg-white rounded-xl shadow-xl border border-gray-100 p-8 h-full transition-all duration-1000 delay-400 hover:shadow-2xl hover:-translate-y-2 ${animateClass('service-3')}`}>
                <div className="p-4 bg-purple-600 text-white rounded-2xl inline-block mb-6 shadow-lg">
                  <Zap size={28} />
                </div>
                <h3 className="text-2xl font-bold mb-4">Custom Project Development</h3>
                <p className="text-gray-600 mb-6">
                  From idea to launch, we build digital products tailored to your business needs and market requirements with a focus on quality and innovation.
                </p>
                <ul className="space-y-2 mb-6">
                  <li className="flex items-center text-gray-700">
                    <CheckCircle size={16} className="text-purple-600 mr-2" />
                    <span>End-to-end development</span>
                  </li>
                  <li className="flex items-center text-gray-700">
                    <CheckCircle size={16} className="text-purple-600 mr-2" />
                    <span>User-centered design</span>
                  </li>
                  <li className="flex items-center text-gray-700">
                    <CheckCircle size={16} className="text-purple-600 mr-2" />
                    <span>Ongoing maintenance</span>
                  </li>
                </ul>
                <a 
                  href="/custom-project-development" 
                  className="flex items-center text-purple-600 font-medium group"
                >
                  <span className="mr-1">Learn more</span>
                  <ChevronRight size={16} className="group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works */}
      <section id="how-it-works" className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white relative overflow-hidden">
        {/* Background particles */}
        <div className="absolute inset-0 overflow-hidden">
          {[...Array(15)].map((_, i) => (
            <div 
              key={i} 
              className="absolute rounded-full bg-white/10"
              style={{
                width: `${Math.random() * 6 + 2}px`,
                height: `${Math.random() * 6 + 2}px`,
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animation: `float ${Math.random() * 10 + 15}s linear infinite`,
                animationDelay: `${Math.random() * 5}s`,
                opacity: Math.random() * 0.5 + 0.3
              }}
            />
          ))}
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16 animate-section" id="hiw-title">
            <div className={`transition-all duration-1000 ${animateClass('hiw-title')}`}>
              <div className="inline-block bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-4 border border-white/20">
                SIMPLE PROCESS
              </div>
              <h2 className="text-3xl md:text-4xl font-bold mb-4">How It Works</h2>
              <p className="text-xl text-purple-200 max-w-3xl mx-auto">
                Our streamlined process ensures we find the perfect match for your needs quickly and efficiently.
              </p>
            </div>
          </div>

          <div className="relative">
            {/* Connection line */}
          
            
            <div className="grid md:grid-cols-4 gap-8">
              <div className="animate-section" id="step-1">
                <div className={`relative transition-all duration-1000 ${animateClass('step-1')}`}>
                  <div className="bg-white/10 backdrop-blur-sm border border-white/20 rounded-xl p-6 h-full relative z-10 hover:bg-white/20 transition-all duration-300">
                    <div className="w-16 h-16 bg-purple-600 text-white rounded-full flex items-center justify-center font-bold text-2xl mb-6 shadow-lg mx-auto md:mx-0">1</div>
                    <h3 className="text-xl font-bold mb-3">Submit Requirements</h3>
                    <p className="text-purple-200">
                      Share your project details, tech stack requirements, timeline, and team culture preferences through our intuitive matching form.
                    </p>
                  </div>
                </div>
              </div>

              <div className="animate-section" id="step-2">
                <div className={`relative transition-all duration-1000 delay-200 ${animateClass('step-2')}`}>
                  <div className="bg-white/10 backdrop-blur-sm border border-white/20 rounded-xl p-6 h-full relative z-10 hover:bg-white/20 transition-all duration-300">
                    <div className="w-16 h-16 bg-purple-600 text-white rounded-full flex items-center justify-center font-bold text-2xl mb-6 shadow-lg mx-auto md:mx-0">2</div>
                    <h3 className="text-xl font-bold mb-3">Expert Matching</h3>
                    <p className="text-purple-200">
                      Our AI-powered algorithm identifies top candidates from our pool of pre-vetted professionals based on your specific needs.
                    </p>
                  </div>
                </div>
              </div>

              <div className="animate-section" id="step-3">
                <div className={`relative transition-all duration-1000 delay-400 ${animateClass('step-3')}`}>
                  <div className="bg-white/10 backdrop-blur-sm border border-white/20 rounded-xl p-6 h-full relative z-10 hover:bg-white/20 transition-all duration-300">
                    <div className="w-16 h-16 bg-purple-600 text-white rounded-full flex items-center justify-center font-bold text-2xl mb-6 shadow-lg mx-auto md:mx-0">3</div>
                    <h3 className="text-xl font-bold mb-3">Interview & Select</h3>
                    <p className="text-purple-200">
                      Meet with pre-screened candidates and choose the perfect match for your team from our curated selection of tech professionals.
                    </p>
                  </div>
                </div>
              </div>

              <div className="animate-section" id="step-4">
                <div className={`relative transition-all duration-1000 delay-600 ${animateClass('step-4')}`}>
                  <div className="bg-white/10 backdrop-blur-sm border border-white/20 rounded-xl p-6 h-full relative z-10 hover:bg-white/20 transition-all duration-300">
                    <div className="w-16 h-16 bg-purple-600 text-white rounded-full flex items-center justify-center font-bold text-2xl mb-6 shadow-lg mx-auto md:mx-0">4</div>
                    <h3 className="text-xl font-bold mb-3">Onboarding & Support</h3>
                    <p className="text-purple-200">
                      We facilitate smooth integration and provide ongoing support throughout the engagement to ensure long-term success. <br/><br/>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mt-16 animate-section" id="hiw-cta">
            <div className={`transition-all duration-1000 delay-800 ${animateClass('hiw-cta')}`}>
              <a 
                href="/get-matched" 
                className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
              >
                Start Your Matching Process
                <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Expertise Section */}
      <section id="expertise" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16 animate-section" id="expertise-title">
            <div className={`transition-all duration-1000 ${animateClass('expertise-title')}`}>
              <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">OUR EXPERTISE</div>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Technical Proficiency</h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                Our talent pool includes experts across a wide range of technologies and specializations.
              </p>
            </div>
          </div>

          <div className="animate-section" id="tech-tabs">
            <div className={`transition-all duration-1000 ${animateClass('tech-tabs')}`}>
              <div className="bg-white rounded-xl shadow-xl border border-gray-100 overflow-hidden">
                <div className="flex flex-wrap border-b border-gray-200">
                  {Object.keys(techStacks).map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveTab(tab)}
                      className={`px-6 py-4 text-sm font-medium transition-colors duration-300 ${
                        activeTab === tab
                          ? 'border-b-2 border-purple-600 text-purple-600'
                          : 'text-gray-500 hover:text-gray-700'
                      }`}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                  ))}
                </div>
                
                <div className="p-8">
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h3 className="text-xl font-bold mb-6 text-gray-900 capitalize">{activeTab} Technologies</h3>
                      <div className="space-y-6">
                        {techStacks[activeTab].map((tech, index) => (
                          <div key={index}>
                            <div className="flex justify-between mb-2">
                              <span className="font-medium text-gray-700">{tech.name}</span>
                              <span className="text-sm text-gray-500">{tech.level}%</span>
                            </div>
                            <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                              <div
                                className="h-full bg-gradient-to-r from-purple-600 to-indigo-600 rounded-full"
                                style={{ width: `${tech.level}%` }}
                              ></div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    
                    <div className="bg-purple-50 rounded-xl p-6 flex flex-col justify-center">
                      <h3 className="text-xl font-bold mb-4 text-gray-900">Why Choose Our {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} Experts</h3>
                      <ul className="space-y-3">
                        <li className="flex items-start">
                          <CheckCircle size={18} className="text-purple-600 mr-2 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">Extensive experience in enterprise-level projects</span>
                        </li>
                        <li className="flex items-start">
                          <CheckCircle size={18} className="text-purple-600 mr-2 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">Proficiency in the latest frameworks and tools</span>
                        </li>
                        <li className="flex items-start">
                          <CheckCircle size={18} className="text-purple-600 mr-2 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">Strong problem-solving abilities and clean code practices</span>
                        </li>
                        <li className="flex items-start">
                          <CheckCircle size={18} className="text-purple-600 mr-2 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">Continuous learning and skill development</span>
                        </li>
                      </ul>
                      <div className="mt-6">
                        <Link 
                          to={`/${activeTab}-experts`}
                          className="group inline-flex items-center text-purple-600 font-medium"
                        >
                          Find Your {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} Expert
                          <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section id="testimonials" className="py-20 bg-purple-50 relative overflow-hidden">
        {/* Background decoration */}
        <div className="absolute -left-32 -bottom-32 w-96 h-96 bg-purple-200 rounded-full mix-blend-multiply filter blur-3xl opacity-70"></div>
        <div className="absolute -right-32 -top-32 w-96 h-96 bg-indigo-200 rounded-full mix-blend-multiply filter blur-3xl opacity-70"></div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16 animate-section" id="testimonials-title">
            <div className={`transition-all duration-1000 ${animateClass('testimonials-title')}`}>
              <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">TESTIMONIALS</div>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">What Our Clients Say</h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                We've helped companies of all sizes find their perfect tech match.
              </p>
            </div>
          </div>

          <div className="animate-section" id="testimonials-slider">
            <div className={`relative transition-all duration-1000 ${animateClass('testimonials-slider')}`}>
              {/* Testimonial card with animated transition */}
              <div className="max-w-4xl mx-auto relative h-96 md:h-64">
                {testimonials.map((testimonial, index) => (
                  <div 
                    key={index}
                    className={`absolute w-full transition-all duration-700 ${
                      currentTestimonial === index 
                        ? 'opacity-100 translate-x-0' 
                        : index < currentTestimonial 
                          ? 'opacity-0 -translate-x-full' 
                          : 'opacity-0 translate-x-full'
                    }`}
                  >
                    <div className="bg-white rounded-xl shadow-xl p-8 border border-gray-100">
                      <div className="flex flex-col md:flex-row items-start md:items-center">
                        <div className="flex-none mb-4 md:mb-0 md:mr-6">
                          <div className="w-16 h-16 rounded-full bg-purple-100 overflow-hidden ring-4 ring-purple-50">
                            <img 
                              src={testimonial.image} 
                              alt={testimonial.author}
                              className="w-full h-full object-cover"
                            />
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="flex items-center mb-4">
                            {[...Array(5)].map((_, i) => (
                              <Star key={i} size={18} className="text-yellow-400 fill-current" />
                            ))}
                            <span className="ml-2 text-sm font-medium text-gray-600">5.0</span>
                          </div>
                          <p className="text-xl text-gray-700 mb-6 italic">"{testimonial.text}"</p>
                          <div>
                            <div className="font-bold text-gray-900">{testimonial.author}</div>
                            <div className="text-gray-600">{testimonial.role}</div>
                            <div className="text-sm text-purple-600 mt-1">{testimonial.industry}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <br></br>
              <div className="flex justify-center space-x-2 mt-32 md:mt-8 relative z-10">
                {testimonials.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentTestimonial(index)}
                    className={`w-3 h-3 rounded-full transition-colors duration-300 ${
                      currentTestimonial === index ? 'bg-purple-600' : 'bg-gray-300'
                    }`}
                    aria-label={`Go to testimonial ${index + 1}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Blog Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16 animate-section" id="blog-title">
            <div className={`transition-all duration-1000 ${animateClass('blog-title')}`}>
              <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">INSIGHTS</div>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Latest from Our Blog</h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                Discover industry trends, technical insights, and success stories from our experts.
              </p>
            </div>
          </div>

          {loading ? (
  <div className="flex justify-center items-center h-64">
    <Loader className="w-12 h-12 text-purple-600 animate-spin" />
  </div>
) : (
  <div className="grid md:grid-cols-3 gap-8">
      {blogPosts.map((post, index) => (
    <div 
      className={`blog-item blog-item-${index}`} 
      id={`blog-${index}`} 
      key={index}
      ref={el => {
        // Manual intersection observer setup for blog items
        if (!el) return;
        
        const observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                el.classList.add('visible');
                observer.unobserve(el);
              }
            });
          },
          { threshold: 0.1 }
        );
        
        observer.observe(el);
      }}
    >
      <div className="bg-white rounded-xl shadow-xl border border-gray-100 overflow-hidden h-full hover:shadow-2xl hover:-translate-y-2 transition-all duration-300">
        {post._embedded && post._embedded['wp:featuredmedia'] ? (
          <div className="h-48 bg-gradient-to-br from-purple-600 to-indigo-700 overflow-hidden">
            <img
              src={post._embedded['wp:featuredmedia'][0].source_url}
              alt={post.title.rendered}
              className="w-full h-full object-cover"
            />
          </div>
        ) : (
          <div className="h-48 bg-gradient-to-br from-purple-600 to-indigo-700 flex items-center justify-center relative overflow-hidden">
            <div className="absolute inset-0 bg-black/10"></div>
            <div className="relative z-10 text-white text-5xl font-bold opacity-30">SOFT HOUSE</div>
            <div className="absolute top-4 left-4 bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full text-xs font-semibold text-white border border-white/30">
              {getCategoryName(post)}
            </div>
          </div>
        )}
        <div className="p-6">
          <div className="flex items-center text-sm text-gray-500 mb-3">
            <Clock size={14} className="mr-1" />
            <span>{getReadingTime(post.content ? post.content.rendered : '')}</span>
            <span className="mx-2">•</span>
            <span>{formatDate(post.date)}</span>
          </div>
          <h3 className="text-xl font-bold mb-3 text-gray-900">
            {post.title && post.title.rendered ? (
              <span dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
            ) : (
              "Untitled Post"
            )}
          </h3>
          <div className="text-gray-600 mb-6 h-18 overflow-hidden">
            {post.excerpt && post.excerpt.rendered ? (
              <span dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
            ) : (
              "No description available."
            )}
          </div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              {post._embedded && post._embedded.author ? (
                <>
                  <div className="w-8 h-8 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center font-bold text-sm">
                    {post._embedded.author[0].name.charAt(0)}
                  </div>
                  <span className="ml-2 text-sm text-gray-700 font-medium">{post._embedded.author[0].name}</span>
                </>
              ) : (
                <>
                  <div className="w-8 h-8 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center font-bold text-sm">
                    S
                  </div>
                  <span className="ml-2 text-sm text-gray-700 font-medium">SOFT HOUSE Team</span>
                </>
              )}
            </div>
            <Link 
              to={`/blog/${post.slug}`}
              state={{ post }}
              className="text-purple-600 font-medium flex items-center group hover:text-purple-800 transition-colors duration-300"
            >
              Read More
              <ArrowRight size={16} className="ml-1 group-hover:translate-x-1 transition-transform duration-300" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  ))}
  </div>
)}

          <div className="text-center mt-12 animate-section" id="blog-cta">
            <div className={`transition-all duration-1000 delay-600 ${animateClass('blog-cta')}`}>
              <Link 
                to="/blog"
                className="group text-purple-600 hover:text-purple-800 font-bold text-lg inline-flex items-center transition-colors duration-300"
              >
                View All Articles
                <ChevronRight className="ml-1 group-hover:translate-x-1 transition-transform duration-300" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white relative overflow-hidden">
        {/* Animated background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
          
          {[...Array(20)].map((_, i) => (
            <div 
              key={i} 
              className="absolute rounded-full bg-white/10"
              style={{
                width: `${Math.random() * 6 + 2}px`,
                height: `${Math.random() * 6 + 2}px`,
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animation: `float ${Math.random() * 10 + 15}s linear infinite`,
                animationDelay: `${Math.random() * 5}s`,
                opacity: Math.random() * 0.5 + 0.3
              }}
            />
          ))}
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="animate-section" id="cta-content">
              <div className={`transition-all duration-1000 ${animateClass('cta-content')}`}>
                <div className="inline-block bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-white/20">
                  GET STARTED
                </div>
                <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Find Your Perfect Tech Match?</h2>
                <p className="text-xl mb-8 text-purple-200">
                  Our experts are ready to help you find the right talent for your next project. Start the matching process today.
                </p>
                <div className="flex flex-wrap gap-4">
                  <a 
                    href="/get-matched" 
                    className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                  >
                    Get Matched
                    <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                  </a>
                  <a 
                    href="/contact" 
                    className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                  >
                    Contact Us
                    <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                  </a>
                </div>
              </div>
            </div>
            <div className="animate-section" id="cta-image">
              <div className={`transition-all duration-1000 delay-300 ${animateClass('cta-image')}`}>
                <div className="relative">
                  <div className="absolute -left-4 -top-4 w-64 h-64 bg-purple-500/30 rounded-full animate-pulse"></div>
                  <div className="relative bg-white text-gray-900 p-8 rounded-xl shadow-2xl">
                    <div className="flex justify-between items-center mb-8 pb-4 border-b border-gray-100">
                      <div className="flex items-center">
                        <div className="w-10 h-10 bg-purple-100 rounded-full text-purple-600 flex items-center justify-center font-bold mr-3">SH</div>
                        <div className="font-bold">SOFT HOUSE Match</div>
                      </div>
                      <div className="text-xs bg-green-100 text-green-700 px-2 py-1 rounded-full font-medium">LIVE</div>
                    </div>
                    
                    <div className="space-y-6">
                      <div className="flex space-x-4">
                        <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center text-purple-600">
                          <CheckCircle size={24} />
                        </div>
                        <div>
                          <h3 className="font-bold text-lg">Fast Matching</h3>
                          <p className="text-gray-600">Average time: 48 hours</p>
                        </div>
                      </div>
                      <div className="flex space-x-4">
                        <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center text-purple-600">
                          <CheckCircle size={24} />
                        </div>
                        <div>
                          <h3 className="font-bold text-lg">Pre-Vetted Talent</h3>
                          <p className="text-gray-600">Top 5% of professionals</p>
                        </div>
                      </div>
                      <div className="flex space-x-4">
                        <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center text-purple-600">
                          <CheckCircle size={24} />
                        </div>
                        <div>
                          <h3 className="font-bold text-lg">Perfect Culture Fit</h3>
                          <p className="text-gray-600">Aligned with your values</p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="mt-8 pt-4 border-t border-gray-100">
                      <div className="flex items-center space-x-2">
                        <div className="flex -space-x-2">
                          {[...Array(3)].map((_, i) => (
                            <div key={i} className="w-8 h-8 rounded-full border-2 border-white" style={{ backgroundColor: ['#4f46e5', '#8b5cf6', '#d946ef'][i] }}></div>
                          ))}
                        </div>
                        <div className="text-sm text-gray-600">
                          <span className="font-medium text-gray-900">250+</span> matches this month
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Custom animations */}
      <style jsx>{`
        @keyframes float {
          0%, 100% {
            transform: translateY(0) translateX(0);
          }
          25% {
            transform: translateY(-10px) translateX(10px);
          }
          50% {
            transform: translateY(0) translateX(20px);
          }
          75% {
            transform: translateY(10px) translateX(10px);
          }
        }
        
        @keyframes blink {
          0%, 100% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
        }
        
        .animate-blink {
          animation: blink 1s step-end infinite;
        }
      `}</style>
      <ViewMatchesModal key={modalKey}
    isOpen={isMatchModalOpen} 
    onClose={() => setIsMatchModalOpen(false)}  />
    </div>
  );
};

export default HomePage;