import React, { useState } from 'react';
import { ChevronRight, CheckCircle, ArrowRight, ChevronDown, Users, BarChart, TrendingUp, Lightbulb, Target, LayoutGrid } from 'lucide-react';

const ConsultingPage = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const consultingServices = [
    {
      icon: <TrendingUp className="w-6 h-6" />,
      title: "Strategic IT Planning",
      description: "Develop a comprehensive IT strategy aligned with your business objectives and future growth plans."
    },
    {
      icon: <LayoutGrid className="w-6 h-6" />,
      title: "Technology Assessment",
      description: "Evaluate your current tech stack and identify opportunities for optimization, cost reduction, and innovation."
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Team Structure Optimization",
      description: "Design efficient team structures and workflows to maximize productivity and collaboration."
    },
    {
      icon: <Lightbulb className="w-6 h-6" />,
      title: "Digital Transformation",
      description: "Navigate the complexities of digital transformation with expert guidance and implementation support."
    },
    {
      icon: <BarChart className="w-6 h-6" />,
      title: "Performance Optimization",
      description: "Identify bottlenecks and implement solutions to enhance system performance and reliability."
    },
    {
      icon: <Target className="w-6 h-6" />,
      title: "Project Recovery",
      description: "Get struggling projects back on track with expert analysis, planning, and execution support."
    }
  ];

  const caseStudies = [
    {
      title: "E-commerce Platform Optimization",
      client: "Leading Retail Brand",
      industry: "Retail",
      challenge: "The client's e-commerce platform was struggling with performance issues during peak traffic periods, leading to lost sales and customer dissatisfaction.",
      solution: "We conducted a comprehensive performance audit, identified bottlenecks, and implemented infrastructure changes, caching strategies, and code optimizations.",
      results: [
        "53% improvement in page load times",
        "99.99% uptime during Black Friday sales",
        "32% increase in conversion rates"
      ],
    },
    {
      title: "Digital Transformation Strategy",
      client: "Traditional Manufacturing Company",
      industry: "Manufacturing",
      challenge: "A 50-year-old manufacturing company needed to modernize operations and integrate digital technologies across their business processes.",
      solution: "We developed a phased digital transformation roadmap, prioritizing key initiatives, and providing implementation guidance for new systems and processes.",
      results: [
        "40% reduction in operational costs",
        "25% improvement in production efficiency",
        "Creation of new data-driven revenue streams"
      ],
    },
    {
      title: "IT Department Restructuring",
      client: "Multinational Financial Institution",
      industry: "Finance",
      challenge: "The client's IT department was siloed, leading to inefficiencies, duplicated efforts, and slow response to business needs.",
      solution: "We redesigned the organizational structure, implemented agile methodologies, and established clear roles, responsibilities, and communication channels.",
      results: [
        "65% faster project delivery times",
        "30% reduction in IT operational costs",
        "Improved alignment with business objectives"
      ],
    }
  ];

  const expertise = [
    {
      category: "Business Strategy",
      items: [
        "Digital Transformation Planning",
        "IT Strategic Roadmapping",
        "Technology Investment Planning",
        "Business Process Optimization",
        "Innovation Strategy"
      ]
    },
    {
      category: "Technical Advisory",
      items: [
        "Architecture Assessment & Design",
        "Technology Stack Evaluation",
        "Security & Compliance Review",
        "Performance Optimization",
        "Scalability Planning"
      ]
    },
    {
      category: "Operational Excellence",
      items: [
        "IT Team Structure Design",
        "Agile & DevOps Implementation",
        "Project Recovery",
        "IT Governance Frameworks",
        "Quality Assurance Processes"
      ]
    }
  ];

  const faqItems = [
    {
      question: "How do your consulting services work?",
      answer: "Our consulting services typically begin with a discovery phase where we understand your business goals, challenges, and current technological landscape. We then develop a tailored strategy and implementation plan, which may involve workshops, assessments, and collaborative sessions. Throughout the engagement, we provide guidance, expertise, and actionable recommendations that align with your specific needs."
    },
    {
      question: "How long does a typical consulting engagement last?",
      answer: "The duration varies depending on the scope and complexity of your needs. Strategic assessments might take 2-4 weeks, while comprehensive digital transformation projects could span several months. We offer both short-term focused engagements and long-term strategic partnerships, always with clearly defined milestones and deliverables."
    },
    {
      question: "What industries do you specialize in?",
      answer: "We have extensive experience across multiple industries, including Finance, Healthcare, Retail, Manufacturing, Technology, and Professional Services. Our consultants bring both broad technological expertise and industry-specific knowledge to each engagement, ensuring that our recommendations are both technically sound and contextually appropriate."
    },
    {
      question: "How do you measure the success of your consulting services?",
      answer: "We establish clear, measurable KPIs at the beginning of each engagement, aligned with your business objectives. These may include metrics like cost reduction, efficiency improvements, time-to-market acceleration, or other relevant business outcomes. We regularly review these metrics throughout the engagement and provide detailed reporting on progress and results."
    },
    {
      question: "Can you work with our existing IT team?",
      answer: "Absolutely. We believe in collaboration rather than replacement. Our consultants work closely with your internal teams, transferring knowledge, building capabilities, and ensuring that your organization can sustain and build upon the improvements we implement together. We tailor our approach to complement your team's strengths and address specific skill gaps."
    }
  ];

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
                IT Consulting Services
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Strategic Technology Consulting For Business Growth
              </h1>
              <p className="text-xl mb-8 text-purple-100 max-w-lg">
                Navigate complex technology decisions with confidence. Our expert consultants provide strategic guidance to optimize your IT investments and accelerate business outcomes.
              </p>
              <div className="flex flex-wrap gap-4">
                <a 
                  href="/get-matched" 
                  className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  Speak With a Consultant
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
                <a 
                  href="#case-studies" 
                  className="group bg-purple-800/50 backdrop-blur-sm hover:bg-purple-800/70 border border-purple-500/30 text-white px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
                >
                  View Success Stories
                  <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </div>
            </div>
            <div>
              <div className="relative">
                <div className="absolute -left-4 -top-4 w-64 h-64 bg-purple-500/30 rounded-full animate-pulse"></div>
                <div className="relative bg-white/10 backdrop-blur-sm border border-white/20 p-8 rounded-xl shadow-2xl">
                  <div className="p-6 bg-purple-600 text-white rounded-xl inline-block mb-6 shadow-lg">
                    <BarChart size={48} />
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-white">Strategic IT Consulting</h3>
                  <ul className="space-y-3 mb-8">
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Technology Strategy Development</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Digital Transformation</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Architecture & Infrastructure</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Process Optimization</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle size={20} className="text-purple-300 mr-2 mt-1 flex-shrink-0" />
                      <span className="text-purple-100">Technology Roadmapping</span>
                    </li>
                  </ul>
                  <div className="text-center">
                    <div className="text-sm text-purple-200 mb-2">Expert-Led Approach</div>
                    <div className="flex justify-center space-x-2">
                      <div className="px-3 py-1 bg-purple-500/30 backdrop-blur-sm rounded-full text-xs font-medium border border-white/10">Strategy</div>
                      <div className="px-3 py-1 bg-purple-500/30 backdrop-blur-sm rounded-full text-xs font-medium border border-white/10">Innovation</div>
                      <div className="px-3 py-1 bg-purple-500/30 backdrop-blur-sm rounded-full text-xs font-medium border border-white/10">Results</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR SERVICES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Comprehensive Consulting Solutions
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              From strategic planning to implementation guidance, we provide expert consulting across all aspects of technology and business.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {consultingServices.map((service, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-300 hover:shadow-xl hover:-translate-y-1"
              >
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  {service.icon}
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Our Expertise */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR EXPERTISE
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Areas of Specialization
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our consultants bring deep expertise across a wide range of technical and business domains.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {expertise.map((area, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-8 transition-all duration-300 hover:shadow-xl"
              >
                <h3 className="text-xl font-bold mb-6 text-purple-700 pb-4 border-b border-gray-100">{area.category}</h3>
                <ul className="space-y-3">
                  {area.items.map((item, i) => (
                    <li key={i} className="flex items-start">
                      <CheckCircle size={18} className="text-purple-600 mr-2 mt-0.5 flex-shrink-0" />
                      <span className="text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="mt-12 text-center">
            <a 
              href="/get-matched" 
              className="inline-flex items-center text-purple-600 font-medium group"
            >
              <span className="mr-1">Discuss your specific needs with our experts</span>
              <ArrowRight size={16} className="group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>

      {/* Case Studies */}
      <section id="case-studies" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              SUCCESS STORIES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Client Success Stories
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              See how our consulting expertise has helped businesses overcome challenges and achieve their goals.
            </p>
          </div>

          <div className="space-y-12">
            {caseStudies.map((study, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden transition-all duration-300 hover:shadow-xl"
              >
                <div className="grid md:grid-cols-5 gap-0">
                  <div className="md:col-span-2 bg-purple-50 p-8 flex flex-col justify-center">
                    <div className="inline-block bg-purple-600 text-white px-4 py-1 rounded-full text-sm font-semibold mb-4">
                      {study.industry}
                    </div>
                    <h3 className="text-2xl font-bold mb-3 text-gray-900">{study.title}</h3>
                    <p className="text-gray-600 mb-4">Client: {study.client}</p>
                  </div>
                  <div className="md:col-span-3 p-8">                    
                    <div className="mb-4">
                      <h4 className="font-medium text-gray-900 mb-2">Challenge:</h4>
                      <p className="text-gray-600">{study.challenge}</p>
                    </div>
                    
                    <div className="mb-4">
                      <h4 className="font-medium text-gray-900 mb-2">Solution:</h4>
                      <p className="text-gray-600">{study.solution}</p>
                    </div>
                    
                    <div>
                      <h4 className="font-medium text-gray-900 mb-2">Results:</h4>
                      <ul className="space-y-1">
                        {study.results.map((result, i) => (
                          <li key={i} className="flex items-center text-gray-600">
                            <CheckCircle size={16} className="text-green-600 mr-2 flex-shrink-0" />
                            <span>{result}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR APPROACH
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Our Consulting Process
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              We follow a proven methodology to deliver consistent, valuable results for our clients.
            </p>
          </div>

          <div className="relative">
            {/* Connection line */}
            <div className="hidden md:block absolute top-24 left-0 w-full h-1 bg-purple-200 z-0"></div>
            
            <div className="grid md:grid-cols-4 gap-8">
              {[
                { 
                  title: "Discovery", 
                  description: "We begin by understanding your business objectives, challenges, and current technology landscape through in-depth discussions and assessments." 
                },
                { 
                  title: "Analysis", 
                  description: "Our experts analyze findings, identify opportunities, and develop strategic recommendations aligned with your business goals." 
                },
                { 
                  title: "Roadmap", 
                  description: "We create a detailed implementation plan with clear milestones, resource requirements, and expected outcomes." 
                },
                { 
                  title: "Implementation", 
                  description: "We provide guidance and support throughout the execution phase, ensuring successful adoption and sustainable results." 
                }
              ].map((step, index) => (
                <div key={index} className="relative">
                  <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 relative z-10 h-full hover:shadow-xl transition-all duration-300">
                    <div className="w-12 h-12 bg-purple-600 text-white rounded-full flex items-center justify-center font-bold text-xl mb-4 mx-auto md:mx-0">{index + 1}</div>
                    <h3 className="text-xl font-bold mb-3 text-gray-900">{step.title}</h3>
                    <p className="text-gray-600">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-white">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              FAQ
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Frequently Asked Questions
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Find answers to common questions about our consulting services.
            </p>
          </div>

          <div className="space-y-4">
            {faqItems.map((item, index) => (
              <div 
                key={index} 
                className="border border-gray-200 rounded-xl overflow-hidden transition-all duration-300"
              >
                <button 
                  className="flex justify-between items-center w-full p-6 text-left bg-white hover:bg-gray-50 transition-colors duration-300"
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="font-bold text-lg text-gray-900">{item.question}</span>
                  <ChevronDown 
                    className={`transform transition-transform duration-300 text-purple-600 ${
                      activeAccordion === index ? 'rotate-180' : ''
                    }`} 
                    size={20} 
                  />
                </button>
                <div 
                  className={`overflow-hidden transition-all duration-300 ${
                    activeAccordion === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                  }`}
                >
                  <div className="p-6 pt-0 text-gray-600 bg-white">
                    {item.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Transform Your Technology Strategy?</h2>
          <p className="text-xl mb-8 text-purple-200 max-w-3xl mx-auto">
            Partner with our expert consultants to navigate complex technology decisions and accelerate your business growth.
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <a 
              href="/get-matched" 
              className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Schedule a Consultation
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            <a 
              href="/contact" 
              className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Contact Us
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ConsultingPage;