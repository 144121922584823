import React from 'react';
import PropTypes from 'prop-types';

/**
 * PolicyPage component - a reusable template for policy pages like Privacy Policy and Terms & Conditions
 * 
 * @param {Object} props - Component props
 * @param {string} props.title - Page title (e.g. "Privacy Policy" or "Terms & Conditions")
 * @param {string} props.lastUpdated - Date when the policy was last updated
 * @param {Array} props.sections - Array of section objects with title and content
 * @param {string} props.contactEmail - Email for policy-related inquiries
 */
const PolicyPage = ({ title, lastUpdated, sections, contactEmail }) => {
  return (
    <div className="font-sans text-gray-900">
      {/* Header Section */}
      <section className="pt-32 pb-16 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">{title}</h1>
            <p className="text-xl text-purple-100">
              Last Updated: {lastUpdated}
            </p>
          </div>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-16 bg-white">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8 md:p-12">
            {/* Introduction */}
            <div className="prose prose-lg max-w-none">
              <p className="text-lg text-gray-700 mb-8">
                This {title.toLowerCase()} outlines how SOFT HOUSE LTD ("we", "our", or "us") collects, uses, and protects your information. 
                Please read this document carefully to understand our practices regarding your data and how we will treat it.
              </p>
              
              {/* Policy Sections */}
              {sections.map((section, index) => (
                <div key={index} className="mb-10">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4" id={`section-${index + 1}`}>
                    {section.title}
                  </h2>
                  <div className="text-gray-700 space-y-4">
                    {section.content}
                  </div>
                </div>
              ))}
              
              {/* Contact Information */}
              <div className="mt-12 pt-8 border-t border-gray-200">
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Us</h2>
                <p className="text-gray-700">
                  If you have any questions about this {title.toLowerCase()}, please contact us at:{' '}
                  <a href={`mailto:${contactEmail}`} className="text-purple-600 hover:text-purple-800">
                    {contactEmail}
                  </a>
                </p>
                
                <div className="mt-8 bg-gray-50 p-6 rounded-lg">
                  <h3 className="text-lg font-bold text-gray-900 mb-2">Company Information</h3>
                  <div className="grid md:grid-cols-2 gap-6 text-gray-700">
                    <div>
                      <p className="font-medium mb-1">UK Office:</p>
                      <address className="not-italic">
                        <p>SOFT HOUSE LTD</p>
                        <p>321-323 High Road</p>
                        <p>Essex, RM6 6AX</p>
                        <p>United Kingdom</p>
                        <p>VAT: GB432066718</p>
                        <p className="mt-2">
                          <a href="mailto:hello@soft-house.uk" className="text-purple-600 hover:text-purple-800">
                            hello@soft-house.uk
                          </a>
                        </p>
                      </address>
                    </div>
                    <div>
                      <p className="font-medium mb-1">Poland Office:</p>
                      <address className="not-italic">
                        <p>GAWRYS HOLDING SP. Z O.O.</p>
                        <p>ul. Uniwersytecka 13</p>
                        <p>Katowice, 40-007</p>
                        <p>Poland</p>
                        <p>VAT EU: PL5882514286</p>
                        <p className="mt-2">
                          <a href="mailto:contact@soft-house.eu" className="text-purple-600 hover:text-purple-800">
                            contact@soft-house.eu
                          </a>
                        </p>
                      </address>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

PolicyPage.propTypes = {
  title: PropTypes.string.isRequired,
  lastUpdated: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired
    })
  ).isRequired,
  contactEmail: PropTypes.string.isRequired
};

export default PolicyPage;