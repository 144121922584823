import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Linkedin } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white pt-16 pb-8 relative overflow-hidden">
      {/* Background texture */}
      <div className="absolute inset-0 opacity-5">
        <div className="absolute inset-0" style={{ backgroundSize: '20px 20px', backgroundImage: 'radial-gradient(#fff 1px, transparent 0)' }}></div>
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-4 gap-12">
          <div>
            <div className="flex items-center mb-6">
              <div className="w-10 h-10 bg-purple-600 rounded-md flex items-center justify-center text-white font-bold text-xl shadow-lg mr-3">S</div>
              <h3 className="text-2xl font-bold text-white">SOFT HOUSE</h3>
            </div>
            <p className="text-gray-400 mb-6">
              Connecting businesses with top tech talent around the globe. Our premium matchmaking service ensures the perfect fit for your team.
            </p>
            <div className="flex space-x-4">
              <a 
                href="https://www.linkedin.com/company/softhouseglobal/" 
                className="text-gray-400 hover:text-white transition-colors duration-300 bg-gray-800 p-2 rounded-md hover:bg-gray-700"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin size={20} />
              </a>
            </div>
          </div>
          
          <div>
            <h4 className="font-bold text-lg mb-6 text-white">Company</h4>
            <ul className="space-y-3">
              <li><Link to="/about-us" className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center"><ChevronRight size={14} className="mr-2" /> About Us</Link></li>
              <li><a href="/careers" className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center" target="_blank" rel="noopener noreferrer"><ChevronRight size={14} className="mr-2" /> Careers</a></li>
              <li><a href="/blog" className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center" target="_blank" rel="noopener noreferrer"><ChevronRight size={14} className="mr-2" /> Blog</a></li>
              <li><Link to="/privacy-policy" className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center"><ChevronRight size={14} className="mr-2" /> Privacy Policy</Link></li>
              <li><Link to="/contact" className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center"><ChevronRight size={14} className="mr-2" /> Contact Us</Link></li>
            </ul>
          </div>
          
          <div>
            <h4 className="font-bold text-lg mb-6 text-white">Services</h4>
            <ul className="space-y-3">
              <li><Link to="/software-development" className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center"><ChevronRight size={14} className="mr-2" /> Software Development</Link></li>
              <li><Link to="/it-staff-augmentation" className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center"><ChevronRight size={14} className="mr-2" /> IT Staff Augmentation</Link></li>
              <li><Link to="/custom-project-development" className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center"><ChevronRight size={14} className="mr-2" /> Custom Projects</Link></li>
              <li><Link to="/consulting" className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center"><ChevronRight size={14} className="mr-2" /> Consulting</Link></li>
              <li><Link to="/devops-services" className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center"><ChevronRight size={14} className="mr-2" /> DevOps Services</Link></li>
            </ul>
          </div>
          
          <div>
            <h4 className="font-bold text-lg mb-6 text-white">Locations</h4>
            <ul className="space-y-3">
              <li>
                <Link to="/about-us#uk-office" className="text-gray-400 hover:text-white transition-colors duration-300 flex items-start">
                  <ChevronRight size={14} className="mr-2 mt-1" /> 
                  <div>
                    <span className="block text-white">UK Office</span>
                    <span className="block text-xs text-gray-500">Essex, United Kingdom</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/about-us#poland-office" className="text-gray-400 hover:text-white transition-colors duration-300 flex items-start">
                  <ChevronRight size={14} className="mr-2 mt-1" /> 
                  <div>
                    <span className="block text-white">Poland Office</span>
                    <span className="block text-xs text-gray-500">Katowice, Poland</span>
                  </div>
                </Link>
              </li>
              <li className="pt-4">
                <Link to="/contact" className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-md font-medium text-center transition duration-300 shadow-md flex items-center justify-center w-full">
                  Contact Us
                  <ChevronRight size={16} className="ml-1" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-500">
          <p>&copy; {new Date().getFullYear()} SOFT HOUSE. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;