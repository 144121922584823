// api.js - Service for communicating with WordPress API

// Base URL for the WordPress REST API
const API_BASE_URL = 'https://cms.soft-house.eu/wp-json/soft-house/v1';

/**
 * Fetch job listings with optional filtering
 * 
 * @param {Object} filters - Object containing filter parameters
 * @param {number} filters.page - Page number
 * @param {number} filters.perPage - Number of items per page
 * @param {string|number} filters.department - Department ID or slug
 * @param {string|number} filters.location - Location ID or slug
 * @param {string} filters.search - Search term
 * @returns {Promise} Promise resolving to job listings data
 */
export const fetchJobs = async (filters = {}) => {
  try {
    const queryParams = new URLSearchParams();
    
    // Add filters to query params
    if (filters.page) queryParams.append('page', filters.page);
    if (filters.perPage) queryParams.append('per_page', filters.perPage);
    if (filters.department) queryParams.append('department', filters.department);
    if (filters.location) queryParams.append('location', filters.location);
    if (filters.search) queryParams.append('search', filters.search);
    
    const response = await fetch(`${API_BASE_URL}/job-offers?${queryParams.toString()}`);
    
    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

/**
 * Fetch a single job by ID
 * 
 * @param {number} id - Job ID
 * @returns {Promise} Promise resolving to job data
 */
export const fetchJobBySlug = async (slug) => {
  try {
    const response = await fetch(`${API_BASE_URL}/job-offers/${slug}`);
    
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('Job not found');
      }
      throw new Error(`API error: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error(`Error fetching job ${slug}:`, error);
    throw error;
  }
};

/**
 * Fetch all departments
 * 
 * @returns {Promise} Promise resolving to departments data
 */
export const fetchDepartments = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/departments`);
    
    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error fetching departments:', error);
    throw error;
  }
};

/**
 * Fetch all locations
 * 
 * @returns {Promise} Promise resolving to locations data
 */
export const fetchLocations = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/locations`);
    
    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error fetching locations:', error);
    throw error;
  }
};

/**
 * Submit a job application using Contact Form 7
 * 
 * @param {number} formId - Contact Form 7 form ID
 * @param {Object} formData - Form data to submit
 * @param {File} [resumeFile] - Resume file to upload
 * @returns {Promise} Promise resolving to submission result
 */
export const submitApplication = async (formId, formData, resumeFile = null) => {
  try {
    // Create FormData object for the API request
    const apiFormData = new FormData();
    
    // Add form ID
    apiFormData.append('form_id', formId);
    
    // Add all form fields
    Object.entries(formData).forEach(([key, value]) => {
      apiFormData.append(key, value);
    });
    
    // Add resume file if provided
    if (resumeFile) {
      apiFormData.append('resume', resumeFile);
    }
    
    const response = await fetch(`${API_BASE_URL}/submit-application`, {
      method: 'POST',
      body: apiFormData,
    });
    
    const result = await response.json();
    
    if (!response.ok) {
      throw new Error(result.message || 'Form submission failed');
    }
    
    return result;
  } catch (error) {
    console.error('Error submitting application:', error);
    throw error;
  }
};