import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ChevronRight, MapPin, Briefcase, Clock, DollarSign, CheckCircle } from 'lucide-react';
import { fetchJobBySlug, submitApplication } from '../../api';

// ChevronLeft component
const ChevronLeft = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}><path d="m15 18-6-6 6-6"/></svg>
);

const JobView = () => {
  const { jobSlug } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('description');
  const [applicationForm, setApplicationForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    resume: null,
    acceptPrivacyPolicy: false
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionResult, setSubmissionResult] = useState(null);

  // Fetch job details when component mounts or jobId changes
  useEffect(() => {
    const getJobDetails = async () => {
      try {
        setIsLoading(true);
        const jobData = await fetchJobBySlug(jobSlug);
        setJob(jobData);
      } catch (error) {
        console.error(`Error fetching job ${jobSlug}:`, error);
        // Redirect to positions page if job not found
        navigate('/careers/positions');
      } finally {
        setIsLoading(false);
      }
    };
    
    if (jobSlug) {
      getJobDetails();
    }
  }, [jobSlug, navigate]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    
    if (type === 'file') {
      setApplicationForm(prev => ({
        ...prev,
        [name]: files[0] || null
      }));
    } else if (type === 'checkbox') {
      setApplicationForm(prev => ({
        ...prev,
        [name]: checked
      }));
    } else {
      setApplicationForm(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error for this field if it exists
    if (formErrors[name]) {
      setFormErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  // Validate form
  const validateForm = () => {
    const errors = {};
    
    if (!applicationForm.firstName.trim()) {
      errors.firstName = 'First name is required';
    }
    
    if (!applicationForm.lastName.trim()) {
      errors.lastName = 'Last name is required';
    }
    
    if (!applicationForm.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(applicationForm.email)) {
      errors.email = 'Email address is invalid';
    }
    
    if (!applicationForm.resume) {
      errors.resume = 'Resume is required';
    } else {
      const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!allowedTypes.includes(applicationForm.resume.type)) {
        errors.resume = 'Resume must be in PDF, DOC, or DOCX format';
      } else if (applicationForm.resume.size > 10 * 1024 * 1024) { // 10MB
        errors.resume = 'Resume must be smaller than 10MB';
      }
    }
    
    if (!applicationForm.acceptPrivacyPolicy) {
      errors.acceptPrivacyPolicy = 'You must agree to the privacy policy';
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Replace the existing handleSubmit function with this implementation
const handleSubmit = async (e) => {
  e.preventDefault();
  
  // Reset submission result
  setSubmissionResult(null);
  
  // Validate form
  if (!validateForm()) {
    // Scroll to first error
    const firstErrorField = Object.keys(formErrors)[0];
    const errorElement = document.querySelector(`[name="${firstErrorField}"]`);
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    return;
  }
  
  // Submit form
  try {
    setIsSubmitting(true);
    
    const FORM_ID = 12;
    // Create form data object for submission
    const formData = new FormData();
    
    // Add CF7 required fields
    formData.append('_wpcf7', FORM_ID);
    formData.append('_wpcf7_version', '5.7.7'); // Update to match your CF7 version
    formData.append('_wpcf7_locale', 'en_US');
    formData.append('_wpcf7_unit_tag', `wpcf7-f${FORM_ID}-p1-o1`);
    formData.append('_wpcf7_container_post', '0');
    
    // Add user information fields
    formData.append('your-first-name', applicationForm.firstName);
    formData.append('your-last-name', applicationForm.lastName);
    formData.append('your-email', applicationForm.email);
    formData.append('your-phone', applicationForm.phone || '');
    formData.append('your-message', applicationForm.message || '');
    formData.append('job-id', job.id);
    formData.append('job-title', job.title);
    
    // Add resume file if available
    if (applicationForm.resume) {
      formData.append('resume', applicationForm.resume);
    }
    
    // Submit the form
    const response = await fetch(
      `https://cms.soft-house.eu/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
      {
        method: 'POST',
        body: formData,
      }
    );
    
    const result = await response.json();
    
    // Handle response
    if (result.status === 'mail_sent') {
      // Show success message
      setSubmissionResult({
        status: 'success',
        message: 'Your application has been submitted successfully. We will review it and contact you soon.'
      });
      
      // Reset form
      setApplicationForm({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        resume: null,
        acceptPrivacyPolicy: false
      });
      
      // Scroll to result message
      setTimeout(() => {
        const resultElement = document.getElementById('submission-result');
        if (resultElement) {
          resultElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
    } else {
      // Show error message
      setSubmissionResult({
        status: 'error',
        message: result.message || 'There was an error submitting your application. Please try again later.'
      });
    }
  } catch (error) {
    console.error('Error submitting application:', error);
    setSubmissionResult({
      status: 'error',
      message: error.message || 'There was an error submitting your application. Please try again later.'
    });
  } finally {
    setIsSubmitting(false);
  }
};

  if (isLoading) {
    return (
      <div className="pt-32 pb-24 flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (!job) {
    return (
      <div className="pt-32 pb-24 text-center min-h-screen">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">Position Not Found</h1>
          <p className="text-xl text-gray-600 mb-8">The position you're looking for doesn't exist or has been removed.</p>
          <Link
            to="/careers/positions"
            className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 rounded-lg inline-flex items-center font-bold transition-colors duration-300"
          >
            Browse All Positions
            <ChevronRight className="ml-2 w-5 h-5" />
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-32 pb-24 bg-gray-50 min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-6">
          <Link
            to="/careers/positions"
            className="inline-flex items-center text-purple-600 hover:text-purple-800 font-medium mb-4"
          >
            <ChevronLeft className="mr-1 w-5 h-5" />
            Back to All Positions
          </Link>
          
          <div className="bg-white rounded-xl shadow-md p-8 mb-8">
            <div className="sm:flex sm:items-start sm:justify-between">
              <div>
                <h1 className="text-3xl font-bold text-gray-900 mb-4">{job.title}</h1>
                <div className="flex flex-wrap gap-4 mb-4">
                  {job.location && (
                    <div className="flex items-center text-gray-600">
                      <MapPin className="w-5 h-5 mr-1 text-purple-600" />
                      <span>{job.location.name}</span>
                    </div>
                  )}
                  {job.job_type && (
                    <div className="flex items-center text-gray-600">
                      <Briefcase className="w-5 h-5 mr-1 text-purple-600" />
                      <span>{job.job_type.name}</span>
                    </div>
                  )}
                  {job.experience && (
                    <div className="flex items-center text-gray-600">
                      <Clock className="w-5 h-5 mr-1 text-purple-600" />
                      <span>{job.experience}</span>
                    </div>
                  )}
                  {job.salary_range && (
                    <div className="flex items-center text-gray-600">
                      <DollarSign className="w-5 h-5 mr-1 text-purple-600" />
                      <span>{job.salary_range}</span>
                    </div>
                  )}
                </div>
                <div className="flex flex-wrap items-center gap-2">
                  {job.department && (
                    <span className="bg-purple-100 text-purple-700 px-3 py-1 rounded-full text-sm font-medium">
                      {job.department.name}
                    </span>
                  )}
                  {job.reference_code && (
                    <span className="text-gray-500 text-sm">Ref: {job.reference_code}</span>
                  )}
                </div>
              </div>
              <div className="mt-6 sm:mt-0">
                <a
                  href="#application-form"
                  className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 rounded-lg inline-flex items-center font-bold shadow-md hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300"
                >
                  Apply Now
                  <ChevronRight className="ml-2 w-5 h-5" />
                </a>
              </div>
            </div>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8">
            <div className="md:col-span-2">
              <div className="bg-white rounded-xl shadow-md p-8 mb-8">
                <div className="border-b border-gray-200 mb-6">
                  <div className="flex flex-wrap -mb-px">
                    <button
                      className={`mr-6 py-4 px-1 border-b-2 font-medium text-sm ${
                        activeTab === 'description'
                          ? 'border-purple-600 text-purple-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      }`}
                      onClick={() => setActiveTab('description')}
                    >
                      Description
                    </button>
                    <button
                      className={`mr-6 py-4 px-1 border-b-2 font-medium text-sm ${
                        activeTab === 'benefits'
                          ? 'border-purple-600 text-purple-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      }`}
                      onClick={() => setActiveTab('benefits')}
                    >
                      Benefits
                    </button>
                  </div>
                </div>
                
                <div className="prose max-w-none">
                  {activeTab === 'description' ? (
                    <div dangerouslySetInnerHTML={{ __html: job.content }} />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: job.benefits }} />
                  )}
                </div>
              </div>
            </div>
            
            <div>
              {job.required_skills && (
                <div className="bg-white rounded-xl shadow-md p-6 mb-6">
                  <h3 className="text-lg font-bold text-gray-900 mb-4">Required Skills</h3>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {job.required_skills.split(',').map((skill, i) => (
                      <span key={i} className="bg-purple-50 text-purple-700 px-3 py-1 rounded-full text-sm">
                        {skill.trim()}
                      </span>
                    ))}
                  </div>
                  
                  {job.nice_to_have_skills && (
                    <>
                      <h3 className="text-lg font-bold text-gray-900 mt-6 mb-4">Nice to Have</h3>
                      <div className="flex flex-wrap gap-2">
                        {job.nice_to_have_skills.split(',').map((skill, i) => (
                          <span key={i} className="bg-gray-100 text-gray-800 px-3 py-1 rounded-full text-sm">
                            {skill.trim()}
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              )}
              
              <div className="bg-purple-50 rounded-xl p-6">
                <div className="flex items-center mb-4">
                  <CheckCircle className="w-5 h-5 text-purple-600 mr-2" />
                  <h3 className="text-lg font-bold text-gray-900">Why Join Us</h3>
                </div>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 text-purple-600 mr-2 flex-shrink-0 mt-0.5" />
                    <span>Work with cutting-edge technologies</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 text-purple-600 mr-2 flex-shrink-0 mt-0.5" />
                    <span>Flexible remote work environment</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 text-purple-600 mr-2 flex-shrink-0 mt-0.5" />
                    <span>Collaborate with a global team of experts</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 text-purple-600 mr-2 flex-shrink-0 mt-0.5" />
                    <span>Continuous learning and skill development</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 text-purple-600 mr-2 flex-shrink-0 mt-0.5" />
                    <span>Competitive compensation package</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          {/* Application Form */}
          <div id="application-form" className="bg-white rounded-xl shadow-md p-8 mt-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Apply for this Position</h2>
            
            {/* Submission Result */}
            {submissionResult && (
              <div 
                id="submission-result"
                className={`p-4 mb-6 rounded-lg ${
                  submissionResult.status === 'success' 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-red-100 text-red-800'
                }`}
              >
                {submissionResult.message}
              </div>
            )}
            
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">First Name *</label>
                  <input 
                    type="text" 
                    id="firstName"
                    name="firstName"
                    value={applicationForm.firstName}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-3 rounded-lg border ${
                      formErrors.firstName ? 'border-red-500' : 'border-gray-300'
                    } focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300`} 
                    required 
                  />
                  {formErrors.firstName && (
                    <p className="mt-1 text-sm text-red-600">{formErrors.firstName}</p>
                  )}
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">Last Name *</label>
                  <input 
                    type="text" 
                    id="lastName"
                    name="lastName"
                    value={applicationForm.lastName}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-3 rounded-lg border ${
                      formErrors.lastName ? 'border-red-500' : 'border-gray-300'
                    } focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300`} 
                    required 
                  />
                  {formErrors.lastName && (
                    <p className="mt-1 text-sm text-red-600">{formErrors.lastName}</p>
                  )}
                </div>
              </div>
              
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email *</label>
                  <input 
                    type="email" 
                    id="email"
                    name="email"
                    value={applicationForm.email}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-3 rounded-lg border ${
                      formErrors.email ? 'border-red-500' : 'border-gray-300'
                    } focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300`} 
                    required 
                  />
                  {formErrors.email && (
                    <p className="mt-1 text-sm text-red-600">{formErrors.email}</p>
                  )}
                </div>
                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
                  <input 
                    type="tel" 
                    id="phone"
                    name="phone"
                    value={applicationForm.phone}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300"
                  />
                </div>
              </div>
              
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Cover Letter</label>
                <textarea 
                  id="message"
                  name="message"
                  value={applicationForm.message}
                  onChange={handleInputChange}
                  rows="4" 
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent outline-none transition-colors duration-300"
                  placeholder="Tell us why you're interested in this position and what makes you a great candidate..."
                ></textarea>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Resume / CV *</label>
                <div className="flex items-center justify-center w-full">
                  <label className={`flex flex-col items-center justify-center w-full h-32 border-2 ${
                    formErrors.resume ? 'border-red-300 bg-red-50' : 'border-gray-300 bg-gray-50'
                  } border-dashed rounded-lg cursor-pointer hover:bg-gray-100 transition-colors duration-300`}>
                    {applicationForm.resume ? (
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg className="w-8 h-8 mb-3 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-700">
                          <span className="font-semibold">{applicationForm.resume.name}</span>
                        </p>
                        <p className="text-xs text-gray-500">
                          {(applicationForm.resume.size / (1024 * 1024)).toFixed(2)} MB
                        </p>
                        <p className="mt-2 text-xs text-blue-600 underline">Click to change</p>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg className="w-8 h-8 mb-3 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                        <p className="text-xs text-gray-500">PDF, DOC, or DOCX (MAX. 10MB)</p>
                      </div>
                    )}
                    <input 
                      id="resume" 
                      name="resume"
                      type="file" 
                      className="hidden" 
                      accept=".pdf,.doc,.docx" 
                      onChange={handleInputChange}
                      required 
                    />
                  </label>
                </div>
                {formErrors.resume && (
                  <p className="mt-1 text-sm text-red-600">{formErrors.resume}</p>
                )}
              </div>
              
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="acceptPrivacyPolicy"
                    name="acceptPrivacyPolicy"
                    type="checkbox"
                    checked={applicationForm.acceptPrivacyPolicy}
                    onChange={handleInputChange}
                    className={`w-4 h-4 border ${
                      formErrors.acceptPrivacyPolicy ? 'border-red-300 bg-red-50' : 'border-gray-300 bg-gray-50'
                    } rounded focus:ring-3 focus:ring-purple-300`}
                    required
                  />
                </div>
                <label htmlFor="acceptPrivacyPolicy" className="ml-2 text-sm text-gray-600">
                  I agree to the <a href="/privacy-policy" className="text-purple-600 hover:underline">privacy policy</a> and consent to the processing of my data for recruitment purposes.
                </label>
              </div>
              {formErrors.acceptPrivacyPolicy && (
                <p className="mt-1 text-sm text-red-600">{formErrors.acceptPrivacyPolicy}</p>
              )}
              
              <div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-lg shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300 ${
                    isSubmitting ? 'opacity-70 cursor-not-allowed' : ''
                  }`}
                >
                  {isSubmitting ? (
                    <span className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Submitting...
                    </span>
                  ) : (
                    'Submit Application'
                  )}
                </button>
              </div>
            </form>
          </div>
          
          {/* Similar Positions */}
          {job.similar_jobs && job.similar_jobs.length > 0 && (
            <div className="mt-12">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Similar Positions</h2>
              <div className="grid md:grid-cols-2 gap-6">
                {job.similar_jobs.map(similarJob => (
                  <div 
                    key={similarJob.id} 
                    className="bg-white rounded-xl shadow-md border border-gray-100 overflow-hidden transition-all duration-300 hover:shadow-lg hover:-translate-y-1 cursor-pointer"
                    onClick={() => navigate(`/careers/positions/${similarJob.id}`)}
                  >
                    <div className="p-6">
                      <div className="flex justify-between items-start mb-4">
                        {similarJob.department && (
                          <span className="bg-purple-100 text-purple-700 px-3 py-1 rounded-full text-sm font-medium">
                            {similarJob.department.name}
                          </span>
                        )}
                      </div>
                      <h3 className="text-xl font-bold mb-3 text-gray-900">{similarJob.title}</h3>
                      <div className="flex flex-wrap gap-4 mb-4">
                        {similarJob.location && (
                          <div className="flex items-center text-gray-600">
                            <MapPin className="w-4 h-4 mr-1" />
                            <span>{similarJob.location.name}</span>
                          </div>
                        )}
                        {similarJob.experience && (
                          <div className="flex items-center text-gray-600">
                            <Clock className="w-4 h-4 mr-1" />
                            <span>{similarJob.experience}</span>
                          </div>
                        )}
                      </div>
                      <Link
                        to={`/careers/positions/${similarJob.slug}`}
                        className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg inline-flex items-center font-medium transition-colors duration-300 shadow hover:shadow-md"
                        onClick={(e) => e.stopPropagation()}
                      >
                        View Details
                        <ChevronRight className="ml-1 w-4 h-4" />
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobView;