import React, { useState } from 'react';
import { ChevronRight, CheckCircle, AlertCircle } from 'lucide-react';

const GetMatchedPage = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    company: '',
    phone: '',
    projectType: '',
    projectDescription: '',
    techStack: '',
    teamSize: '',
    budget: '',
    timeline: '',
    startDate: '',
    hearAboutUs: ''
  });
  
  const [customTech, setCustomTech] = useState('');
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const techOptions = [
    'React', 'Angular', 'Vue.js', 'Node.js', 'Python', 
    'Java', 'PHP', 'Laravel', '.NET', 'Ruby on Rails',
    'AWS', 'Azure', 'Google Cloud', 'Kubernetes', 'Docker',
    'MongoDB', 'PostgreSQL', 'MySQL', 'React Native', 'Flutter'
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTechChange = (tech) => {
    if (formData.techStack.includes(tech)) {
      setFormData({
        ...formData,
        techStack: formData.techStack.filter(item => item !== tech)
      });
    } else {
      setFormData({
        ...formData,
        techStack: [...formData.techStack, tech]
      });
    }
  };

  const addCustomTech = () => {
    if (customTech && !formData.techStack.includes(customTech)) {
      setFormData({
        ...formData,
        techStack: [...formData.techStack, customTech]
      });
      console.log(formData.techStack);
    }
  };

  const nextStep = () => {
    setStep(step + 1);
    window.scrollTo(0, 0);
  };

  const prevStep = () => {
    setStep(step - 1);
    window.scrollTo(0, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // Create FormData object for Contact Form 7
      const form = new FormData();
      
      // Add CF7 required fields
      const formId = 14; // Replace with your actual form ID
      form.append('_wpcf7', formId);
      form.append('_wpcf7_version', '5.7.7'); // Update to match your CF7 version
      form.append('_wpcf7_locale', 'en_US');
      form.append('_wpcf7_unit_tag', `wpcf7-f${formId}-p1-o1`);
      form.append('_wpcf7_container_post', '0');
      
      // Add form data fields
      form.append('your-name', formData.fullName);
      form.append('your-email', formData.email);
      form.append('your-company', formData.company || '');
      form.append('your-phone', formData.phone || '');
      form.append('project-type', formData.projectType || '');
      form.append('project-description', formData.projectDescription || '');
      form.append('tech-stack', formData.techStack || '');
      form.append('team-size', formData.teamSize || '');
      form.append('budget', formData.budget || '');
      form.append('timeline', formData.timeline || '');
      form.append('start-date', formData.startDate || '');
      form.append('hear-about-us', formData.hearAboutUs || '');
      
      // Submit to Contact Form 7 REST API
      const response = await fetch(`https://cms.soft-house.eu/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`, {
        method: 'POST',
        body: form
      });
      
      const result = await response.json();
      
      if (result.status === 'mail_sent') {
        setSubmitStatus('success');
        setStep(4); // Move to success page
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Render different form steps
  const renderFormStep = () => {
    switch(step) {
      case 1:
        return (
          <div className="space-y-6">
            <h3 className="text-2xl font-bold text-gray-900">Tell us about yourself</h3>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Full Name *</label>
                <input 
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Email Address *</label>
                <input 
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Company Name</label>
                <input 
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                <input 
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                />
              </div>
              <div className="space-y-2 md:col-span-2">
                <label className="block text-sm font-medium text-gray-700">How did you hear about us?</label>
                <select
                  name="hearAboutUs"
                  value={formData.hearAboutUs}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                >
                  <option value="">Select an option</option>
                  <option value="Search Engine">Search Engine</option>
                  <option value="Social Media">Social Media</option>
                  <option value="Referral">Referral</option>
                  <option value="Blog">Blog or Article</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={nextStep}
                className="bg-purple-600 text-white px-6 py-3 rounded-lg font-medium inline-flex items-center shadow-md hover:bg-purple-700 transition-colors duration-300"
              >
                Next Step
                <ChevronRight size={20} className="ml-2" />
              </button>
            </div>
          </div>
        );
      
      case 2:
        return (
          <div className="space-y-6">
            <h3 className="text-2xl font-bold text-gray-900">Tell us about your project</h3>
            <div className="space-y-6">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Project Type *</label>
                <select
                  name="projectType"
                  value={formData.projectType}
                  onChange={handleInputChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                >
                  <option value="">Select project type</option>
                  <option value="New Development">New Development</option>
                  <option value="Ongoing Development">Ongoing Development</option>
                  <option value="Maintenance">Maintenance</option>
                  <option value="Consulting">Consulting</option>
                  <option value="Team Augmentation">Team Augmentation</option>
                </select>
              </div>
              
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Project Description *</label>
                <textarea
                  name="projectDescription"
                  value={formData.projectDescription}
                  onChange={handleInputChange}
                  required
                  rows={5}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                  placeholder="Describe your project, goals, and specific requirements..."
                ></textarea>
              </div>
              
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Project Technologies</label>
                <input 
                  type="text"
                  name="techStack"
                  value={formData.techStack}
                  onChange={handleInputChange}
                  placeholder="e.g. React, Node.js, AWS, MongoDB"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                />
                {/* <div className="flex flex-wrap gap-2 mb-4">
                  {techOptions.map(tech => (
                    <button
                      key={tech}
                      type="button"
                      onClick={() => handleTechChange(tech)}
                      className={`px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 ${
                        formData.techStack.includes(tech)
                          ? 'bg-purple-600 text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      }`}
                    >
                      {tech}
                    </button>
                  ))}
                </div>
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={customTech}
                    onChange={(e) => setCustomTech(e.target.value)}
                    placeholder="Add custom technology"
                    className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                  />
                  <button
                    type="button"
                    onClick={addCustomTech}
                    className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors duration-300"
                  >
                    Add
                  </button>
                </div> */}
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                onClick={prevStep}
                className="text-purple-600 px-6 py-3 rounded-lg font-medium inline-flex items-center hover:bg-purple-50 transition-colors duration-300"
              >
                Back
              </button>
              <button
                type="button"
                onClick={nextStep}
                className="bg-purple-600 text-white px-6 py-3 rounded-lg font-medium inline-flex items-center shadow-md hover:bg-purple-700 transition-colors duration-300"
              >
                Next Step
                <ChevronRight size={20} className="ml-2" />
              </button>
            </div>
          </div>
        );
      
      case 3:
        return (
          <div className="space-y-6">
            <h3 className="text-2xl font-bold text-gray-900">Project Details</h3>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Team Size Needed</label>
                <select
                  name="teamSize"
                  value={formData.teamSize}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                >
                  <option value="">Select team size</option>
                  <option value="1 Developer">1 Developer</option>
                  <option value="2-5 Developers">2-5 Developers</option>
                  <option value="6-10 Developers">6-10 Developers</option>
                  <option value="10+ Developers">10+ Developers</option>
                </select>
              </div>
              
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Budget Range</label>
                <select
                  name="budget"
                  value={formData.budget}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                >
                  <option value="">Select budget range</option>
                  <option value="Less than $10,000">Less than $10,000</option>
                  <option value="$10,000 - $50,000">$10,000 - $50,000</option>
                  <option value="$50,000 - $100,000">$50,000 - $100,000</option>
                  <option value="$100,000 - $500,000">$100,000 - $500,000</option>
                  <option value="More than $500,000">More than $500,000</option>
                </select>
              </div>
              
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Project Timeline</label>
                <select
                  name="timeline"
                  value={formData.timeline}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                >
                  <option value="">Select timeline</option>
                  <option value="<1 month">Less than 1 month</option>
                  <option value="1-3 months">1-3 months</option>
                  <option value="3-6 months">3-6 months</option>
                  <option value="6-12 months">6-12 months</option>
                  <option value=">12 months">More than 12 months</option>
                  <option value="Ongoing">Ongoing</option>
                </select>
              </div>
              
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Desired Start Date</label>
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                />
              </div>
            </div>
            <div className="mt-4 p-4 bg-purple-50 rounded-lg border border-purple-100">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <CheckCircle className="h-5 w-5 text-purple-600" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-gray-700">
                    By submitting this form, you agree to our 
                    <a href="/privacy-policy" className="font-medium text-purple-600 hover:text-purple-500"> Privacy Policy </a>
                    and consent to SOFT HOUSE contacting you about your request.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                onClick={prevStep}
                className="text-purple-600 px-6 py-3 rounded-lg font-medium inline-flex items-center hover:bg-purple-50 transition-colors duration-300"
              >
                Back
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-purple-600 text-white px-8 py-3 rounded-lg font-medium inline-flex items-center shadow-md hover:bg-purple-700 transition-colors duration-300 disabled:opacity-70"
              >
                {isSubmitting ? 'Submitting...' : 'Submit Request'}
                {!isSubmitting && <ChevronRight size={20} className="ml-2" />}
              </button>
            </div>
            
            {submitStatus === 'error' && (
              <div className="mt-4 p-4 bg-red-50 rounded-lg border border-red-100 text-red-800 flex items-start">
                <AlertCircle className="h-5 w-5 text-red-600 mr-2 flex-shrink-0" />
                <p className="text-sm">
                  There was an error submitting your request. Please try again or contact us directly.
                </p>
              </div>
            )}
          </div>
        );
      
      case 4:
        return (
          <div className="text-center py-12">
            <div className="inline-flex items-center justify-center w-20 h-20 rounded-full bg-green-100 mb-6">
              <CheckCircle size={40} className="text-green-600" />
            </div>
            <h3 className="text-3xl font-bold text-gray-900 mb-4">Request Submitted Successfully!</h3>
            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              Thank you for your interest in SOFT HOUSE. Our team will review your request and get back to you within 1-2 business days with potential matches for your project.
            </p>
            <p className="text-md text-gray-600 mb-12">
              A confirmation email has been sent to: <span className="font-semibold">{formData.email}</span>
            </p>
            <div className="space-y-4">
              <a
                href="/"
                className="bg-purple-600 text-white px-8 py-4 rounded-lg font-medium inline-flex items-center shadow-md hover:bg-purple-700 transition-colors duration-300"
              >
                Return to Homepage
                <ChevronRight size={20} className="ml-2" />
              </a>
              <p className="text-sm text-gray-500 mt-4">
                Have questions? <a href="mailto:contact@soft-house.eu" className="text-purple-600 hover:text-purple-700">Contact Us</a>
              </p>
            </div>
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Get Matched With Top Tech Talent
          </h1>
          <p className="text-xl mb-0 max-w-3xl mx-auto">
            Fill out the form below to help us understand your needs and find the perfect tech professionals for your project.
          </p>
        </div>
      </section>

      {/* Form Section */}
      <section className="py-16 bg-white">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-xl border border-gray-100 p-8">
          {step < 4 && (
  <div className="mb-8 w-full">
    <div className="flex justify-between items-center mb-4 w-full">
      {[1, 2, 3].map((stepNumber) => (
        <div key={stepNumber} className="flex items-center w-full last:w-auto">
          <div
            className={`w-10 h-10 rounded-full flex items-center justify-center text-white font-bold shrink-0 ${
              step >= stepNumber ? 'bg-purple-600' : 'bg-gray-300'
            }`}
          >
            {stepNumber}
          </div>
          {stepNumber < 3 && (
            <div
              className={`h-1 flex-1 ${
                step > stepNumber ? 'bg-purple-600' : 'bg-gray-300'
              }`}
            ></div>
          )}
        </div>
      ))}
    </div>
    <div className="flex justify-between text-sm text-gray-600 w-full">
      <span className="w-1/3 text-left">Your Info</span>
      <span className="w-1/3 text-center">Project Details</span>
      <span className="w-1/3 text-right">Requirements</span>
    </div>
  </div>
)}          
            <form onSubmit={handleSubmit}>
              {renderFormStep()}
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GetMatchedPage;