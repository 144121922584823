import React from 'react';
import { ChevronRight, MapPin, Award, Users, Globe, BookOpen, Briefcase, Heart, Coffee } from 'lucide-react';

const AboutUsPage = () => {
  const teamMembers = [
    {
      name: "Igor Gawrys",
      position: "CEO & Founder",
      bio: "With over 8 years of experience in tech talent acquisition, Igor founded SOFT HOUSE with a vision to bridge the gap between companies and top tech professionals worldwide.",
      image: "#",
      linkedIn: "linkedin.com/in/igorgawrys",
      expertise: ["Tech Leadership", "Business Development", "Talent Strategy"],
      education: "MSc in Computer Science, Warsaw University of Technology"
    },
    {
      name: "Sophia Chen",
      position: "Chief Technology Officer",
      bio: "Sophia brings 12+ years of enterprise software development experience to evaluate technical talent. She leads our technical assessment processes and ensures we match candidates with the right technical skills.",
      image: "#",
      linkedIn: "linkedin.com/in/sophiachen",
      expertise: ["Software Architecture", "AI & ML", "Technical Assessment"],
      education: "PhD in Computer Science, Stanford University"
    },
    {
      name: "Marcus Thompson",
      position: "Chief Operations Officer",
      bio: "Marcus oversees day-to-day operations and ensures seamless client-talent matching. His background in both tech and HR gives him a unique perspective on optimizing talent acquisition processes.",
      image: "#",
      linkedIn: "linkedin.com/in/marcusthompson",
      expertise: ["Operations Management", "Process Optimization", "Strategic Planning"],
      education: "MBA, London Business School"
    },
    {
      name: "Ana Rodriguez",
      position: "Head of Talent Acquisition",
      bio: "Ana has placed over 500 tech professionals throughout her career. She leads our global talent acquisition team, focusing on sourcing rare skill sets and specialized technical expertise.",
      image: "#",
      linkedIn: "linkedin.com/in/anarodriguez",
      expertise: ["Technical Recruitment", "Candidate Sourcing", "Talent Market Analysis"],
      education: "BA in Psychology, University of Barcelona"
    },
    {
      name: "Emma Wilson",
      position: "Head of Marketing",
      bio: "Emma leads our marketing efforts to connect with both tech talent and companies seeking expertise. She specializes in technical content marketing and community building to strengthen our talent network.",
      image: "#",
      linkedIn: "linkedin.com/in/emmawilson",
      expertise: ["Technical Content Marketing", "Employer Branding", "Digital Strategy"],
      education: "MSc in Marketing, University of Manchester"
    },
    {
      name: "Nadia Petrova",
      position: "People Operations Director",
      bio: "Nadia ensures our internal team operates at peak performance and creates a supportive culture. She also helps clients implement best practices in onboarding our placed talent.",
      image: "#",
      linkedIn: "linkedin.com/in/nadiapetrova",
      expertise: ["HR Operations", "Team Culture", "Talent Development"],
      education: "MSc in Organizational Psychology, Moscow State University"
    },
    {
      name: "Michael O'Connor",
      position: "Global Expansion Director",
      bio: "Michael leads our efforts to expand into new markets. He develops strategies for sourcing talent in emerging tech hubs and connects with companies expanding internationally.",
      image: "#",
      linkedIn: "linkedin.com/in/michaeloconnor",
      expertise: ["International Business", "Market Entry Strategy", "Global Talent Pools"],
      education: "MBA, INSEAD Business School"
    }
  ];

  const values = [
    {
      icon: <Award className="w-6 h-6" />,
      title: "Excellence",
      description: "We strive for excellence in everything we do, from our talent selection process to client communication and project delivery."
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Collaboration",
      description: "We believe in the power of collaboration and teamwork to achieve exceptional results for our clients and partners."
    },
    {
      icon: <Heart className="w-6 h-6" />,
      title: "Integrity",
      description: "We operate with the highest ethical standards, maintaining transparency and honesty in all our business dealings."
    },
    {
      icon: <Globe className="w-6 h-6" />,
      title: "Global Perspective",
      description: "We embrace diversity of thought, culture, and experience to create innovative solutions for our global clientele."
    },
    {
      icon: <BookOpen className="w-6 h-6" />,
      title: "Continuous Learning",
      description: "We foster a culture of continuous learning and skill development to stay at the forefront of technological advancements."
    },
    {
      icon: <Coffee className="w-6 h-6" />,
      title: "Work-Life Balance",
      description: "We promote a healthy work-life balance, recognizing that well-rested professionals deliver the best results."
    }
  ];

  const milestones = [
    {
      year: "2021",
      title: "SOFT HOUSE Founded",
      description: "Established with a mission to connect businesses with top tech talent globally."
    },
    {
      year: "2022",
      title: "European Expansion",
      description: "Opened our first European office in Poland, expanding our talent pool across Eastern Europe."
    },
    {
      year: "2022",
      title: "UK Headquarters",
      description: "Established our headquarters in the UK to better serve clients in Western Europe."
    },
    {
      year: "2023",
      title: "AI Matching Algorithm",
      description: "Developed our proprietary AI-powered matching algorithm to improve talent-client compatibility."
    },
    {
      year: "2023",
      title: "Global Client Base",
      description: "Reached the milestone of serving clients across 20+ countries with talent from 30+ countries."
    },
    {
      year: "2025",
      title: "Industry Recognition",
      description: "Recognized as one of the top IT staffing firms in Europe by industry analysts."
    }
  ];

  return (
    <div className="font-sans text-gray-900">
      {/* Hero Section */}
      <section className="pt-32 pb-24 bg-gradient-to-br from-purple-900 via-indigo-800 to-purple-800 text-white relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-40 -top-40 w-96 h-96 bg-purple-600/30 rounded-full blur-3xl"></div>
          <div className="absolute -left-40 -bottom-40 w-96 h-96 bg-indigo-600/30 rounded-full blur-3xl"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto">
            <div className="inline-block bg-purple-500/20 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-semibold mb-6 border border-purple-500/30">
              About SOFT HOUSE
            </div>
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              We Connect Great Companies with Exceptional Talent
            </h1>
            <p className="text-xl mb-8 text-purple-100">
              Founded with a passion for technology and a commitment to excellence, SOFT HOUSE bridges the gap between innovative companies and top-tier tech professionals worldwide.
            </p>
          </div>
        </div>
      </section>

      {/* Our Story Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
                OUR STORY
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
                From Vision to Global Impact
              </h2>
              <p className="text-lg text-gray-700 mb-6">
                SOFT HOUSE was founded in 2021 with a simple yet powerful vision: to connect innovative companies with exceptional tech talent, regardless of geographical boundaries. What began as a small operation has grown into a global talent matchmaking powerhouse.
              </p>
              <p className="text-lg text-gray-700 mb-6">
                Our founder recognized the challenges businesses face in finding qualified tech professionals and the opportunities that remote work presents for both companies and talent. This insight led to the creation of SOFT HOUSE's unique approach to IT staffing and project development.
              </p>
              <p className="text-lg text-gray-700">
                Today, we're proud to serve clients across multiple industries and continents, helping them build robust technology solutions with our carefully vetted professionals. Our journey continues as we expand our reach and refine our services to meet the evolving needs of the global tech landscape.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-4">
                <div className="bg-purple-100 h-64 rounded-xl overflow-hidden shadow-lg">
                  <img src="/images/pl-office.jpg" alt="Office" className="w-full h-full object-cover" />
                </div>
                <div className="bg-indigo-100 h-32 rounded-xl overflow-hidden shadow-lg">
                  <img src="/images/team_collaboration.jpg" alt="Team collaboration" className="w-full h-full object-cover" />
                </div>
              </div>
              <div className="space-y-4 mt-8">
                <div className="bg-indigo-100 h-32 rounded-xl overflow-hidden shadow-lg">
                  <img src="/images/technology.jpg" alt="Technology" className="w-full h-full object-cover" />
                </div>
                <div className="bg-purple-100 h-64 rounded-xl overflow-hidden shadow-lg">
                  <img src="/images/team.jpg" alt="Global team" className="w-full h-full object-cover" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Mission & Vision */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              PURPOSE
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Our Mission & Vision
            </h2>
          </div>

          <div className="grid md:grid-cols-2 gap-12">
            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8 transition-all duration-300 hover:shadow-xl">
              <div className="p-4 bg-purple-100 text-purple-600 rounded-full inline-block mb-6">
                <Briefcase size={24} />
              </div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">Our Mission</h3>
              <p className="text-lg text-gray-700">
                To empower businesses worldwide with access to exceptional tech talent and custom software solutions that drive innovation, efficiency, and growth. We're committed to creating meaningful connections that benefit both companies and professionals, fostering a global ecosystem of technological advancement.
              </p>
            </div>
            
            <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-8 transition-all duration-300 hover:shadow-xl">
              <div className="p-4 bg-purple-100 text-purple-600 rounded-full inline-block mb-6">
                <Globe size={24} />
              </div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">Our Vision</h3>
              <p className="text-lg text-gray-700">
                To be the world's most trusted partner for tech talent matchmaking and custom software development, known for our uncompromising quality, client-centric approach, and positive impact on the global technology landscape. We envision a future where geographical boundaries no longer limit access to opportunities or talent.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Our Values */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              GUIDING PRINCIPLES
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Our Core Values
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              These principles guide every decision we make and interaction we have.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {values.map((value, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-300 hover:shadow-xl hover:-translate-y-1"
              >
                <div className="p-3 bg-purple-100 text-purple-600 rounded-lg inline-block mb-4">
                  {value.icon}
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Our Team */}
      <section className="py-20 bg-purple-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR PEOPLE
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Meet Our Leadership Team
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              The experienced professionals driving SOFT HOUSE's mission forward.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {teamMembers.map((member, index) => (
              <div 
                key={index} 
                className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden transition-all duration-300 hover:shadow-xl hover:-translate-y-1"
              >
                {/* <div className="h-48 bg-gradient-to-br from-purple-600 to-indigo-700 flex items-center justify-center">
                  <img 
                    src={member.image} 
                    alt={member.name}
                    className="w-24 h-24 rounded-full border-4 border-white object-cover"
                  />
                </div> */}
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-1 text-gray-900">{member.name}</h3>
                  <p className="text-purple-600 font-medium mb-4">{member.position}</p>
                  <p className="text-gray-600">{member.bio}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="text-center mt-12">
            <a 
              href="https://career.soft-house.eu/" 
              className="group inline-flex items-center text-purple-600 font-medium"
            >
              <span className="mr-1">Interested in joining our team? View open positions</span>
              <ChevronRight className="group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>

      {/* Milestones */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR JOURNEY
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Key Milestones
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our journey of growth and innovation over the years.
            </p>
          </div>

          <div className="relative">
            {/* Timeline line */}
            <div className="hidden md:block absolute left-1/2 top-0 bottom-0 w-0.5 bg-purple-200 transform -translate-x-1/2"></div>
            
            <div className="space-y-12">
              {milestones.map((milestone, index) => (
                <div key={index} className="relative">
                  <div className={`md:flex items-center ${index % 2 === 0 ? '' : 'md:flex-row-reverse'}`}>
                    <div className="hidden md:block w-1/2"></div>
                    <div className="hidden md:flex absolute left-1/2 transform -translate-x-1/2 md:translate-y-0 items-center justify-center">
                      <div className="w-12 h-12 bg-purple-600 text-white rounded-full flex items-center justify-center font-bold text-lg z-10">
                        {milestone.year.slice(2)}
                      </div>
                    </div>
                    <div className="md:hidden flex absolute left-0 top-0 items-center justify-center">
                      <div className="w-12 h-12 bg-purple-600 text-white rounded-full flex items-center justify-center font-bold text-lg z-10">
                        {milestone.year.slice(2)}
                      </div>
                    </div>
                    <div className="md:w-1/2 ml-14 md:ml-0 md:px-8">
                      <div className="bg-white rounded-xl shadow-lg border border-gray-100 p-6 transition-all duration-300 hover:shadow-xl md:max-w-md mx-auto">
                        <div className="text-2xl font-bold text-purple-600 mb-2">{milestone.year}</div>
                        <h3 className="text-xl font-bold mb-3 text-gray-900">{milestone.title}</h3>
                        <p className="text-gray-600">{milestone.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Locations */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="inline-block bg-purple-100 px-4 py-2 rounded-full text-sm font-semibold text-purple-700 mb-4">
              OUR LOCATIONS
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Where to Find Us
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              With offices in strategic locations, we serve clients and connect with talent globally.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8" >
            <div className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden">
              <div className="h-64 bg-purple-100">
                <img 
                  src="/images/uk-office.jpg" 
                  alt="UK Office"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-6">
                <div className="flex items-start">
                  <div className="bg-purple-100 p-2 rounded-lg mr-4">
                    <MapPin className="text-purple-600" />
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2 text-gray-900">UK Headquarters</h3>
                    <address className="not-italic text-gray-600">
                      <p className="mb-1">SOFT HOUSE LTD</p>
                      <p className="mb-1">321-323 High Road</p>
                      <p className="mb-1">Essex, RM6 6AX</p>
                      <p className="mb-1">United Kingdom</p>
                      <p className="mb-1">VAT: GB432066718</p>
                      <a href="mailto:hello@soft-house.uk" className="text-purple-600 font-medium">hello@soft-house.uk</a>
                    </address>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden" >
              <div className="h-64 bg-purple-100">
                <img 
                  src="/images/pl-office.jpg" 
                  alt="Poland Office"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-6">
                <div className="flex items-start">
                  <div className="bg-purple-100 p-2 rounded-lg mr-4">
                    <MapPin className="text-purple-600" />
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2 text-gray-900">Poland Office</h3>
                    <address className="not-italic text-gray-600">
                      <p className="mb-1">GAWRYS HOLDING SP. Z O.O.</p>
                      <p className="mb-1">ul. Uniwersytecka 13</p>
                      <p className="mb-1">Katowice, 40-007</p>
                      <p className="mb-1">Poland</p>
                      <p className="mb-1">VAT EU: PL5882514286</p>
                      <a href="mailto:contact@soft-house.eu" className="text-purple-600 font-medium">contact@soft-house.eu</a>
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 via-indigo-900 to-purple-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Work With Us?</h2>
          <p className="text-xl mb-8 text-purple-200 max-w-3xl mx-auto">
            Whether you're looking for exceptional tech talent or seeking new career opportunities, we'd love to connect with you.
          </p>
          <div className="flex flex-wrap gap-4 justify-center">
            <a 
              href="/get-matched" 
              className="group bg-white text-purple-700 hover:bg-purple-50 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Find Tech Talent
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
            <a 
              href="https://career.soft-house.eu/" 
              className="group bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-lg font-bold text-lg inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
            >
              Join Our Team
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUsPage;